import { useContext, useEffect } from 'react';
import { useState, useRef } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Table, Tag, Input, Button, Space, Popover, Typography, Divider, Badge, Col, Row } from 'antd';
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';

import { cleanRestaurantCategories, convertBaccoCorrectionType } from '../../helpers/StringMethods';
import { countCorrectionsTotals } from '../../helpers/CountCorrectionsTotals';
import formatEuro from '../../helpers/FormatEuro';
import { Context } from '../../helpers/Context';
import { dateHourFormatIta } from '../../helpers/DateUtils';

dayjs.extend(utc);
dayjs.extend(timezone);

const TableCorrectionList = (props) => {
  
    const {
        refCorrectionsTotalQuantity,
        refCorrectionsTotalValue,
        refTotalAfterPreReceipt,
        refTotalWhileService, 
        refCorrectionsList,
        currency,
    } = useContext(Context);

    const [filteredData, setFilteredData] = useState(props.data ?? []);

    
    const [quantityRemoved, setquantityRemoved] = useState(0);
    const [quantityAdded, setquantityAdded] = useState(0);
    const [valueRemoved, setvalueRemoved] = useState(0);
    const [valueAdded, setvalueAdded] = useState(0);
    
    const [correctionTypeFilter, setCorrectionTypeFilter] = useState([]);
    const [valueAfterPreReceipt, setvalueAfterPreReceipt] = useState(null)
    const [valueWhileService, setvalueWhileService] = useState(null)
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [idTableFilter, setIdTableFilter] = useState([]);
    const [waiterFilter, setWaiterFilter] = useState([]);

    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchText, setSearchText] = useState('');
    const searchInput = useRef(null);

    function comparePrice(oldValue, newValue) {
        if (oldValue == null || newValue == null || isNaN(oldValue) || isNaN(newValue)) {
            return "Valori Mancanti";
        }
        if (oldValue === newValue) {
            return oldValue;
        }
        let difference = newValue - oldValue;
        let sign = difference > 0 ? "+" : "";
        return `${sign}${difference}`;
    }

    function compareQuantity(oldValue, newValue) {
        if (oldValue == null || newValue == null || isNaN(oldValue) || isNaN(newValue)) {
            return "Valori Mancanti";
        }
        if (oldValue === newValue) {
            return 0;
        }
        let difference = newValue - oldValue;
        let sign = difference > 0 ? "+" : "";
        return `${sign}${difference}`;
    }
   
    useEffect(() => {
        
        if (!props.data || props.data.length < 0) return;
        
        setFilteredData(props.data ?? []);

        const waiterSet = new Set();
        const categorySet = new Set();
        const idTableSet = new Set();
        const correctionTypeSet = new Set();

        props.data.forEach(i => {
            waiterSet.add(i.Cameriere);
            categorySet.add(cleanRestaurantCategories(i.GroupDescription));
            idTableSet.add(i.IDTavolo);
            correctionTypeSet.add(convertBaccoCorrectionType(i.TipoModifica));
            delete i.children; // Rimuovi "children" se necessario
        });

        // Trasformiamo i Set in array di oggetti per i filtri
        setCorrectionTypeFilter( Array.from(correctionTypeSet).map(value => ({ text: value, value })));
        setCategoryFilter(Array.from(categorySet).map(value => ({ text: value, value })));
        setIdTableFilter(Array.from(idTableSet).map(value => ({ text: value, value })));
        setWaiterFilter(Array.from(waiterSet).map(value => ({ text: value, value })));
        
    }, [props.data]);

    // ------------- gestione ricerca delle colonne ---------------—

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Cerca ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Cerca
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm, dataIndex) => {
        clearFilters();
        setSearchText('');
    };
    // ------------- gestione ricerca delle colonne ---------------—

    // ------------- colonne della tabella ---------------—

    const columns = [
        {
            title: 'Cameriere',
            dataIndex: 'Cameriere',
            key: 'Cameriere',
            filters: waiterFilter,
            onFilter: (value, record) => record.Cameriere?.includes(value) || false,
        },
       {
            title: 'Preconto Emesso',
            dataIndex: 'PrecontoEmesso',
            key: 'PrecontoEmesso',
            filters: [
                { text: "Si", value: "Si" },
                { text: "No", value: "No" }
           ],
           onFilter: (value, record) => (record.PrecontoEmesso ? "Si" : "No") === value,
            render: (value) => (
                <Popover title="Operazione applicata prima o dopo il preconto">
                    <Tag
                        className="fs-12"
                        bordered={false}
                        color={value ? "cyan" : "yellow"}
                    >
                        {value ? "Si" : "No"}
                    </Tag>
                </Popover>
            )
        },
       {
            title: 'Durante il Servizio',
            dataIndex: 'DuranteServizio',
            key: 'DuranteServizio',
            filters: [
                { text: "Si", value: "Si" },
                { text: "No", value: "No" }
           ],
           onFilter: (value, record) => (record.DuranteServizio ? "Si" : "No") === value,
            render: (value) => (
                <Popover title="Operazione applicata durante il Servizio">
                    <Tag
                        className="fs-12"
                        bordered={false}
                        color={value ? "geekblue" : "orange"}
                    >
                        {value ? "Si" : "No"}
                    </Tag>
                </Popover>
            )
        },
        {
            title: 'Codice Articolo',
            dataIndex: 'ArticleCode',
            key: 'ArticleCode',
            ...getColumnSearchProps('ArticleCode'),
        },
        {
            title: 'Nome Articolo',
            dataIndex: 'ArticleDescription',
            key: 'ArticleDescription',
            ...getColumnSearchProps('ArticleDescription'),
        },
        {
            title: 'Codice Categoria',
            dataIndex: 'GroupCode',
            key: 'GroupCode',
            ...getColumnSearchProps('GroupCode'),
        },
        {
            title: 'Categoria',
            dataIndex: 'GroupDescription',
            key: 'GroupDescription',
            filters: categoryFilter,
            render: (value) => <>{cleanRestaurantCategories(value)}</>,
            onFilter: (value, record) => cleanRestaurantCategories(record.GroupDescription)?.includes(value) || false,
        },
        {
            title: 'ID Tavolo',
            dataIndex: 'IDTavolo',
            key: 'IDTavolo',
            filters: idTableFilter,
            onFilter: (value, record) => record.IDTavolo.indexOf(value) === 0,
        },
       {
            title: 'Tipo di Modifica',
            dataIndex: 'TipoModifica',
            key: 'TipoModifica',
            filters: correctionTypeFilter,
            onFilter: (value, record) => convertBaccoCorrectionType(record.TipoModifica)?.includes(value) || false,
            render: (value) => {
                const modificationTags = {
                    "CP"  : { color: "red",   icon: <DeleteOutlined />,     text: "Cancellazione Prodotto" },
                    "CV"  : { color: "red",   icon: <DeleteOutlined />,     text: "Cancellazione Variante" },
                    "CD"  : { color: "red",   icon: <DeleteOutlined />,     text: "Cancellazione Documento" },
                    "APV" : { color: "green", icon: <PlusCircleOutlined />, text: "Prezzo Variante" },
                    "AQP" : { color: "green", icon: <PlusCircleOutlined />, text: "Quantità" },
                    "AV"  : { color: "green", icon: <PlusCircleOutlined />, text: "Variante" },
                    "APP" : { color: "green", icon: <PlusCircleOutlined />, text: "Prezzo" },
                    "DPV" : {                 icon: <MinusCircleOutlined />,text: "Prezzo Variante" },
                    "DQP" : {                 icon: <MinusCircleOutlined />,text: "Quantità" },
                    "DPP" : {                 icon: <MinusCircleOutlined />,text: "Prezzo" },
                    "MM"  : {                 icon: <EditOutlined />,       text: "Modifica Maggiorazione" },
                    "MS"  : {                 icon: <EditOutlined />,       text: "Modifica Sconto" },
                };

                const tag = modificationTags[value];

                return tag ? <Tag className='fs-15' color={tag.color}>{tag.icon} {tag.text}</Tag> : value;
            }
        },
        {
            title: 'Prezzo',
            dataIndex:"PriceOLD",
            key:"PriceOLD",
            render: (value, record) => (
                <Tag
                    className="fs-15"
                    bordered={false}
                    color={
                        record.TipoModifica === "MS" ? "" :
                        record.TipoModifica === "CD" ? "red" :
                        record.PriceNew > record.PriceOLD ? "green" :
                        record.PriceNew < record.PriceOLD ? "red" :
                        ""
                    }
                >
                    {formatEuro(comparePrice(record.PriceOLD, record.PriceNew))} {currency}
                </Tag>
            ),
        },
        {
            title: 'Quantità',
            dataIndex: 'QtaOLD',
            key: 'QtaOLD',
             render: (value, record) => (
                <Tag className="fs-15" bordered={false} color={record.QtaNew > record.QtaOLD ? "green" : "red"}>
                    {compareQuantity(record.QtaOLD, record.QtaNew)}
                </Tag>
            ),
        },
        {
            title: 'Data e Ora',
            dataIndex: 'DateTimeAfterChangeISO8601',
            key: 'DateTimeAfterChangeISO8601',
            render: (value) => <>{dayjs.utc(value).format(dateHourFormatIta)}</>
        },
    ];
    
    // ------------- colonne della tabella ---------------—


    // ------------- calcolo dei dati aggregati come le statistiche rapide ---------------—

    useEffect(() => {
        const data = filteredData?.length > 0 ? filteredData : props.data ?? [];

        if (!data) return;
        const totals = countCorrectionsTotals(data);
        
        setquantityAdded(totals.totalQtaIncrease);
        setquantityRemoved(totals.totalQtaDecrease);
        setvalueAdded(totals.totalPriceIncrease);
        setvalueRemoved(totals.totalPriceDecrease);
        setvalueAfterPreReceipt(totals.totalPriceAfterPreReceipt);
        setvalueWhileService(totals.totalPriceWhileService);

     }, [props.data, filteredData]);
    
    // ------------- calcolo dei dati aggregati come le statistiche rapide ---------------—
console.log(valueAfterPreReceipt)
    return (
        <>
            <Row justify={"center"} id="correctionListTable">
                <Col xs={24} xxl={12}>
                    <div className='mb-30 py-10 flex flex-wrap gap-35 w-100 justify-center mt-20'>
                        
                        <div className='flex-grow'>
                            <Tag bordered={false} className='di-corrections-ribbon'>
                                Prezzo
                            </Tag>
                            <Tag bordered={false} className='flex wrap gap-10 p-10 justify-center align-center m-0 h-100' ref={refCorrectionsTotalValue}>
                                <div>
                                    <div className='flex flex-wrap'>
                                        <Tag bordered={false} color='red' className='fs-15'>{`${formatEuro(valueRemoved)} ${currency}`}</Tag>
                                        <Tag bordered={false} color='green' className='fs-15'>{`+${formatEuro(valueAdded)} ${currency}`}</Tag>
                                    </div>
                                    <Divider className='mt-5 mb-0' />
                                    <div className='w-100 flex align-center justify-center gap-5 fs-18'>
                                        Totale: 
                                        <span className={Math.abs(valueAdded) > Math.abs(valueRemoved) ? "bold color-primary" : "bold color-red"}>
                                            {formatEuro(valueAdded + valueRemoved)}{currency}
                                        </span>
                                    </div>
                                </div>
                            </Tag>
                        </div>
        
                        <div className='flex-grow' ref={refCorrectionsTotalQuantity}>
                            <Tag bordered={false} className='di-corrections-ribbon' color="yellow">
                                Quantità
                            </Tag>
                            <Tag bordered={false} className='flex wrap gap-10 p-10 justify-center align-center m-0 h-100' color='yellow'>
                                <div>
                                    <div className='flex flex-wrap'>
                                        <Tag bordered={false} color='red' className='fs-15'>{quantityRemoved}</Tag>
                                        <Tag bordered={false} color='green' className='fs-15'>{quantityAdded}</Tag>
                                    </div>
                                </div>
                            </Tag>
                        </div>
                        
                        <div className='flex-grow' ref={refTotalWhileService}>
                            <Tag bordered={false} className='di-corrections-ribbon' color="orange">
                                Dopo invio ai reparti
                            </Tag>
                            <Tag bordered={false} className='flex wrap gap-10 p-10 justify-center align-center m-0 mobile-w-100 h-100'  color="orange">
                                <Tag bordered={false} color={(valueAdded + valueRemoved - valueAfterPreReceipt) >= 0 ? "green" : "red"} className='fs-20'>{formatEuro(valueAdded + valueRemoved - valueAfterPreReceipt)}{currency}</Tag>
                            </Tag>
                        </div>

                        <div className='flex-grow' ref={refTotalAfterPreReceipt}>
                            <Tag bordered={false} className='di-corrections-ribbon' color="cyan">
                                Dopo Preconto
                            </Tag>
                             <Tag bordered={false} className='flex wrap gap-10 p-10 justify-center align-center m-0 mobile-w-100 h-100' color="cyan">
                                <Tag bordered={false} color={valueAfterPreReceipt >= 0 ? "green" : "red"} className='fs-20'>{formatEuro(valueAfterPreReceipt)} {currency}</Tag>
                            </Tag>
                        </div>
        
                    </div>
                </Col>
            </Row>

            <div ref={refCorrectionsList}>
                <Table
                    onChange={
                        (pagination, filters, sorter, extra) => {
                            setFilteredData(extra.currentDataSource)
                        } 
                    } 
                    columns={columns}
                    pagination={{
                        defaultPageSize: props.numberOfRows ?? 5,
                    }}
                    className="mobile-x-scroll"
                    dataSource={props.data}
                    scroll={{ x: 'max-content'}}
                    bordered
                />
            </div>
        </>
    );
};
export default TableCorrectionList;
