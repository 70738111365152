import React, { useState } from 'react'

import { Checkbox, Col, Collapse, Divider, Form, InputNumber, Row, Select, TreeSelect, Typography } from 'antd';

import MarketingRemoveFieldIcon from '../../../MarketingRemoveFieldIcon';
import MarketingButtonAddField from '../../../MarketingButtonAddField';
import MarketingDateField from '../../../MarketingDateField';
import { CalendarOutlined, CaretRightOutlined, ControlOutlined, ProductOutlined } from '@ant-design/icons';
import MarketingDynamicDatesCollapse from '../../../MarketingDynamicDatesCollapse';
import MarketingNumberConditionSelect from '../../../MarketingNumberConditionSelect';


const treeData = [
  {
    title: 'Antipasti',
    value: 'cat-1',
    key: 'cat-1',
    children: [
      { title: 'Mozzarella e Prosciutto', value: 'prod-1', key: 'prod-1' },
      { title: 'Bruschetta', value: 'prod-2', key: 'prod-2' },
      { title: 'Olive ascolane', value: 'prod-3', key: 'prod-3' },
      { title: 'Carpaccio di manzo', value: 'prod-4', key: 'prod-4' },
      { title: 'Frittelle di baccalà', value: 'prod-5', key: 'prod-5' },
    ],
  },
  {
    title: 'Primi',
    value: 'cat-2',
    key: 'cat-2',
    children: [
      { title: 'Penne al sugo', value: 'prod-6', key: 'prod-6' },
      { title: 'Spaghetti a vongole', value: 'prod-7', key: 'prod-7' },
      { title: 'Spaghetti alla carbonara', value: 'prod-8', key: 'prod-8' },
      { title: 'Risotto ai funghi', value: 'prod-9', key: 'prod-9' },
      { title: 'Gnocchi al pesto', value: 'prod-10', key: 'prod-10' },
      { title: 'Ravioli di zucca', value: 'prod-11', key: 'prod-11' },
      { title: 'Lasagna', value: 'prod-12', key: 'prod-12' },
      { title: 'Tagliatelle al ragù', value: 'prod-13', key: 'prod-13' },
    ],
  },
  {
    title: 'Secondi',
    value: 'cat-3',
    key: 'cat-3',
    children: [
      { title: 'Bistecca alla fiorentina', value: 'prod-14', key: 'prod-14' },
      { title: 'Pollo arrosto', value: 'prod-15', key: 'prod-15' },
      { title: 'Salmone grigliato', value: 'prod-16', key: 'prod-16' },
      { title: 'Costolette di agnello', value: 'prod-17', key: 'prod-17' },
      { title: 'Frittura di calamari', value: 'prod-18', key: 'prod-18' },
      { title: 'Filetto al pepe verde', value: 'prod-19', key: 'prod-19' },
      { title: 'Cotoletta alla milanese', value: 'prod-20', key: 'prod-20' },
      { title: 'Scaloppine al limone', value: 'prod-21', key: 'prod-21' },
    ],
  },
  {
    title: 'Contorni',
    value: 'cat-4',
    key: 'cat-4',
    children: [
      { title: 'Insalata mista', value: 'prod-22', key: 'prod-22' },
      { title: 'Patate al forno', value: 'prod-23', key: 'prod-23' },
      { title: 'Zucchine grigliate', value: 'prod-24', key: 'prod-24' },
      { title: 'Melanzane alla parmigiana', value: 'prod-25', key: 'prod-25' },
      { title: 'Carciofi fritti', value: 'prod-26', key: 'prod-26' },
      { title: 'Fagiolini al burro', value: 'prod-27', key: 'prod-27' },
      { title: 'Cipolle caramellate', value: 'prod-28', key: 'prod-28' },
      { title: 'Spinaci saltati', value: 'prod-29', key: 'prod-29' },
    ],
  },
  {
    title: 'Dolci',
    value: 'cat-5',
    key: 'cat-5',
    children: [
      { title: 'Tiramisù', value: 'prod-30', key: 'prod-30' },
      { title: 'Panna cotta', value: 'prod-31', key: 'prod-31' },
      { title: 'Gelato misto', value: 'prod-32', key: 'prod-32' },
      { title: 'Torta della nonna', value: 'prod-33', key: 'prod-33' },
      { title: 'Cannoli siciliani', value: 'prod-34', key: 'prod-34' },
      { title: 'Zuppa inglese', value: 'prod-35', key: 'prod-35' },
      { title: 'Cheesecake', value: 'prod-36', key: 'prod-36' },
      { title: 'Profiteroles', value: 'prod-37', key: 'prod-37' },
      { title: 'Biscotti assortiti', value: 'prod-38', key: 'prod-38' },
      { title: 'Sfogliatelle', value: 'prod-39', key: 'prod-39' },
      { title: 'Babà', value: 'prod-40', key: 'prod-40' },
    ],
  },
];


export default function EcommerceFiltersCategoriesProducts({form, labelValue}) {
   
  const [notBought, setNotBought] = useState(false);
  const label = labelValue || "Prodotti";

  return (
     <Form.List name="products_list" initialValue={[]}>
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[10, 10]} className="mb-20">
            {fields.map(({ key, name, ...restField }) => (
              
                <Col xs={24}  key={key} className="flex relative">
                  <Row gutter={[10, 10]} className="w-100 di-filters-group-wrapper">
                  
                    {/* Campo per la selezione dei prodotti */}
                    <Col span={24}>
                      <Typography className=" marketing-field-label">
                        {label}
                      </Typography>
                    
                      <Typography className='fs-12 opacity-05 mb-5'>Seleziona un'intera categoria o specifici prodotti</Typography>
                    
                      <Form.Item
                        {...restField}
                        name={[name, "products"]}
                        key={[key, "products"]}
                      >
                        <TreeSelect
                          treeLine={true}
                          treeData={treeData}
                          treeIcon={true}
                          treeCheckable
                          showCheckedStrategy='SHOW_PARENT'
                          treeNodeFilterProp="title" 
                          allowClear
                          maxTagCount="responsive" // Aumenta dinamicamente l'altezza
                        />
                      </Form.Item>
                    </Col>
                  
                    {/* Campo per la selezione delle varianti */}
                    <Col span={24} className='mt-10'>
                      <Row>
                          <Typography className=" marketing-field-label">
                            <ControlOutlined/> Varianti
                          </Typography>
                          <Divider className='mb-10 mt-10'/>
                      </Row>
                    
                     <Row gutter={[10,10]}>
                        <Col>
                          <Typography className='mb-10'>Taglia</Typography>
                          <Form.Item
                            {...restField}
                            name={[name, "variation_1"]}
                            key={[key, "variation_1"]}
                          >
                            <Select
                              className='min-w-150'
                              showSearch
                              allowClear
                              maxTagCount="responsive"
                              options={[
                                  { label:"Variante 1"  ,value: '1' },
                                  { label:"Variante 2"  ,value: '2' },
                                  { label:"Variante 3"  ,value: '3' },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      
                        <Col>
                          <Typography className='mb-10'>Colore</Typography>
                          <Form.Item
                            {...restField}
                            name={[name, "variation_2"]}
                            key={[key, "variation_2"]}
                          >
                            <Select
                              className='min-w-150'
                              showSearch
                              allowClear
                              maxTagCount="responsive"
                              options={[
                                  { label:"Variante 1"           ,value: '1' },
                                  { label:"Variante 2"           ,value: '2' },
                                  { label:"Variante 3"           ,value: '3' },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      
                     </Row>
                    </Col>
                    
                    <Divider className='mb-10 mt-10' />
                  
                    <Col span={24}>
                      <Form.Item
                        name={[name,"not_bought"]}
                        initialValue={notBought}
                        valuePropName='checked'
                      >
                        <Checkbox onChange={()=>setNotBought(!notBought)}>Non hanno acquistato i prodotti selezionati</Checkbox>
                      </Form.Item>
                    </Col>
                  {/* Campo per la selezione delle quantità acquistate */}
                  
                    {!notBought &&
                      <Col span={24} className='mt-20'>
                        <Typography className="marketing-field-label">
                          Quantità di acquisto {label.toLowerCase()}
                        </Typography>
                      
                        <Row gutter={[10,10]} className='mt-10'>
                          <Col xs={24} sm={18} md={6}>
                            <Typography className='mb-5'>Condizione quantità</Typography>
                            <MarketingNumberConditionSelect nameValue={[name,"product_condition"]} initialValue={"over"}/>
                          </Col>
                        
                          <Col xs={24} sm={6} md={4}>
                            <Typography className='mb-5'>Quantità</Typography>
                            <Form.Item
                              key={[name,"product_amount"]}
                              name={[name,"product_amount"]}
                            >
                              <InputNumber className='w-100' min={1}/>
                            </Form.Item>
                          </Col>
    
                          <Col xs={24} sm={24} md={8}>
                            <Typography className='mb-5'>Condizione per categoria/prodotto</Typography>
                            <Form.Item
                                className='w-100'
                                name={[name,"product_amount_order_scope"]}
                                key={[name,"product_amount_order_scope"]}
                                initialValue={"each"}
                            >
                                <Select
                                  className='min-w-150'
                                  options={[
                                      { label:"In tutte le selezioni"           ,value: 'each' },
                                      { label:"In almeno una delle selezioni"   ,value: 'one' },
                                      { label:"In totale tra le selezioni"      , value: 'all' },
                                  ]}
                                />
                            </Form.Item>
                          </Col>
                        </Row>
                      
                      </Col>
                    }
                    
                    {/* Campo per la selezione delle date */}
                    <MarketingDynamicDatesCollapse
                        label={""}
                        child={
                            <Form.Item>
                              <Form.List name={[name, 'products_dates']}>
                                {(subFields, subOpt) => (
                                  <>
                                    <div className='dynamic-date-wrapper'>
                                        {subFields.map((subField, index) => (
                                          <div className="radius-2 p-10 bg-dark-02 dynamic-date-child mw-sm" key={index}>
                                            <MarketingDateField listName={[name, 'products_dates']} field={subField} index={index} opt={subOpt} />
                                          </div>
                                        ))}
                                    </div>
                                    <div className="mt-20"><MarketingButtonAddField onClick={() => subOpt.add()} label={"Data"}/></div>
                                  </>
                                )}
                              </Form.List>
                          </Form.Item>
                        }
                      />
                  
                  </Row>
                  
                  <MarketingRemoveFieldIcon onClick={()=>remove(name)} />
                </Col>
            ))}
          </Row>

          <MarketingButtonAddField
            onClick={() => add()} 
            label={label}
          />
        </>
      )}
    </Form.List>
  )
}
