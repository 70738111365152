import React, { useEffect, useState } from 'react';

const EmailTemplateStandard = ({ emailData }) => {
  
  const [imageUrl, setimageUrl] = useState(null);


  const styles = {
    container: {
      fontFamily: "'Arial', sans-serif",
      maxWidth: '600px',
      margin: '0 auto',
      backgroundColor: '#f7f7f7',
      overflowY:"scroll"
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: '#f8f8f8',
      borderBottom: '1px solid #ddd',
    },
    logo: {
      height: '50px',
    },
    headerButton: {
      padding: '8px 16px',
      backgroundColor: emailData?.header_button_background_color,
      color: emailData?.header_button_text_color,
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '14px',
    },
    imageContainer: {
      padding: "10px",
    },
    image: {
      width: '100%',
      display: 'block',
      borderRadius: "20px",
      backgroundColor:"#fff",
    },
    content: {
      padding: '20px',
      textAlign: 'center',
    },
    title: {
    fontSize: '24px',
    margin: '10px 0',
    color:"#000",
    },
    text: {
      fontSize: '16px',
      color: '#555',
      lineHeight: '1.5',
    },
    buttonContainer: {
      textAlign: 'center',
      padding: '20px',
    },
    actionButton: {
      padding: '10px 20px',
      backgroundColor: emailData?.footer_button_background_color,
      color: emailData?.footer_button_text_color,
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',
    },
  };
    
  useEffect(() => {
     if (emailData?.file?.length > 0) {
      const file = emailData?.file[0].originFileObj;
      const imageUrl = URL.createObjectURL(file);
      setimageUrl(imageUrl)
    } else {
      setimageUrl(null)
    }
    
  }, [emailData])
  
  return (
    <div style={styles.container}>
      {/* Header */}
      <div style={styles.header}>
        <img
          src="https://via.placeholder.com/150x50" // Sostituisci con il logo reale
          alt="Logo"
          style={styles.logo}
        />
        {emailData?.header_button_label && <button style={styles.headerButton}>{emailData?.header_button_label}</button>}
      </div>

      {/* Foto */}
      {imageUrl &&
        <div style={styles.imageContainer}>
          <img
            src={imageUrl}
            alt=""
            style={styles.image}
          />
        </div>
      }
      {/* Testo */}
      <div style={styles.content}>
        <h1 style={styles.title}>{emailData?.title}</h1>
        <p style={styles.text}>
          {emailData?.description}
        </p>
      </div>

      {/* Pulsante */}
      {emailData?.footer_button_label &&
        <div style={styles.buttonContainer}>
          <button style={styles.actionButton}>{emailData?.footer_button_label}</button>
        </div>
      }
    </div>
  );
};

export default EmailTemplateStandard;
