import React, { useState } from 'react';
import ReactDOM from 'react-dom/client'; 

import { ConfigProvider, Drawer, theme } from 'antd';

import MarketingAudienceCreateContent from './MarketingAudienceCreateContent';
import { DARK_BG_CONTAINER } from '../../../helpers/Globals';
import confirmModal from '../../DiConfirmModal';

const createAudience = (context, audienceId) => {
   
   // ------------- Crea un div temporaneo nel DOM per far comparire il drawer -------------
   const drawerContainer = document.createElement('div');
   document.body.appendChild(drawerContainer);
   // ------------- Crea un div temporaneo nel DOM per far comparire il drawer -------------

   // ------------- Componente Drawer -------------
   const DrawerComponent = () => {
      
      const [open, setOpen] = useState(true);
      const { darkMode } = context;

      const handleClose = async () => {
      
         console.log("inizio la chiusura");

         const result = await confirmModal(
            "Hai effettuato delle modifiche, chiudendo le perderai... Consigliamo di salvare il pubblico. Sicuro di voler chiudere?",
            context
         ); //attendo la risposta dal componente di conferma modale

         if (result) {
            setOpen(false); 
         } else {
            console.log("resto aperto");
         }

      };

      
      return (
            <ConfigProvider
               theme={
               {
                  algorithm: darkMode ? theme.darkAlgorithm : theme.compactAlgorithm,
                  token:{
                     colorPrimary: "#008833",
                     colorBgContainer: darkMode ? DARK_BG_CONTAINER : "#fff"
                  }
               }
               }
            >
               <Drawer
                  className='di-create-audience-drawer'
                  onClose={handleClose}
                  title={`${audienceId ? "Modifica" : "Crea"} il tuo Pubblico`}
                  placement="bottom"
                  height="100%"
                  open={open}
                  destroyOnClose
               >
                  <MarketingAudienceCreateContent contextValue={context} />
               </Drawer>
            </ConfigProvider>
      );
   };
   // ------------- Componente Drawer -------------

   // ------------- rendering effettivo del drawer -------------
   const root = ReactDOM.createRoot(drawerContainer);
   root.render(<DrawerComponent />);
   // ------------- rendering effettivo del drawer -------------

};

export default createAudience;
