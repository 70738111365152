import React, { useContext, useState } from 'react'

import { Col, Collapse, Divider, Form, InputNumber, Row, Select, TreeSelect, Typography } from 'antd';

import MarketingNumberConditionSelect from '../../../MarketingNumberConditionSelect';
import MarketingDynamicDatesCollapse from '../../../MarketingDynamicDatesCollapse';
import MarketingRemoveFieldIcon from '../../../MarketingRemoveFieldIcon';
import MarketingButtonAddField from '../../../MarketingButtonAddField';
import MarketingDateField from '../../../MarketingDateField';


export default function EcommerceFiltersOrders({ form, labelValue }) {
   
  const label = labelValue || "Ordini";
  
  return (
     <Form.List name="orders_list" initialValue={[]}>
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[10, 10]} className="mb-20">
            {fields.map(({ key, name, ...restField }) => (
              
                <Col xs={24}  key={key} className="flex relative">
                  <Row gutter={[10, 10]} className="w-100 di-filters-group-wrapper">
                    
                    {/* Campo per la selezione del numero ordine */}
                    <Col span={24} className='mt-20'>
                      <Typography className=" marketing-field-label">
                          {label}
                      </Typography>
                    
                      <Row gutter={[10,10]} className='mt-10'>
                        <Col xs={24} sm={18} md={6}>
                          <Typography className='mb-5'>Condizione quantità</Typography>
                          <MarketingNumberConditionSelect allowClear nameValue={[name, 'orders_condition']}/>
                        </Col>
                      
                        <Col xs={24} sm={6} md={4}>
                          <Typography className='mb-5'>Quantità</Typography>
                          <Form.Item name={[name, 'orders_quantity']}>
                            <InputNumber className='w-100' min={1}/>
                          </Form.Item>
                        </Col>
                           
                      </Row>
                    
                    </Col>
                    
                    {/* Campo per la selezione del valore ordini */}
                    <Col span={24} className='mt-20'>
                      <Typography className=" marketing-field-label">
                          Valore Ordini
                      </Typography>
                    
                      <Row gutter={[10,10]} className='mt-10'>
                        <Col xs={24} sm={18} md={6}>
                          <Typography className='mb-5'>Condizione valore</Typography>
                          <MarketingNumberConditionSelect allowClear nameValue={[name, 'orders_value_condition']}/>
                        </Col>
                      
                        <Col xs={24} sm={6} md={4}>
                          <Typography className='mb-5'>Incasso</Typography>
                          <Form.Item name={[name, 'orders_value_amount']}>
                            <InputNumber className='w-100' min={0} suffix={"€"} />
                          </Form.Item>
                        </Col>
                           
                        <Col xs={24} sm={24} md={8}>
                            <Typography className='mb-5'>Condizione calcolo valore</Typography>
                            <Form.Item
                              className='w-100'
                              name={[name, 'orders_value_calculation']}
                            >
                            <Select
                              allowClear
                                className='min-w-150'
                                options={[
                                  { label:"Per singolo ordine"  ,value: 'each' },
                                  { label:"In totale"           ,value: 'all' },
                                  { label:"Almeno un ordine"    , value: 'one' },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                      
                      </Row>
                    
                    </Col>
                    
                    {/* Campo per la selezione delle date */}
                    <MarketingDynamicDatesCollapse
                        label={" di emissione Ordini"}
                        child={
                            <Form.Item>
                              <Form.List name={[name, 'orders_dates']}>
                                {(subFields, subOpt) => (
                                  <>
                                    <div className='dynamic-date-wrapper justify-center'>
                                        {subFields.map((subField, index) => (
                                          <div className="radius-2 p-10 bg-dark-02 dynamic-date-child mw-sm" key={index}>
                                            <MarketingDateField listName={[name, 'orders_dates']} field={subField} index={index} opt={subOpt} />
                                          </div>
                                        ))}
                                    </div>
                                    <div className="mt-20"><MarketingButtonAddField onClick={() => subOpt.add()} label={"Data"}/></div>
                                  </>
                                )}
                              </Form.List>
                          </Form.Item>
                        }
                      />
                  
                  </Row>
                  
                  <MarketingRemoveFieldIcon onClick={()=>remove(name)} />
                </Col>
            ))}
          </Row>

          <MarketingButtonAddField
            onClick={() => add()} 
            label={label}
          />
        </>
      )}
    </Form.List>
  )
}
