import { Col, Row, Typography } from 'antd'
import React from 'react'

import ChannelWhatsAppPreview from './ChannelWhatsAppPreview'
import ChannelWhatsAppForm from './ChannelWhatsAppForm'

export default function ChannelWhatsAppContent() {
   
  return (
    <div className='operations-group-whatsapp'>
       <Row gutter={[20,20]} className='mt-20'>
           <Col xs={24} md={14}>
              <ChannelWhatsAppForm/>
           </Col>
           <Col xs={24} md={10}>
               <Typography className='fs-18 bold mb-10 text-center'>
                  Preview
               </Typography>
               <ChannelWhatsAppPreview/>
           </Col>
        </Row>
    </div>
  )
}
