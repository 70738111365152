import React, { useContext, useEffect, useState } from 'react'

import EmailTemplateStandard from '../../../../email_templates/EmailTemplateStandard';
import { CreateOperationDataContext } from '../../../../../helpers/Context';
import MarketingDeviceLayout from '../../MarketingDeviceLayout';

export default function ChannelEmailPreview() {
  

  const { emailData, formChanged } = useContext(CreateOperationDataContext);
  const [imageUrl, setimageUrl] = useState(null)
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (!emailData?.description && !emailData?.link) {
      setLoad(true)
    } else {
      setLoad(false)
    }

    if (emailData?.file?.length > 0) {
      const file = emailData?.file[0].originFileObj;
      const imageUrl = URL.createObjectURL(file);
      setimageUrl(imageUrl)
    } else {
      setimageUrl(null)
    }
    
  }, [emailData, formChanged])
  
  return (
     <div className="preview-container">
      {/* Schermata dispositivo */}
      <MarketingDeviceLayout >
        <div className='h-100 flex column space-between'>
         
              <EmailTemplateStandard emailData={emailData}/>
              
        </div>
      </MarketingDeviceLayout>
    </div>
  )
}
