function cleanRestaurantCategories(str) {
    if (!str) return;
    if (str.startsWith('--') && str.endsWith('--')) {
        return str.slice(2, -2);
    }
    return str;
}

function convertBaccoCorrectionType(str) {
    const types = {
        "CP":"Cancellazione Prodotto",
        "DQP":"Diminuzione Quantità Prodotto",
        "AQP":"Aumento Quantità Prodotto",
        "DPP":"Diminuzione Prezzo Prodotto",
        "APP":"Aumento Prezzo Prodotto",
        "AV":"Aumento Variante",
        "CV":"Cancellazione Variante",
        "APV":"Aumento Prezzo Variante",
        "DPV":"Diminuzione Prezzo Variante",
        "CD":"Cancellazione Documento",
        "MM":"Modifica Maggiorazione",
        "MS":"Modifica Sconto",
    };

    return types[str] || str;
}


export {
   convertBaccoCorrectionType,
   cleanRestaurantCategories
};