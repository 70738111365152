import React, { useContext, useRef, useState } from 'react';

import { Card, Form, Input, Typography } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

import MarketingOperationCopyButton from '../../MarketingOperationCopyButton';
import { CreateOperationDataContext } from '../../../../../helpers/Context';
import MarketingOperationShortcode from '../../MarketingOperationShortcode';
import { REGEX_HTTPS } from '../../../../../helpers/Globals';

export default function ChannelSmsForm() {
   
   const { form, onInputFocus, handleFormChange } = useContext(CreateOperationDataContext);

   const smsDescriptionRef = useRef(null);
    const formPrefix = "sms_data";

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 }, 
        md: { span: 10 },
        lg: { span: 12 },
        xl: { span: 8 },
        xxl: { span:6 },
      },
    };
    const formItemOrientation = "horizontal";

  return (
    <Card className='bg-dark-02'>
      
      <MarketingOperationShortcode/>

      <Typography className="fs-16 bold mb-10">
        <MessageOutlined /> Dati SMS
      </Typography>

      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "description"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "description"]}
            />
            Descrizione
          </div>
        }
        rules={[
          {
            required: true,
            message: "Per favore, inserisci il testo del messaggio!",
          },
        ]}
      >
        <TextArea
          onFocus={() => onInputFocus(smsDescriptionRef, [formPrefix, "description"])}
          onChange={handleFormChange}
          ref={smsDescriptionRef}
          maxLength={100}
          showCount
          allowClear
        />
      </Form.Item>
      
      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "link"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "link"]}
            />
            Link
          </div>
        }
        rules={[
          {
            type: 'url',
            message: "Il link deve essere un url valido.",
          },
          {
            type: 'string',
          },
          {
            pattern: REGEX_HTTPS,
            message: 'L\'URL deve iniziare con "https://"',
          },
        ]}
      >
        <Input allowClear/>
      </Form.Item>
    </Card>
  );
}
