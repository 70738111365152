import React, { useContext, useEffect, useState } from 'react'

import { Input } from 'antd';
import { CameraOutlined, CommentOutlined, LoadingOutlined } from '@ant-design/icons';

import { CreateOperationDataContext } from '../../../../../helpers/Context';
import MarketingDeviceLayout from '../../MarketingDeviceLayout';

export default function ChannelSmsPreview() {

  const { smsData } = useContext(CreateOperationDataContext);
  const [load, setLoad] = useState(true);
  
  useEffect(() => {
    if (!smsData?.description && !smsData?.link) {
      setLoad(true)
    } else {
      setLoad(false)
    }
  },[smsData])
  
  return (
   <div className="preview-container">
      {/* Schermata dispositivo */}
        <MarketingDeviceLayout
           appIcon={<CommentOutlined className='fs-16' />}
           appName={"SMS"}
        >
        <div className='h-100 flex column justify-end'>
          
          <div className="bubble text-wrap sent bg-dark-02">
            <div className='mb-5'>{!load ? smsData?.description : <LoadingOutlined />} </div>
            <a>{!load &&  smsData?.link}</a>
          </div>

          <div className='p-10 bg-dark-02'>
           <div className='flex gap-10'>
              <CameraOutlined/>
              <Input disabled/>
           </div>
          </div>
          
        </div>
      </MarketingDeviceLayout>
    </div>
  )
}



