import React, { useContext, useEffect, useState } from 'react';

import { Button, Col, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import { Context } from '../helpers/Context';

var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

dayjs.updateLocale('en', {
  relativeTime: {
    future: "%s",
    past: "%s fa",
    s: 'pochi secondi',
    m: "un minuto",
    mm: "%d minuti",
    h: "un'ora",
    hh: "%d ore",
    d: "un giorno",
    dd: "%d giorni",
    M: "un mese",
    MM: "%d mesi",
    y: "un anno",
    yy: "%d anni"
  }
})

function formatEuro(number){
  return Number(number.toFixed(2)).toLocaleString("es-ES", {minimumFractionDigits: 2});
}


function TableCard(props) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastOrderTime, setLastOrderTime] = useState("");
  const [openTableTime, setOpenTableTime] = useState("");
  const [tableEarn, setTableEarn] = useState(0);
  const [lines, setLines] = useState([]);
  const table_data = props.table_data;

  const { currency } = useContext(Context);
  
  //calcolo i dati del tavolo
  useEffect(()=>{

    const lines = table_data.lines;
    var tempTotalEarn = 0;
    
    if(lines){
      lines.forEach(e => {
        tempTotalEarn += e.amount;
      });
    }

    setTableEarn(tempTotalEarn)
    setLines(lines);
    
    lines.sort((a,b) => a.dish_date - b.dish_date);
    setOpenTableTime(lines[0].dish_date);
    setLastOrderTime(lines[lines.length - 1].dish_date);

  },[lines])

  const showModal = () => {
    setIsModalOpen(true);
  };;
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="text" className='table-button h-100 p-0 radius-4' onClick={showModal} block>
        <div className='di-card w-100'>
          <Row align={"space-between"}>
            <Col className='fs-15 fw-6'>
              Tavolo {table_data.table}
            </Col>
          </Row>
          <Row align={"space-between"}>
            Aperto da {dayjs().from(dayjs(openTableTime))}
          </Row>

          <Row className='fs-20 fw-5 color-primary'>
            {formatEuro(tableEarn)+currency}
          </Row>

          <Row>
            Ultimo ordine {dayjs().from(dayjs(lastOrderTime))} fa
          </Row>
        </div>
      </Button>

      {/* modal di dettaglio ordine */}
      <Modal destroyOnClose={true} className='opened-table-modal' title={"Tavolo " + table_data.table} open={isModalOpen} onCancel={handleCancel} footer={null}>
        {lines?
          lines.map((e, index) => {
            if (e.description === "SERVIZIO" && e.amount === 0) return <></>;
            return(
              <Row align={"space-between"} key={index}>
                <Col span={20}   className='fs-15'>
                  {e.quantity +" x "+ e.description}
                </Col>
                <Col span={4} align="end" className='fs-18 fw-5'>
                  {formatEuro(e.amount)+currency}
                </Col>
              </Row>
            )
          })
          :
           ""
        }
        <Row>
          <Col span={24} align="end" className='mt-10 fs-20 fw-5 color-primary'>
            Totale {formatEuro(tableEarn)+currency}
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default TableCard;