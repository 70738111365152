import React, { useContext, useEffect, useState } from 'react'

import dayjs from 'dayjs';

import { ArrowDownOutlined, ArrowUpOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, InputNumber, Popover, Row, Skeleton, Spin, Statistic, Switch } from 'antd'
import * as Unicons from '@iconscout/react-unicons';

import { COMPARE_GREEN, COMPARE_RED, ENDPOINT_PERIOD_SALES, LOCAL_FAST_CUSTOMER_COMPARE_1, LOCAL_FAST_CUSTOMER_COMPARE_2, LOCAL_FAST_CUSTOMER_COMPARE_3, PRIMARY_COLOR, TOOLTIP_DIFF_TEXT } from '../../../helpers/Globals';
import getPercentage from '../../../helpers/GetPercentage';
import { getOldDate } from '../../../helpers/DateUtils';
import { Context } from '../../../helpers/Context';
import { ApiCall } from '../../../Backoffice';

export default function DisplayFastDailyCustomer() {
   
   //settaggi per gli scontrini chiusi
   const [dailyCustomersOldDay3, setDailyCustomersOldDay3] = useState(null);
   const [dailyCustomersOldDay1, setDailyCustomersOldDay1] = useState(null);
   const [dailyCustomersOldDay2, setDailyCustomersOldDay2] = useState(null);
   const [diffCustomerOldDay3, setDiffCustomerOldDay3] = useState(null);
   const [diffCustomerOldDay1, setDiffCustomerOldDay1] = useState(null);
   const [diffCustomerOldDay2, setDiffCustomerOldDay2] = useState(null);
   const [serviceQuantiy, setServiceQuantity] = useState(null);
   const [dailyCustomers, setDailyCustomers] = useState(null);

   const [showCustomerPercentage, setShowCustomerPercentage] = useState(false);
   
   const [loadingDiff1, setLoadingDiff1] = useState(true); 
   const [loadingDiff2, setLoadingDiff2] = useState(true); 
   const [loadingDiff3, setLoadingDiff3] = useState(true); 

   // --------- setto i giorni di deafult per le statistiche rapide e personalizzabili ---------
   const [oldDay1, setOldDay1] = useState(localStorage.getItem(LOCAL_FAST_CUSTOMER_COMPARE_1) ? localStorage.getItem(LOCAL_FAST_CUSTOMER_COMPARE_1) : 1); 
   const [oldDay2, setOldDay2] = useState(localStorage.getItem(LOCAL_FAST_CUSTOMER_COMPARE_2) ? localStorage.getItem(LOCAL_FAST_CUSTOMER_COMPARE_2) : 7); 
   const [oldDay3, setOldDay3] = useState(localStorage.getItem(LOCAL_FAST_CUSTOMER_COMPARE_3) ? localStorage.getItem(LOCAL_FAST_CUSTOMER_COMPARE_3) : 28); 
   // --------- setto i giorni di deafult per le statistiche rapide e personalizzabili ---------
   
// ---- variabili dal context -----
const {
   commonQueryParams,
   refreshVariables,
   accessToken,
   useCurrentTime,
} = useContext(Context);
// ---- variabili dal context -----

// ---------------------  chiamata giornaliera per ricevere i dati delle ${ENDPOINT_PERIOD_SALES} da cui ottengo il totale venduto giornaliero, il totale clienti serviti, prodotti venduti e documenti emessi --------------------- 
useEffect(()=>{

   (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${dayjs().format('YYYY-MM-DDT00:00:00')}&end_date=${dayjs().format('YYYY-MM-DDT23:59:59')}&${commonQueryParams}`, "GET", `${accessToken}`, null)
   
      if(json){
      var sales = json.sales
      var dailyCustomers = 0;
      var dailyServiceQuantity = 0;

      for (const i in sales) {
         for (let n in sales[i]["lines"]) {
            if(sales[i]["lines"][n]["is_cover"] === 1){
            dailyCustomers += sales[i]["lines"][n]["quantity"];
            }

            if (sales[i]["lines"][n]["code"] === "SERVIZIO") {
               dailyServiceQuantity += sales[i]["lines"][n]["quantity"];
            }
         }
      }
      setServiceQuantity(dailyServiceQuantity);
      setDailyCustomers(dailyCustomers);
      }
   })(); 
}, [...refreshVariables]);
   
// ---------------------  chiamata per ricevere le statistiche rapide sul primo confronto --------------------- 
useEffect(() => {
   if (dailyCustomers === null) return;
   
   setLoadingDiff1(true);

   var fullOldDate = getOldDate(oldDay1);
   var oldDate = fullOldDate.split("T",1)[0];

   if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi

   (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate+"T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
      if(json){
         var sales = json.sales
         var dailyCustomersOldDay1Temp = 0;

         for (const i in sales) {
            for (let n in sales[i]["lines"]) {
               if(sales[i]["lines"][n]["is_cover"] === 1){
               dailyCustomersOldDay1Temp += sales[i]["lines"][n]["quantity"];
               }
            }
         }
         if (dailyCustomersOldDay1Temp == dailyCustomersOldDay1) {
            setLoadingDiff1(false);
         }
         setDailyCustomersOldDay1(dailyCustomersOldDay1Temp);
      }
   })(); 

}, [...refreshVariables, useCurrentTime, dailyCustomers, oldDay1]);
   
// ---------------------  chiamata per ricevere le statistiche rapide sul primo confronto --------------------- 
   
// ---------------------  chiamata per ricevere le statistiche rapide sul secondo confronto --------------------- 
useEffect(() => {
   if (dailyCustomers === null) return;

   setLoadingDiff2(true);

   var fullOldDate = getOldDate(oldDay2);
   var oldDate = fullOldDate.split("T",1)[0];

   if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi


   (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate+"T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      if (json) {
            
         var sales = json.sales
         var dailyCustomersOldDay2Temp = 0;

         for (const i in sales) {
            for (let n in sales[i]["lines"]) {
               if(sales[i]["lines"][n]["is_cover"] === 1){
               dailyCustomersOldDay2Temp += sales[i]["lines"][n]["quantity"];
               }
            }
         }
         if (dailyCustomersOldDay2Temp == dailyCustomersOldDay2) {
            setLoadingDiff2(false);
         }
         setDailyCustomersOldDay2(dailyCustomersOldDay2Temp);
      }
   })(); 

}, [...refreshVariables, useCurrentTime, dailyCustomers, oldDay2]);
    
// ---------------------  chiamata per ricevere le statistiche rapide sul secondo confronto --------------------- 
   
// --------------------- chiamata per ricevere le statistiche rapide sul terzo confronto --------------------- 
useEffect(() => {
   if (dailyCustomers === null) return;
    
   setLoadingDiff3(true);

   var fullOldDate = getOldDate(oldDay3);
   var oldDate = fullOldDate.split("T",1)[0];

   if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti confronto i dati fino all'ora corrente di oggi


   (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate+"T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
      if(json){
         var sales = json.sales
         var dailyCustomersOldDay3Temp = 0;

         for (const i in sales) {
            for (let n in sales[i]["lines"]) {
               if(sales[i]["lines"][n]["is_cover"] === 1){
               dailyCustomersOldDay3Temp += sales[i]["lines"][n]["quantity"];
               }
            }
         }
         if (dailyCustomersOldDay3Temp == dailyCustomersOldDay3) {
            setLoadingDiff3(false);
         }
         setDailyCustomersOldDay3(dailyCustomersOldDay3Temp);
      }
   })(); 

}, [...refreshVariables, useCurrentTime, dailyCustomers, oldDay3]);
// --------------------- chiamata per ricevere le statistiche rapide sul terzo confronto --------------------- 

// ---------------------  settaggio dei dati giorni precedenti --------------------- 
   useEffect(() => {
      if (dailyCustomersOldDay1 === null ) return;
      setLoadingDiff1(true);
      setDiffCustomerOldDay1(getPercentage(dailyCustomers,dailyCustomersOldDay1));
   }, [dailyCustomers, dailyCustomersOldDay1])

   useEffect(() => {
      if (dailyCustomersOldDay2 === null) return;
      setLoadingDiff2(true);
      setDiffCustomerOldDay2(getPercentage(dailyCustomers,dailyCustomersOldDay2));
   }, [dailyCustomers, dailyCustomersOldDay2])
   
   useEffect(() => {
      if (dailyCustomersOldDay3 === null) return;
      setLoadingDiff3(true);
      setDiffCustomerOldDay3(getPercentage(dailyCustomers,dailyCustomersOldDay3));
   }, [dailyCustomers, dailyCustomersOldDay3])
   
   useEffect(() => {
      if (diffCustomerOldDay1 === null) return;
      setLoadingDiff1(false);
  }, [diffCustomerOldDay1])
   useEffect(() => {
      if (diffCustomerOldDay2 === null) return;
      setLoadingDiff2(false);
  }, [diffCustomerOldDay2])
   useEffect(() => {
      if (diffCustomerOldDay3 === null) return;
      setLoadingDiff3(false);
  }, [diffCustomerOldDay3])
// ---------------------  settaggio dei dati giorni precedenti --------------------- 

  // ----------- contenuto del popover per cambiare le comparazioni -----------
  
  const [showCalendar, setShowCalendar] = useState(true);
  const [popoverOldDay1, setPopoverOldDay1] = useState(1); 
  const [popoverOldDay2, setPopoverOldDay2] = useState(1); 
  const [popoverOldDay3, setPopoverOldDay3] = useState(1); 
  
  const popoverContent = (param) => (
    <div>
      <p>{TOOLTIP_DIFF_TEXT}</p>
      <div className='flex gap-5 align-center'>
          {!showCalendar ? <InputNumber
            min={1}
            prefix="-"
            defaultValue={
              param === 1 ? oldDay1 :
              param === 2 ? oldDay2 :
              param === 3 ? oldDay3 :
              1
            }
            onChange={(value) => {
              switch (param) {
                case 1:
                  setPopoverOldDay1(value)
                  break;
                case 2:
                  setPopoverOldDay2(value)
                  break;
                case 3:
                  setPopoverOldDay3(value)
                  break;
              
                default:
                  break;
              }
            }
            }
            changeOnWheel
           />
            :
           <DatePicker
            size='medium'
            placeholder='Scegli giorno'
            onChange={(value) => {
                var day = dayjs(value).diff(dayjs(), "day", true).toString().split(".")[0].replace("-","");
                if (day === "un") day = 1;
                switch (param) {
                  case 1:
                    setPopoverOldDay1(day)
                    break;
                  case 2:
                    setPopoverOldDay2(day)
                    break;
                  case 3:
                    setPopoverOldDay3(day)
                    break;
                
                  default:
                    break;
                }
              }
            }
          />}
          <Button
            type='primary'
            size='medium'
            onClick={() => {
              
              localStorage.setItem(
                param === 1 ? LOCAL_FAST_CUSTOMER_COMPARE_1 :
                param === 2 ? LOCAL_FAST_CUSTOMER_COMPARE_2 :
                param === 3 ? LOCAL_FAST_CUSTOMER_COMPARE_3 :
                "",
                param === 1 ? popoverOldDay1 :
                param === 2 ? popoverOldDay2 :
                param === 3 ? popoverOldDay3 :
                ""
              )

               switch (param) {
                case 1:
                  setOldDay1(popoverOldDay1)
                  break;
                case 2:
                  setOldDay2(popoverOldDay2)
                  break;
                case 3:
                  setOldDay3(popoverOldDay3)
                  break;
              
                default:
                  break;
                }
              
            }}
          >
            Salva
           </Button>
         <Switch
          checkedChildren="n"
          unCheckedChildren={<CalendarOutlined />}
          onChange={()=>setShowCalendar(!showCalendar)}
        />
      </div>
    </div>
  );
   // ----------- contenuto del popover per cambiare le comparazioni ----------- 
   

  return (
     <div className="di-card h-100 flex column">
      <Spin className='absolute bottom-20 right-30'  spinning={loadingDiff1 || loadingDiff2 || loadingDiff3}  />
        <Row gutter={[15, 15]} justify="center" align="middle">
           

         <Unicons.UilSmileBeam className="color-primary" />
           
         <Col align="center">
            <div className='fs-18 fw-5'>Clienti serviti oggi</div>
         </Col>
      
         <Col align="center">
            <div className='flex gap-5 align-center'>
            <Switch
               checkedChildren="%"
               unCheckedChildren="n"
               onChange={()=>setShowCustomerPercentage(showCustomerPercentage ? false : true)}
            />
            </div>
         </Col>
      
      </Row>
      <Divider className='mt-10 mb-10'/>
      
      <Row gutter={[15, 15]} justify="center" align="middle">
           <Col xs={24} xl={4} align="center" className='fs-30'>
              {dailyCustomers === null ?
                  <Skeleton.Button active/>
                :
                  <>{dailyCustomers}</>
               }   
         </Col>
      </Row>
      
      <Divider className='mt-10 mb-10'/>
      <div className='flex-grow flex column justify-center'>
         <Row justify="center"><div className='fs-12 color-grey'>{showCustomerPercentage ? "Confronti" : "Totali" }</div></Row>
         <Row gutter={[15, 15]} justify="space-around" align="middle">
            <Popover
               className='cursor-pointer'
               content={()=>popoverContent(1)}
               trigger={"click"}
            >
               <Col align="center">
                  {showCustomerPercentage ?
                  <Statistic
                     groupSeparator="."
                     decimalSeparator=','
                     title={`-${oldDay1}gg`}
                     loading={loadingDiff1}
                     value={diffCustomerOldDay1}
                     valueStyle={{
                        color: diffCustomerOldDay1>0?COMPARE_GREEN : COMPARE_RED,
                     }}
                     prefix={ 
                        diffCustomerOldDay1 > 0 ? <ArrowUpOutlined />
                     :
                        diffCustomerOldDay1 < 0 ? <ArrowDownOutlined />
                     :
                        ""
                     }
                     suffix="%"
                  />
                  :
                     <Statistic
                        title={`-${oldDay1}gg`}
                        loading={loadingDiff1}
                        value={dailyCustomersOldDay1}
                        valueStyle={{color: PRIMARY_COLOR}}
                     />
                  }
               </Col>
               
           </Popover>
            
            <Popover
               className='cursor-pointer'
               content={()=>popoverContent(2)}
               trigger={"click"}
            >
               <Col align="center">
                  {showCustomerPercentage ?
                     <Statistic
                     groupSeparator="."
                     decimalSeparator=','
                        title={`-${oldDay2}gg`}
                     loading={loadingDiff2}
                     value={diffCustomerOldDay2}
                     valueStyle={{
                        color: diffCustomerOldDay2 > 0?COMPARE_GREEN : COMPARE_RED,
                     }}
                     prefix={ 
                        diffCustomerOldDay2 > 0 ? <ArrowUpOutlined />
                     :
                        diffCustomerOldDay2 < 0 ? <ArrowDownOutlined />
                     :
                        ""
                     }
                     suffix="%"
                     />
                  :
                     <Statistic
                        groupSeparator="."
                        decimalSeparator=','
                        title={`-${oldDay2}gg`}
                        loading={loadingDiff2}
                        value={dailyCustomersOldDay2}
                        valueStyle={{color: PRIMARY_COLOR}}
                     />
                  }
               </Col>
           </Popover>
            
            <Popover
               className='cursor-pointer'
               content={()=>popoverContent(3)}
               trigger={"click"}
            >
               <Col align="center">
                  {showCustomerPercentage ?
                     <Statistic
                        groupSeparator="."
                        decimalSeparator=','
                        title={`-${oldDay3}gg`}
                        loading={loadingDiff3}
                        value={diffCustomerOldDay3}
                        valueStyle={{
                           color: diffCustomerOldDay3>0?COMPARE_GREEN : COMPARE_RED,
                        }}
                        prefix={ 
                           diffCustomerOldDay3 > 0 ? <ArrowUpOutlined />
                        :
                           diffCustomerOldDay3 < 0 ? <ArrowDownOutlined />
                        :
                           ""
                        }
                        suffix="%"
                     />
                  :
                     <Statistic
                        groupSeparator="."
                        decimalSeparator=','
                        title={`-${oldDay3}gg`}
                        loading={loadingDiff3}
                        value={dailyCustomersOldDay3}
                        valueStyle={{color: PRIMARY_COLOR}}
                     />
                  }
               </Col>
            </Popover>
         </Row>
      </div>
      </div>
  )
}
