import React from 'react'

import { DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons'

export default function MarketingRemoveFieldIcon({ onClick, trashIcon }) {
  
  const classes = "color-red absolute top-0 right-0";

  return (
    trashIcon ? 
        <DeleteOutlined  onClick={onClick} className={classes} />
      :
        <MinusCircleOutlined onClick={onClick} className={classes} />
  )
}
