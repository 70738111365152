import React, { useContext, useEffect, useState } from 'react'

import { InfoOutlined, SaveOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import { Button, Popover, Tour } from 'antd'

import { LOCAL_LAST_VERSION } from '../../helpers/Globals';
import { Context } from '../../helpers/Context';

export default function DiDashboardTour() {
   
    const [showDot, setShowDot] = useState(localStorage.getItem(LOCAL_LAST_VERSION) && localStorage.getItem(LOCAL_LAST_VERSION) == "1.2.4" ? true : false)
    const [open, setOpen] = useState(false);
    const [steps, setSteps] = useState([]);

    const location = useLocation();
    const path = [...location.pathname].slice(1).join('');

    const {
        setEditLayout,
        refSelledProductsQuantitySwitcher,
        refSelledProductsSelectCategories,
        refSelledProductsResetCategories,
        refSelledProductsSaveCategories,
        refCorrectionsTotalQuantity,
        refCorrectionsTotalValue,
        refTotalAfterPreReceipt,
        refTotalWhileService,
        refEarnTimeSwitcher,
        refCorrectionsList,
        refSelledProducts,
        refShowPercentage,
        refResetLayout,
        refEditLayout,
        refChangeDay,
        refEarnTime,
        refFastStat,
        refDarkMode,
        refVersion,
        refColors,
        refFilter,
        refRange,
        refCsv,
    } = useContext(Context);

  const nextLabel = "Avanti";
  const backLabel = "indietro";

    const commonSteps = [
      {
        title: 'Filtra i tuoi dati',
        description: 'Scegli quali grafici vedere nella tua bacheca, ed imposta come interrogare i tuoi dati',
        target: () => refFilter.current,
        prevButtonProps: {
          children: backLabel, 
        },
        nextButtonProps: {
          children: nextLabel,
        },
      },
      {
        title: 'Scegli il tuo tema',
        description: 'Imposta la tua dashboard con il tema scuro o chiaro e scegli ciò che preferisci!',
        cover: (
          <img
            alt="tour.png"
            src="/dylogapp-dark-light.jpg"
          />
        ),
        target: () => refDarkMode.current,
        prevButtonProps: {
          children: backLabel, 
        },
        nextButtonProps: {
          children: nextLabel,
        },
      },
      {
        title: 'Modifica la disposizione',
        description: 'Attiva il tuo editor, e disponi i grafici come preferisci, il salvataggio è automatico!',
        target: () => refEditLayout.current,
        prevButtonProps: {
          children: backLabel, 
        },
        nextButtonProps: {
          onClick: () => setEditLayout(true),
          children: nextLabel,
        },
      },
      {
        title: 'Ripristina il layout base',
        description: 'Non sei convinto del layout o vuoi ripristinare la nostra configurazione consigliata? Clicca il tasto di reset',
        target: () => refResetLayout.current,
        prevButtonProps: {
          children: backLabel, 
        },
        nextButtonProps: {
          onClick: () => setEditLayout(false),
          children: nextLabel,
        },
      },
    ];
  
  
  useEffect(() => {

    var tempSteps = [];

    switch (path) {
      case "fast-stats":
      case "":
           tempSteps = [
            {
              title: 'Statistica Rapida',
              description: 'Le statistiche rapide mostrano dati della giornata odierna, confrontati con giorni precedenti, per una rapida occhiata.',
              target: () => refFastStat.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Mostra Percentuale',
              description: 'Questo switcher ti permetterà di vedere il confronto dei tuoi dati in valore numerico o percentuale',
              target: () => refShowPercentage.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Confronto Statistiche',
              description: 'Clicca qui per impostare i giorni con cui vuoi confrontare i dati della giornata odierna',
              target: () => refChangeDay.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
          ]
        
          setSteps([...tempSteps, ...commonSteps]);
        break;
        
      case "graphics":
          tempSteps = [
            {
              title: 'Scegli i tuoi colori!',
              description: 'Scegli per ogni grafico la tua palette colori preferita dalla nostra selezione.',
              target: () => refColors.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Download in CSV',
              description: 'Una volta scelto il range di tempo, puoi scaricare i dati del grafico in CSV',
              target: () => refCsv.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Imposta il range di date',
              description: 'Scegli per ogni grafico, il range di tempo da interrogare, se non impostato mostrerà la giornata odierna. Massimo range 1 anno, ricorda: periodi lunghi, attese lunghe!',
              target: () => refRange.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Mostra i singoli orari o totali',
              description: 'Con questo switch potrai mostrare anche il totale incassato giornaliero, altrimenti verranno mostrati i singoli orari con incasso per orario',
              target: () => refEarnTimeSwitcher.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Grafico dei prodotti venduti',
              description: 'Qui troverai i prodotti più venduti con diverse interazioni possibili.',
              target: () => refSelledProducts.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Seleziona le categorie di prodotti',
              description: 'Mostra nel grafico dei prodotti venduti solamente le categorie di tuo interesse.',
              target: () => refSelledProductsSelectCategories.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Salva le categorie che hai selezionato',
              description: 'Solamente quando avrai selezionato le categorie, avrai la possibilità di salvarne una configurazione',
              cover: (
              <Button
                type="primary"
                shape="circle"
                icon={<SaveOutlined />}
              /> 
              ),
              target: () => refSelledProductsSaveCategories.current,
            },
            {
              title: 'Reimposta il salvataggio',
              description: 'Quando comparirà il tasto di reimpostazione categorie, cliccandolo verranno attivate le ultime categorie salvate',
              target: () => refSelledProductsResetCategories.current,
            },
            {
              title: 'Scegli quanti prodotti vedere...',
              description: 'Imposta fino a 50 prodotti visionabili ed usa lo switch per mostrarli ordinati per quantità o per valore',
              target: () => refSelledProductsQuantitySwitcher.current,
            },
          ]
          setSteps([...tempSteps, ...commonSteps]);
        break;
      
      case "tabulations":
          tempSteps = [
            {
              title: 'Imposta il range di date',
              description: 'Scegli per ogni tabella, il range di tempo da interrogare, se non impostato mostrerà la giornata odierna. Massimo range 1 anno, ricorda: periodi lunghi, attese lunghe!',
              target: () => refRange.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Totale del valore delle rettifiche',
              description: 'Con questi dati in tempo reale verifica il totale in euro di rettifiche in incremento e decremento',
              target: () => refCorrectionsTotalValue.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Totale quantità prodotto delle rettifiche',
              description: 'Con questi dati in tempo reale verifica il totale delle quantità in aumento e decremento',
              target: () => refCorrectionsTotalQuantity.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Totale valore delle rettifiche durante il servizio',
              description: 'Con questi dati in tempo reale verifica il totale rettifiche in termini economici applicate durante il servizio',
              target: () => refTotalWhileService.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Totale valore delle rettifiche dopo il preconto',
              description: 'Con questi dati in tempo reale verifica il totale rettifiche in termini economici applicate dopo il preconto',
              target: () => refTotalAfterPreReceipt.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
            {
              title: 'Controlla lo storico correzioni!',
              description: 'In questa tabella potrai filtrare e vedere i dati relativi alle correzioni applicate dai camerieri',
              target: () => refCorrectionsList.current,
              prevButtonProps: {
                children: backLabel, 
              },
              nextButtonProps: {
                children: nextLabel,
              },
            },
          ]
          setSteps([...tempSteps, ...commonSteps]);
        break;
    
      default:
        break;
    }
  },[])

  
   return (
     <>
          <Popover title="Tour della pagina">
           <Button
              icon={<InfoOutlined />}
              shape='circle'
              onClick={() => setOpen(true)}
              badge={{
                dot: showDot,
              }}
           />
         </Popover>
         <Tour
            onClose={() => setOpen(false)}
            steps={steps}
            open={open}
         />
     </>
  )
}
