import { Button, Typography } from 'antd'
import React, { useContext } from 'react'

import createOperation from './operations/MarketingOperationCreate'
import createAudience from './audience/MarketingAudienceCreate'

export default function MarketingEmpty({isOperation, contextValue}) {
   

   const onClick = () => {
      if (isOperation === true) {
         createOperation(contextValue,)
      } else {
         createAudience(contextValue,)
      }
   }

  return (
      <div className='di-no-content-wrapper'>
         <Typography className='fs-16'>
            {isOperation ? "Sembra che tu non abbia ancora fatto operazioni..." : "Ops... Sembra che non ci sia ancora alcun pubblico"}
         </Typography>
         <Button
            onClick={onClick}
            className='px-30 mw-100 fw-5'
            size='large'
            type={"primary"}
         >
            + Crea {isOperation ? "Operazione" : "Pubblico"}
         </Button>
      </div>
  )
}
