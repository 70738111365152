import React from 'react'

import DiEditLayoutButton from './DiEditLayoutButton'
import DiDashboardTour from './tours/DiDashboardTour'
import DiDarkModeButton from './DiDarkModeButton'
import BackButton from './BackButton'

export default function DiActionsSider() {
  return (
    <div className='flex gap-10 justify-center w-100 di-sider-actions-wrapper bg-dark-02'>
      <BackButton />        
      <DiDarkModeButton />
      <DiEditLayoutButton />
      <DiDashboardTour />
   </div>
  )
}
