import React, { useContext, useEffect, useState } from 'react'

import dayjs from 'dayjs';

import { ShoppingCartOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { Button, Col, Row} from 'antd'

import { ENDPOINT_PERIOD_SALES } from '../../helpers/Globals';
import TableReceipts from '../tables/TableReceipts'
import { Context } from '../../helpers/Context'
import { ApiCall } from '../../Backoffice'
import DiTooltip from '../DiTooltip';
import NoData from '../NoData'


export default function DisplayOpenedTables() {
   
   const navigate = useNavigate();

  // ---- variabili dal context -----
   const {
      commonQueryParams,
      refreshVariables,
      setOpenedTable,
      openedTable,
      accessToken,
   } = useContext(Context);
   // ---- variabili dal context -----

   // --------------------- chiamata per ricevere i dati dei tavoli aperti --------------------- 
      useEffect(()=>{
        var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
        var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

        (async () => {

          const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${startDate}&end_date=${endDate}&opened=1&${commonQueryParams}`, "GET", `${accessToken}`, null)
          if(json){

            var openedTable = json.sales;
            var counter = 0;

            for (const i in json.sales) {
                openedTable[i]["key"] = openedTable[i]["bill_id"];
                openedTable[i]["children"] = openedTable[i]["lines"];
                openedTable[i].total = 0;

                for (let n in openedTable[i]["children"]) {
                  counter += 1;
                  openedTable[i].total = openedTable[i].total + openedTable[i]["children"][n]["amount"];
                  openedTable[i]["children"][n]["key"] = counter;
                }
            }
            setOpenedTable(openedTable);
          }
        })();

      }, [...refreshVariables]);
   
   return (
      <div className="di-card flex column h-100 y-scroll">
         <Row>
            <Col span={24}>
            <div className='flex column align-center justify-center gap-10'>
               <div className='flex gap-10 color-grey fs-15 mb-20'>
                  <div className='flex align-center gap-5'><ShoppingCartOutlined className="color-primary fs-20"/> Conti aperti</div>
                  <DiTooltip text="Questa sezione mostra i tavoli aperti"/>
                  </div>
                  {openedTable.length !== 0  &&
                     <Button onClick={()=>navigate("/opened-tables")} type="primary" className="mb-20" block>Vedi tavoli Aperti</Button>
                  }
            </div>
            </Col>
         </Row>
         { 
            openedTable.length !== 0 
            ?
               <TableReceipts data={openedTable} hidePayment={true} />
            :
            <NoData/>
         }
      </div>
  )
}
