import React, { useContext, useEffect, useState } from 'react'

import { ClockCircleOutlined, FlagOutlined, NotificationOutlined, OrderedListOutlined, SaveOutlined, SendOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import {Button, Col, Form, Row, Steps, Typography } from 'antd';
import { orange, purple, yellow,cyan,  } from "@ant-design/colors";

import MarketingOperationCreateSelectAudience from './MarketingOperationCreateSelectAudience';
import MarketingOperationCreateSelectPriority from './MarketingOperationCreateSelectPriority';
import MarketingOperationCreateSelectChannel from './MarketingOperationCreateSelectChannel';
import MarketingOperationCreateSchedule from './MarketingOperationCreateSchedule';
import { Context, CreateOperationDataContext } from '../../../helpers/Context';
import {ifViewportGreaterThan } from '../../../helpers/Viewport';
import { dummyAudiences } from '../../../helpers/DummyData';
import { PRIMARY_COLOR } from '../../../helpers/Globals';

export default function MarketingOperationCreateContent({contextValue, userId}) {
   


   // -------- colori preset delle aree --------

   const colorWhatsApp           = PRIMARY_COLOR;
   const colorPushNotification   = yellow[6];
   const colorCoupons            = orange[6];
   const colorSms                = purple[5];
   const colorEmail              = cyan[7];
   
   // -------- colori preset delle aree --------  
   
   // --------- variabili per l'audience ---------
   const [selectedAudience, setSelectedAudience] = useState([]);
   const [selectedValues, setSelectedValues] = useState([]);
   const [totalUsers, setTotalUsers] = useState(0);
   // --------- variabili per l'audience ---------

   // --------- variabili per lo scheduling ---------
   const [monthlyOption, setMonthlyOption] = useState('specificDay');
   const [repeatFrequency, setRepeatFrequency] = useState('now');
   const [selectedDays, setSelectedDays] = useState([]);
   // --------- variabili per lo scheduling ---------

   // --------- variabili del form --------------
   const [focusedInputName, setFocusedInputName] = useState(null);
   const [focusedInputRef, setFocusedInputRef] = useState(null);
   const [formChanged, setFormChanged] = useState(0);
   const [formValues, setFormValues] = useState([])
   const [form] = Form.useForm();

   const [pushNotificationData, setpushNotificationData] = useState(null)
   const [whatsAppData, setwhatsAppData] = useState(null)
   const [couponData, setcouponData] = useState(null)
   const [emailData, setemailData] = useState(null)
   const [smsData, setsmsData] = useState(null)

      // Definisci un oggetto con i nomi delle variabili da settare
      const formFields = {
         push_notification_data:setpushNotificationData,
         whatsapp_data: setwhatsAppData,
         coupon_data: setcouponData,
         sms_data: setsmsData,
         email_data:setemailData,
      };
      
   // --------- variabili del form --------------


   // ------------ funzioni tecniche degli input -----------——
   
  const insertShortcode = (shortcode) => {
    
   const input = focusedInputRef.current?.resizableTextArea?.textArea || focusedInputRef.current?.input;

   // Verifica che l'input esista
   if (!input) {
      console.error("Nessun input o textarea focalizzato trovato.");
      return;
   }
   
   const { selectionStart, selectionEnd } = input;
     
   var value = form.getFieldValue(focusedInputName);
   var textBeforeCursor = "";
   var textAfterCursor = "";

   if (value) {
      textBeforeCursor = value.substring(0, selectionStart).trim();
      textAfterCursor = value.substring(selectionStart, value.length).trim();
   }
     
   const newValue = textBeforeCursor + " " + shortcode + " " + textAfterCursor;
   form.setFieldValue(focusedInputName, newValue.trim())
   handleFormChange();
  }
   
   const onInputFocus = (inputRef, inputName) => {
      setFocusedInputName(inputName);
      setFocusedInputRef(inputRef);
   }
   
   // ------------ funzioni tecniche degli input -----------——

   
   // --------- funzioni del form --------------

   useEffect(() => {
      
      const formValues = form.getFieldsValue();
      
      // Itera su ogni campo e aggiorna il relativo stato
      Object.keys(formFields).forEach((key) => {
         if (formValues[key] !== undefined) {
         formFields[key](formValues[key]); // Chiamata dinamica a setformXXX
         }
      });
      // Infine, salva tutti i valori nel contesto principale
      setFormValues(formValues);
      console.log(formValues);
   }, [formChanged])
   
   // --------- funzioni del form --------------

   // --------- steps del wizard --------------
   const [current, setCurrent] = useState(0);

   const steps = [
      {
         title: <span className='flex gap-5'><UsergroupAddOutlined/>Seleziona il Pubblico</span>,
      },
      {
         title: <span className='flex gap-5'><NotificationOutlined/>Seleziona il Canale</span>,
      },
      {
         title: <span className='flex gap-5'><OrderedListOutlined/>Seleziona le Priorità</span>,
      },
      {
         title: <span className='flex gap-5'><ClockCircleOutlined/>Seleziona la Ricorrenza</span>,
      },
   ]
   const filteredSteps = userId ? steps.slice(1) : steps;
   const totalSteps = filteredSteps.length; // Calcola il numero totale di steps visibili
   // --------- steps del wizard --------------

   const onClickAudience = (e) => {
      
      const audienceId = e.target.value;
      const audienceData = dummyAudiences.find((item) => item.id === audienceId);

       setSelectedAudience((prevItems) =>
         prevItems.some((i) => i.id === audienceData.id)
            ? prevItems.filter((i) => i.id !== audienceData.id)  // Rimuovi l'oggetto con lo stesso id
            : [...prevItems, audienceData]                       // Aggiungi l'oggetto se non presente
      );
      
      console.log(selectedAudience);
   }
   
   // ------------ navigatore -----------——
   const next = () => {
      setCurrent(current + 1);
   };
   const prev = () => {
      setCurrent(current - 1);
   };
   const onChange = (value) => {
      console.log('onChange:', value);
      setCurrent(value);
   };
   // ------------ navigatore -----------——  
   
   // ------------ calcolo dell'audience totale -----------——  
   useEffect(() => {
      
      const totalAmount = selectedAudience.reduce((sum, item) => sum + Number(item.amount), 0);
      setTotalUsers(totalAmount);

   },[selectedAudience])
   // ------------ calcolo dell'audience totale -----------——  

   const handleFormChange = () => {
      setFormChanged(formChanged + 1);
   }
   const handleSave = () => {
      console.log("Salva")
   }
   const handleFinish = (values) => {
      console.log(values)
      setFormValues(values);
   }

   return (
      <CreateOperationDataContext.Provider
         value={{
            handleFormChange,
            insertShortcode,
            setFormChanged,
            onInputFocus,
            formChanged,
            form,
            
            pushNotificationData,
            whatsAppData,
            formValues,
            couponData,
            emailData,
            smsData,

            colorPushNotification,
            colorWhatsApp,
            colorCoupons,
            colorEmail, 
            colorSms,
         }} 
      >
         <Form
            form={form}
            name="operation_form"
            layout='vertical'
            onFinish={handleFinish}
            onValuesChange={()=>setFormChanged(formChanged + 1)}
         >
            <div className='di-card mt-10 mx-10'>
               
               {/* ------------ monitor e telecomando del wizard ------------ */}
               <div className="mb-20 border-solid p-10 radius-2">
                  <Row justify="space-between" align="middle" gutter={[0,20]}>
                     <Col xs={current < (totalSteps - 1) ? 12 : 24} sm={10}>
                        {current > 0 && (
                           <Button className='mb-10' type="primary" onClick={prev}>
                              Indietro
                           </Button>
                        )}
                     </Col>
                     <Col xs={current < (totalSteps - 1) ? 12 : 24} sm={14} md={12} lg={8} xxl={6} align="end">
                        {current < totalSteps - 1 ? (
                           // Pulsante "Avanti" visibile in tutti gli step tranne l'ultimo
                           ((userId 
                              ? selectedValues.length > 0 // Con userId, verificare solo selectedValues
                              : (current === 0 && selectedAudience.length > 0) || // Senza userId, comportamenti attuali
                              (current === 1 && selectedValues.length > 0) ||
                              current > 1)) && (
                              <Button className='mb-10' type="primary" onClick={next}>
                                 Avanti
                              </Button>
                              )
                        ) : (
                           // Pulsanti "Salva" visibile solo nell'ultimo step
                           <Row gutter={[10, 20]} className='mb-10'>
                              <Col xs={24} sm={12}>
                              <Button className='w-100' icon={<SaveOutlined />} onClick={handleSave}>
                                 Salva Bozza
                              </Button>
                              </Col>
                              <Col xs={24} sm={12}>
                              <Button className='w-100' icon={<FlagOutlined />} type="primary" onClick={handleSave}>
                                 Pubblica
                              </Button>
                              </Col>
                           </Row>
                        )}
                     </Col>
                  </Row>
                  <Row justify={"center"}>
                     <Typography>
                        <span className='flex gap-5'>
                           <SendOutlined/>
                           Hai selezionato {totalUsers} utenti per: {selectedValues.map((e) => e.label).join(', ')}
                        </span>
                     </Typography>
                  </Row>
               </div>
               {/* ------------ monitor e telecomando del wizard ------------ */}
               
               {/* ------------ passi del wizard ------------  */}
               <Steps
                  current={current}
                  direction={ifViewportGreaterThan("md") ? 'horizontal' : 'vertical'}
                  items={filteredSteps}
               />
               {/* ------------ passi del wizard ------------  */}
               
               {/* ------------ contenuto ------------ */}
               <div className="mt-20">
                  {
                     // Logica condizionale per visualizzare i contenuti in base al `current` e all'`userId`
                     (userId ? current + 1 : current) === 0 ? (
                        <MarketingOperationCreateSelectAudience
                           setSelectedAudience={setSelectedAudience}
                           selectedAudience={selectedAudience}
                           contextValue={contextValue}
                           onChange={onClickAudience}
                        />
                     ) : (userId ? current + 1 : current) === 1 ? (
                        <MarketingOperationCreateSelectChannel
                           setSelectedValues={setSelectedValues}
                           selectedValues={selectedValues}
                           contextValue={contextValue}
                        />
                     ) : (userId ? current + 1 : current) === 2 ? (
                        <MarketingOperationCreateSelectPriority
                           selectedChannels={selectedValues}
                        />
                     ) : (userId ? current + 1 : current) === 3 ? (
                        <MarketingOperationCreateSchedule
                           setRepeatFrequency={setRepeatFrequency}
                           setMonthlyOption={setMonthlyOption}
                           repeatFrequency={repeatFrequency}
                           setSelectedDays={setSelectedDays}
                           monthlyOption={monthlyOption}
                           selectedDays={selectedDays}
                        />
                     ) : (
                        ''
                     )
                  }
               </div>
               {/* ------------ contenuto ------------ */}
      
            </div>
         </Form>
      </CreateOperationDataContext.Provider>
   )
}
