import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs';

import { HighlightOutlined } from '@ant-design/icons';
import { Col, InputNumber, Row,Spin } from 'antd';

import { ENDPOINT_BRANDS, LOCAL_BRANDS_COLOR } from '../../helpers/Globals';
import DownloadCsvButton from '../DownloadCsvButton';
import DiPaletteSelector from '../DiPaletteSelector';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import { ApiCall } from '../../Backoffice';
import PieChart from '../charts/PieChart';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';

export default function DisplayBrands() {
   

   const [brandsColor, setBrandsColor] = useState(localStorage.getItem(LOCAL_BRANDS_COLOR) ?? "nivo");
   const [brandsStartDate, setBrandsStartDate] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
   const [brandsEndDate, setBrandsEndDate] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
   const [resetBrandsDate, setResetBrandsDate] = useState(0);
   const [loadingBrands, setLoadingBrands] = useState(false);
   const [brandsQuantity, setBrandsQuantity] = useState([]);
   const [brandsViewed, setBrandsViewed] = useState(10);
   const [brandsPrice, setBrandsPrice] = useState([]);

   
  // ---- variabili dal context -----
   const {
      commonQueryParams, 
      refreshVariables,
      accessToken,
      currency,
   } = useContext(Context);
  // ---- variabili dal context -----


  // --------------------- chiamata per ricevere i dati dei brands --------------------- 

      // settare la data giornaliera dei metodi di pagamento con il reset
      useEffect(()=>{

        var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
        var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

        setBrandsStartDate(startDate)
        setBrandsEndDate(endDate)

      },[resetBrandsDate])
      

      // chiamata per ricevere i dati dei metodi di pagamento
      useEffect(()=>{
        setLoadingBrands(true);
        (async () => {

          const json = await ApiCall(`${ENDPOINT_BRANDS}?date=${brandsStartDate}&end_date=${brandsEndDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
          if (json) {
            json.sort((a, b) => b.amount - a.amount);

            json.forEach(element => {
              element["value"] = element["amount"].toFixed(2);
              element["label"] = element["brand"];
              element["id"] = element["brand"];
              delete element["brand"];
              delete element["amount"];
            });
            
            setBrandsPrice(json);
          }
          setLoadingBrands(false);
        })();

      },[brandsStartDate, ...refreshVariables]);

      // gestione del range date per i metodi di pagamento
      const brandsRangeDate = (dates, dateStrings) => {

        if (dates) {
          var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
          var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
          setBrandsStartDate(newStartDate);
          setBrandsEndDate(newEndDate);
        } else {
          setResetBrandsDate(resetBrandsDate + 1);
        }
      };
  
  // --------------------- chiamata per ricevere i dati dei brands --------------------- 

   
   
  return (
    <div className="di-card h-100">
      <Row>
         <Col span={24}>
         <div className='flex column align-center space-between gap-10'>
            <div className='flex gap-10 color-grey fs-15'>
               <div className='flex align-center gap-5'><HighlightOutlined className='color-primary fs-20' />Marchi</div>
               <DiTooltip text="Le top 10 categorie di prodotti più venduti per l'intervallo di tempo stabilito"/>
            </div>
            <div className='flex align-center gap-10 space-between w-100 flex-wrap'>
               <DiRangePicker onChange={brandsRangeDate}/>
               <div className='flex gap-10'>
               <DiPaletteSelector
                  defaultValue={brandsColor}
                  onChange={
                     (value)=>{
                     setBrandsColor(value);
                     localStorage.setItem(LOCAL_BRANDS_COLOR,value)
                     }
                  }
               />
               {brandsPrice.length > 0 && <DownloadCsvButton dataToDownload={brandsPrice} filename={"marchi"}/>}
               </div>
            </div>
            
         </div>
         </Col>
      </Row>
   
      <Row justify={{xs:"start", xxl:"end"}} className='mt-10'>
         <div className='flex gap-5 flex-wrap align-center'>
         <div>Limite prodotti:</div>
         <InputNumber
            min={1} 
            max={50}
            defaultValue={10}
            onChange={(n)=>setBrandsViewed(n)}
         />
         </div>
      </Row>
   
      <Row className='h-100 flex align-center'>
         <Col span={24}>
         {
            brandsPrice.length === 0 ? <NoData/>
            :
            loadingBrands ? <div className="w-100 flex align-center justify-center pie-chart"><Spin /></div>
            :                 
            <div className="pie-chart">
               <PieChart data={brandsPrice.slice(0,brandsViewed)} colors={brandsColor} suffix={currency}/>
            </div>
         }
         </Col>
      </Row>
   
   </div>
  )
}
