import React from 'react';
import ReactDOM from 'react-dom/client'; // Importa createRoot da 'react-dom/client'

import { ConfigProvider, Modal, theme } from 'antd';
import { DARK_BG_CONTAINER } from '../helpers/Globals';


const confirmModal = (message, context) => {
   
   return new Promise((resolve) => {

      // ------------- Crea un div temporaneo nel DOM per far comparire il drawer -------------
      const container = document.createElement('div');
      document.body.appendChild(container); 
      // ------------- Crea un div temporaneo nel DOM per far comparire il drawer -------------

      // ------------- Crea un div temporaneo nel DOM per far comparire il drawer -------------
      const handleConfirm = () => {
         root.unmount(); // Smonta il modal
         document.body.removeChild(container); // Rimuovi il contenitore dal DOM
         resolve(true); // Risolvi la promise con true
      };

      const handleCancel = () => {
         root.unmount(); // Smonta il modal
         document.body.removeChild(container); // Rimuovi il contenitore dal DOM
         resolve(false); // Risolvi la promise con false
      };

      // ------------- Componente per la modal di conferma con messaggio dinamico -------------
      const ConfirmModal = () => {
         const { darkMode } = context; // Usa useContext qui

         return (
            <ConfigProvider
               theme={{
                  algorithm: darkMode ? theme.darkAlgorithm : theme.compactAlgorithm,
                  token: {
                     colorPrimary: "#008833",
                     colorBgContainer: darkMode ? DARK_BG_CONTAINER : "#fff"
                  }
               }}
            >
               <Modal
                  title="Sicuro di voler procedere?"
                  open={true}
                  onOk={handleConfirm}
                  onCancel={handleCancel}
                  okText="Si"
                  cancelText="No"
               >
                  <p>{message}</p>
               </Modal>
            </ConfigProvider>
         );
      };
      // ------------- Componente per la modal di conferma con messaggio dinamico -------------

      // ------------- Renderizzo la modal -------------
      const root = ReactDOM.createRoot(container);
      root.render(<ConfirmModal />);
      // ------------- Renderizzo la modal -------------

   });
};

export default confirmModal;
