import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs';

import { PrinterOutlined } from '@ant-design/icons';
import { Col, Row} from 'antd'

import { ENDPOINT_PERIOD_SALES } from '../../helpers/Globals';
import TableReceipts from '../tables/TableReceipts';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import TableSkeleton from '../TableSkeleton';
import { ApiCall } from '../../Backoffice';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';


export default function DisplayReceiptData() {
   
   const [sdReceipt, setSdReceipt] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
   const [edReceipt, setEdReceipt] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
   const [loadingReceipt, setLoadingReceipt] = useState(false);
   const [resetReceiptDate, setResetReceiptDate] = useState(0);
  
   
  // ---- variabili dal context -----
  const {
      commonQueryParams,
      refreshVariables,
      setReceiptData,
      accessToken,
      receiptData,
   } = useContext(Context);
  // ---- variabili dal context -----

   
// --------------------- chiamata per ricevere i dati degli scontrini chiusi (receipt) --------------------- 

      // settare la data giornaliera degli scontrini chiusi
      useEffect(()=>{

        var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
        var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

        setSdReceipt(startDate)
        setEdReceipt(endDate)

      },[resetReceiptDate])
      

      // chiamata per ricevere i dati degli scontrini chiusi
      useEffect(()=>{
        setLoadingReceipt(true);

        (async () => {

          const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${sdReceipt}&end_date=${edReceipt}&${commonQueryParams}`, "GET", `${accessToken}`, null)
         
          if(json){
            var receiptData = json.sales;
            var counter = 0;
            for (const i in json.sales) {
                receiptData[i]["key"] = "sale" + i;
                receiptData[i]["children"] = receiptData[i]["lines"];
                receiptData[i].total = 0;

                for (let n in receiptData[i]["children"]) {
                  counter += 1;
                  receiptData[i].total += receiptData[i]["children"][n]["amount"];
                  receiptData[i]["children"][n]["key"] = counter;
                }
            }
            setReceiptData(receiptData);
            setLoadingReceipt(false);
          }
        })();

      },[sdReceipt, ...refreshVariables]);

      // gestione del range date per gli scontrini chiusi 
      const receiptRangeDate = (dates, dateStrings) => {

        if (dates) {

          var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
          var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
          setSdReceipt(newStartDate);
          setEdReceipt(newEndDate);
        } else {
          setResetReceiptDate(resetReceiptDate + 1);
        }
      };

  return (
    <div className="di-card flex column h-100 y-scroll">
        <Row>
        {/* range di date per prodotti venduti e switcher del grafico*/}
            <Col span={24}>
               <div className='flex column align-center justify-center gap-10'>
                  <div className='flex gap-10 color-grey fs-15'>
                  <div className='flex align-center gap-5'><PrinterOutlined className='color-primary fs-20'/> Scontrini emessi e riepilogo conti</div>
                  <DiTooltip text="Riepilogo dettaglio scontrini emessi"/>
                  </div>
                  <div className='mb-20'>
                    <DiRangePicker onChange={receiptRangeDate} />
                  </div>
               </div>
            </Col>
         </Row>
         {/* range di date per prodotti venduti e switcher del grafico*/}
         { 
            !loadingReceipt && receiptData.length !== 0 ? <TableReceipts data={receiptData}/> 
         :
            !loadingReceipt && receiptData.length  ===  0 ? <NoData/>
         :
            loadingReceipt ? <TableSkeleton/> 
         : 
            ""
         }
   </div>
  )
}
