import { message } from 'antd';

export const validateFile = (file) => {
    const isValidType = ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
    if (!isValidType) {
      message.error('Puoi caricare solo file in formato JPG, PNG o GIF!');
      return false;
    }

    const isValidSize = file.size / 1024 / 1024 < 1; // Controllo per dimensione < 1MB
    if (!isValidSize) {
      message.error('L’immagine deve essere inferiore a 1MB!');
      return false;
    }

    return true;
  };
