import React from 'react'

import { Col, Divider, Form, Input, Row, Typography } from 'antd'

import ChannelSmsPreview from './ChannelSmsPreview'
import ChannelSmsForm from './ChannelSmsForm'

export default function ChannelSmsContent({form}) {
   
  return (
     <div className='operations-group-sms'>
        <Row gutter={[20,20]} className='mt-20'>
           <Col xs={24} md={14}>
              <ChannelSmsForm/>
           </Col>
           <Col xs={24} md={10}>
               <Typography className='fs-18 bold mb-10 text-center'>
                  Preview
               </Typography>
               <ChannelSmsPreview/>
           </Col>
        </Row>
     </div>
  )
}
