const dummyAudiences = [
    {
      title: "Compleanni oggi",
      description: "Tutte le persone che oggi compiono gli anni",
      amount: "12",
      id:"1",
      compareDate: [
        {
          date: "10/11/2024",
          amount:"125",
          positive:false,
        },
        {
          date: "10/11/2024",
          amount:"187",
          positive:true,
        },
        {
          date: "10/11/2024",
          amount:"68",
          positive:false,
        },
      ]
    },
    {
      title: "Persone con saldo oltre 100 su fidelity",
      description: "Persone che anno più di 100 unità di attività sulla loro fidelity, indipendentemente dalla campagna",
      id:"2",
      amount:"10",
      compareDate: [
        {
          date: "10/11/2024",
          amount:"125",
          positive:false,
        },
        {
          date: "10/11/2024",
          amount:"187",
          positive:true,
        },
        {
          date: "10/11/2024",
          amount:"68",
          positive:false,
        },
      ]
    },
    {
      title: "Persone con più di 10 ordini",
      description: "Tutti gli utenti che hanno effettuato più di 10 ordini senza contare il totale incassato",
      amount: "5",
      id:"3",
      compareDate: [
        {
          date: "10/11/2024",
          amount:"125",
          positive:false,
        },
        {
          date: "10/11/2024",
          amount:"187",
          positive:true,
        },
        {
          date: "10/11/2024",
          amount:"68",
          positive:false,
        },
      ]
    },
  ]





   const dummyPeople = [
      {
         "key": "1",
         "id": "1",
         "name": "John Doe",
         "email": "john.doe@example.com",
         "dob": "1985-06-15",
         "phone": "+1234567890",
         "address": "123 Elm Street, Springfield, USA"
      },
      {
         "key": "2",
         "id": "2",
         "name": "Jane Smith",
         "email": "jane.smith@example.com",
         "dob": "1992-09-10",
         "phone": "+2345678901",
         "address": "456 Oak Avenue, Rivertown, USA"
      },
      {
         "key": "3",
         "id": "3",
         "name": "Michael Johnson",
         "email": "michael.johnson@example.com",
         "dob": "1980-01-23",
         "phone": "+3456789012",
         "address": "789 Pine Drive, Lakeview, USA"
      },
      {
         "key": "4",
         "id": "4",
         "name": "Emily Davis",
         "email": "emily.davis@example.com",
         "dob": "1994-03-07",
         "phone": "+4567890123",
         "address": "101 Maple Court, Oakwood, USA"
      },
      {
         "key": "5",
         "id": "5",
         "name": "David Wilson",
         "email": "david.wilson@example.com",
         "dob": "1988-05-14",
         "phone": "+5678901234",
         "address": "202 Birch Lane, Riverdale, USA"
      },
      {
         "key": "6",
         "id": "6",
         "name": "Olivia Brown",
         "email": "olivia.brown@example.com",
         "dob": "1995-07-19",
         "phone": "+6789012345",
         "address": "303 Cedar Street, Hilltown, USA"
      },
      {
         "key": "7",
         "id": "7",
         "name": "James Miller",
         "email": "james.miller@example.com",
         "dob": "1979-11-02",
         "phone": "+7890123456",
         "address": "404 Walnut Road, Mapleton, USA"
      },
      {
         "key": "8",
         "id": "8",
         "name": "Sophia Martinez",
         "email": "sophia.martinez@example.com",
         "dob": "1991-04-25",
         "phone": "+8901234567",
         "address": "505 Redwood Avenue, Briarwood, USA"
      },
      {
         "key": "9",
         "id": "9",
         "name": "Daniel Taylor",
         "email": "daniel.taylor@example.com",
         "dob": "1986-08-13",
         "phone": "+9012345678",
         "address": "606 Hickory Blvd, Forest Hills, USA"
      },
      {
         "key": "10",
         "id": "10",
         "name": "Ava Anderson",
         "email": "ava.anderson@example.com",
         "dob": "1993-02-17",
         "phone": "+1023456789",
         "address": "707 Sycamore Way, Greenwood, USA"
      },
      {
         "key": "11",
         "id": "11",
         "name": "Liam Thomas",
         "email": "liam.thomas@example.com",
         "dob": "1982-12-11",
         "phone": "+2134567890",
         "address": "808 Aspen Circle, Pleasantville, USA"
      },
      {
         "key": "12",
         "id": "12",
         "name": "Isabella Jackson",
         "email": "isabella.jackson@example.com",
         "dob": "1997-10-30",
         "phone": "+3245678901",
         "address": "909 Cherry Drive, Brookside, USA"
      },
      {
         "key": "13",
         "id": "13",
         "name": "William White",
         "email": "william.white@example.com",
         "dob": "1983-06-06",
         "phone": "+4356789012",
         "address": "1010 Poplar Lane, Crestview, USA"
      },
      {
         "key": "14",
         "id": "14",
         "name": "Mia Harris",
         "email": "mia.harris@example.com",
         "dob": "1990-07-21",
         "phone": "+5467890123",
         "address": "1111 Willow Street, Riverview, USA"
      },
   ]



const dummyAudienceFilter = {
  "title": "Audience Example",
  "description": "Audience con dettagli anagrafici, acquisti e fidelity card",
  "is_active": true,
  "details": {
    "compare_dates": [
      {
        "date": "15/01/2024",
        "amount": 150,
        "is_positive": true
      },
      {
        "date": "20/02/2024",
        "amount": 30,
        "is_positive": false
      },
      {
        "date": "10/03/2024",
        "amount": 250,
        "is_positive": true
      }
    ],
    "filter_profiles": [
      {
        "data_type": "name",
        "condition": "contains",
        "values": "Mario"
      },
      {
        "data_type": "surname",
        "condition": "equals",
        "values": "Rossi"
      },
      {
        "data_type": "birth_date",
        "condition": "equals",
        "values": "01/01/1990"
      },
      {
        "data_type": "birth_date",
        "condition": "greater_than",
        "values": "01/01/2000"
      },
      {
        "data_type": "gender",
        "condition": "equals",
        "values": "male"
      },
      {
        "data_type": "age",
        "condition": "greater_than",
        "values": 30
      },
      {
        "data_type": "address",
        "values": [
          {
            "street": {
              "condition": "contains",
              "value": "Via Roma"
            },
            "state": {
              "condition": "equals",
              "value": "Lazio"
            },
            "city": {
              "condition": "equals",
              "value": "Rome"
            },
            "zip_code": {
              "condition": "equals",
              "value": "00100"
            }
          },
          {
            "street": {
              "condition": "contains",
              "value": "Corso Milano"
            },
            "state": {
              "condition": "equals",
              "value": "Lombardia"
            },
            "city": {
              "condition": "equals",
              "value": "Milan"
            },
            "zip_code": {
              "condition": "equals",
              "value": "20100"
            }
          }
        ]
      }
    ],
    "filter_products": [
      {
        "category": "electronics",
        "items": "",
        "quantity": {
          "condition": "greater_or_equal",
          "value": 5
        }
      },
      {
        "category": "appliances",
        "items": ["12", "223"],
        "quantity": {
          "condition": "less_than",
          "value": 3
        }
      }
    ],
    "filter_order": {
      "number_of_orders": {
        "condition": "greater_or_equal",
        "value": 10
      },
      "total_order_value": {
        "condition": "greater_than",
        "value": 1000
      }
    },
    "filter_fidelity": [
      {
        "data_type": "number_of_transactions",
        "condition": "greater_or_equal",
        "value": 50
      },
      {
        "data_type": "total_value",
        "condition": "greater_than",
        "value": 500,
        "campaign": {
          "campaign_id": 12,
          "campaign_label": "Punti"
        },
        "activity": {
          "activity_id": 12,
          "activity_label": "Punti"
        }
      },
      {
        "data_type": "total_coupons_burned",
        "condition": "less_than",
        "value": 10,
        "coupon_id": "543"
      },
      {
        "data_type": "total_coupons_obtained",
        "condition": "greater_than",
        "value": 15,
        "coupon_id": "COUP67890"
      },
      {
        "data_type": "activation_date",
        "condition": "before",
        "value": "01/01/2023"
      },
      {
        "data_type": "last_transaction_date",
        "condition": "after",
        "value": "01/12/2020"
      },
      {
        "data_type": "last_transaction_date",
        "condition": "before",
        "value": "01/12/2023"
      }
    ],
    "filter_software": [
      {
        "data_type": "have_app",
        "value": true
      },
      {
        "data_type": "have_webapp",
        "value": true
      },
      {
        "data_type": "app_activation_date",
        "condition": "after",
        "value": "01/12/2023"
      }
    ],
    "extra_users_id": [1001, 1002, 1003]
  }
};


export {
  dummyAudiences,
  dummyPeople
}