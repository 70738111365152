import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs';

import { CreditCardOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';

import { ENDPOINT_PAYMENTS_DATA, LOCAL_PAYMENT_METHOD_COLOR } from '../../helpers/Globals';
import DownloadCsvButton from '../DownloadCsvButton';
import DiPaletteSelector from '../DiPaletteSelector';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import { ApiCall } from '../../Backoffice';
import PieChart from '../charts/PieChart';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';

export default function DisplayPaymentMethod() {
  

  const [paymentMethodColor, setPaymentMethodColor] = useState(localStorage.getItem(LOCAL_PAYMENT_METHOD_COLOR) ?? "nivo");
  const [payStartDate, setPayStartDate] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
  const [payEndDate, setPayEndDate] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [resetPayDate, setResetPayDate] = useState(0);
  const [payData, setPayData] = useState([]);
  const [total, setTotal] = useState(0);
  

  // ---- variabili dal context -----
   const {
    accessToken,
    commonQueryParams,
    refreshVariables,
    currency,
   } = useContext(Context);
  // ---- variabili dal context -----

// --------------------- chiamata per ricevere i dati dei metodi di pagamento --------------------- 

      // settare la data giornaliera dei metodi di pagamento con il reset
      useEffect(()=>{

        var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
        var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

        setPayStartDate(startDate)
        setPayEndDate(endDate)

      },[resetPayDate])
      

      // chiamata per ricevere i dati dei metodi di pagamento
      useEffect(()=>{
        setLoadingPayment(true);

        (async () => {

          const json = await ApiCall(`${ENDPOINT_PAYMENTS_DATA}?date=${payStartDate}&end_date=${payEndDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
          if(json){
            var paymentData = [];

            json.forEach(element => {
              element["value"] = element["amount"].toFixed(2);
              element["label"] = element["description"];
              element["id"] = element["description"];
              
              delete element["amount"];
              delete element["description"];
              delete element["code"];

              paymentData.push(element)
            });
            setPayData(paymentData);
            setLoadingPayment(false);

          }
        })();

      }, [payStartDate, ...refreshVariables]);
  
  // ----------------—— gestisco il totale dei metodi di pagamento ----------------——
  useEffect(() => {
    if (payData.length <= 0) {
      setTotal(0);
      return
    };

    var totalTemp = 0;

    payData.forEach(element => {
      var earn = parseFloat(element.value)
      totalTemp += earn
    });
    setTotal(totalTemp)
  }, [payStartDate, payData, ...refreshVariables])
  
  // ----------------—— gestisco il totale dei metodi di pagamento ----------------——

      // gestione del range date per i metodi di pagamento
      const payRangeDate = (dates, dateStrings) => {

        if (dates) {
          var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
          var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
          setPayStartDate(newStartDate);
          setPayEndDate(newEndDate);
        } else {
          setResetPayDate(resetPayDate + 1);
        }
      };

  // --------------------- chiamata per ricevere i dati dei metodi di pagamento --------------------- 

  return (
        <div className="di-card h-100">
          <Row>
            <Col span={24}>
              <div className='flex column align-center space-between gap-10'>
                <div className='flex gap-10 color-grey fs-15'>
                  <div className='flex align-center gap-5'><CreditCardOutlined className="color-primary fs-20"/> Metodi di Pagamento</div>
                  <DiTooltip text="Totale incassato per ogni metodo di pagamento"/>
                </div>
                <div>Totale: <b>{total.toFixed(2)}{currency}</b></div>
                <div className='flex align-center gap-10 space-between w-100 flex-wrap'>
                  <DiRangePicker onChange={payRangeDate}/>
                  <div className='flex gap-10'>
                    <DiPaletteSelector
                        defaultValue={paymentMethodColor}
                            onChange={
                              (value)=>{
                                setPaymentMethodColor(value);
                                localStorage.setItem(LOCAL_PAYMENT_METHOD_COLOR,value)
                              }
                            }
                      />
                    {payData.length > 0 && <DownloadCsvButton dataToDownload={payData} filename={"metodi-di-pagamento"}/>}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='h-100 flex align-center'>
            <Col span={24}>
              {
                payData.length === 0 ? <NoData/>
                :
                loadingPayment ? <div className="w-100 flex align-center justify-center pie-chart"><Spin /></div>
                :                 
                <div className="pie-chart">
                  <PieChart data={payData} colors={paymentMethodColor} suffix={currency}/>
                </div>
              }
            </Col>
          </Row>
        </div>
  )
}
