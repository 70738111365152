import React from 'react'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'

export default function BackButton() {
  return (
    <Popover title="Torna a DylogApp">
      <Button shape='circle' icon={<ArrowLeftOutlined />} onClick={()=>window.location.href = "https://www.dylogapp.it"}/>
    </Popover>
  )
}
