import { useContext } from 'react';

import { Table, Tag } from 'antd';

import formatEuro from '../../helpers/FormatEuro';
import { Context } from '../../helpers/Context';

const TableReceipts = (props) => {
  
  var parentData = JSON.parse(JSON.stringify(props.data))
  var waiterFilter = [];
  var tableFilter = [];
  var roomFilter = [];

  const { currency, baccoPaymentsList } = useContext(Context);

  
  for (const i of parentData) {
    if(roomFilter.find(element => element.text === i.room) === undefined){
      roomFilter.push({       
        "text": i.room,
        "value":i.room,
      });
    };
    if(waiterFilter.find(element => element.text === i.waiter_name) === undefined){
      waiterFilter.push({       
        "text": i.waiter_name,
        "value":i.waiter_name,
      });
    };
    if(tableFilter.find(element => element.text === i.table) === undefined){
      tableFilter.push({       
        "text":  i.table,
        "value": i.table,
      });
    };

    delete i.children;
  }

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date_bill',
      sorter: (a, b) => new Date(a.date_bill).getTime() - new Date(b.date_bill).getTime(),
      render: (value)=>value.replace("T", " "),
      responsive: ['xl'],
    },
    {
      title: 'Cameriere',
      dataIndex: 'waiter_name',
      defaultSortOrder: 'descend',
      filters: waiterFilter,
      render:(value) => (value === "" || !value) ? "Operatore Cassa" : value,
      onFilter: (value, record) => record.waiter_name.indexOf(value) === 0,
      sorter: (a, b) => a.waiter_name.length - b.waiter_name.length,
      responsive: ['xl'],
    },
    {
      title: 'Sala',
      dataIndex: 'room',
      defaultSortOrder: 'descend',
      filters: roomFilter,
      onFilter: (value, record) => record.room.indexOf(value) === 0,
      sorter: (a, b) => a.room - b.room,
      responsive: ["xs","sm","md"],
    },
    {
      title: 'Tavolo',
      dataIndex: 'table',
      filters: tableFilter,
      onFilter: (value, record) => record.table.indexOf(value) === 0,
      sorter: (a, b) => a.table - b.table,
      responsive: ["xs","sm","md"],
    },
    {
      title: 'Totale',
      dataIndex: 'total',
      onFilter: (value, record) => record.table.indexOf(value) === 0,
      sorter: (a, b) => parseFloat(a.total) - parseFloat(b.total),
      render: (value)=><Tag className='fs-12' bordered={false} color='green'>{formatEuro(value) + " " + currency}</Tag>,
      responsive: ["xs","sm","md"],
    },
    { 
      title: 'Pagamenti',
      dataIndex: 'payments',
         responsive: props.hidePayment ? [] : ["xs","sm","md"],
      render: (value) =>
        value.map((payment) => {
          const paymentMethod = baccoPaymentsList.find(paymentMethod => paymentMethod.code === payment.code);
          
          // Verifica che paymentMethod esista
          if (payment && paymentMethod) {
            return (
              <Tag key={payment.code} className='fs-12 capitalize'  bordered={false}>
                {paymentMethod.name} {formatEuro(payment.amount)} {currency}
              </Tag>
            );
          }
        })
      },
  ];
  
  return (
    <>
        <Table
            locale={{ 
                triggerDesc: 'Ordine decrescente',
                triggerAsc: 'Ordine crescente', 
                cancelSort: "Clicca per cancellare l'ordinamento"
          }}
          columns={columns}
          pagination={{
             pageSize:props.numberOfRows ?? 5
          }}
          className="mobile-x-scroll"
          expandable={{
            expandedRowRender: (record) => {
              var children = props.data[props.data.findIndex(item => item.key === record.key)].children;
              const columns = [
                {
                  title: 'Descrizione',
                  dataIndex: 'description',
                  key: 'description',
                },
                {
                  title: 'Quantità',
                  dataIndex: 'quantity',
                  key: 'quantity',
                  render: (value)=>value + " pz.",
                },
                {
                  title: 'Totale',
                  dataIndex: 'amount',
                  key: 'amount',
                  render: (value)=>formatEuro(value) + " " + currency,
                },
                {
                  title: 'Prezzo',
                  dataIndex: 'price',
                  render: (value)=>formatEuro(value) + " " + currency,
                  key: 'price',
                }
              ];
              return(
                <Table
                  className='di-table-nested'
                  columns={columns}
                  dataSource={children}
                  pagination={false}
                  bordered
                /> 
              )
          }
          }}
          dataSource={parentData}
          scroll={{ x: 'max-content' }} 
          bordered
        />
    </>
  );
};
export default TableReceipts;