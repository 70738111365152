import React from 'react'

import { DatePicker } from 'antd';
import { dateHourFormat, disable12MonthsDate, rangePresets } from '../helpers/DateUtils';

const { RangePicker } = DatePicker;


export default function DiRangePicker({ onChange, onClear,outlined, hidePreset, hideTime, value, enableAllDate }) {
  
    const handleDisabledDate = (current, { from }) => {
    if (enableAllDate) {
      return false; // Se enableAllDate è true, non disabilitiamo nessuna data
    }
    // Se enableAllDate è false, utilizziamo la funzione di disabilitazione
    return disable12MonthsDate(current, { from });
    };
  
  return (
    <>
      { !value ?
        <RangePicker
          variant={"outlined"}
          presets={hidePreset ? false : rangePresets}
          disabledDate={handleDisabledDate}
          placeholder={["Inizio","Fine"]}
          className='mobile-w-100'
          format={dateHourFormat}
          showTime={!hideTime}
          onChange={onChange}
          onClear={onClear}
          readonly
        />
      :
        <RangePicker
          readonly
          variant={"outlined"}
          presets={hidePreset ? false : rangePresets}
          disabledDate={handleDisabledDate}
          placeholder={["Inizio","Fine"]}
          className='mobile-w-100'
          format={dateHourFormat}
          showTime={!hideTime}
          onChange={onChange}
          onClear={onClear}
          value={value} // Passa il valore ricevuto tramite la prop
        />
      }
    </>
  )
}
