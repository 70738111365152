import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs';

import { GroupOutlined } from '@ant-design/icons';
import { Col, Row} from 'antd'

import TableSelledCategories from '../tables/TableSelledCategories';
import { ENDPOINT_PERIOD_SALES } from '../../helpers/Globals';
import duplicateArray from '../../helpers/DuplicateArray';
import formatEuro from '../../helpers/FormatEuro';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import TableSkeleton from '../TableSkeleton';
import { ApiCall } from '../../Backoffice';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';


export default function DisplaySelledCategories() {
   
   const [sdCategories, setSdCategories] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
   const [edCategories, setEdCategories] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
   const [resetCategoriesDate, setResetCategoriesDate] = useState(0);
   const [loadingReceipt, setLoadingReceipt] = useState(false);
   const [categoriesData, setCategoriesData] = useState([]);
   const [totalAmount, setTotalAmount] = useState(0);
  
   
  // ---- variabili dal context -----
   const {
      commonQueryParams,
      refreshVariables,
      accessToken,
      currency,
      refRange,
   } = useContext(Context);
  // ---- variabili dal context -----

   
// --------------------- chiamata per ricevere i dati degli scontrini chiusi (receipt) --------------------- 

      // settare la data giornaliera degli scontrini chiusi
      useEffect(()=>{

        var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
        var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

        setSdCategories(startDate)
        setEdCategories(endDate)

      },[resetCategoriesDate])
      

      // chiamata per ricevere i dati degli scontrini chiusi
      useEffect(()=>{
        setLoadingReceipt(true);

        (async () => {

         const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${sdCategories}&end_date=${edCategories}&group_by_category=1&${commonQueryParams}`, "GET", `${accessToken}`, null)

         var data = duplicateArray(json);
           var tempTotal = 0;
           
         if(json){
            for (const i in json) {
               data[i]["key"] = data[i]["category_code"];
               tempTotal += data[i]["amount"];

               for (let n in data[i]["products"]) {
                  data[i]["products"][n]["key"] = data[i]["products"][n]["product_code"];
               }
            }
            
            // Troviamo l'oggetto con category_code "SERVIZIO" e lo rimuoviamo
            let serviceObj = null;
            const filteredData = data.filter(item => {
               if (item.category_code === "SERVIZIO") {
                     serviceObj = item;  // Salviamo l'oggetto rimosso
                     return false;  // Rimuoviamo questo elemento
               }
               return true;  // Manteniamo gli altri elementi
            });
            
            // Ordiniamo i dati rimanenti per category_code numerico crescente
            const orderedData = filteredData.sort((a, b) => a.category_code - b.category_code);

            // Se abbiamo rimosso l'oggetto SERVIZIO, lo reinseriamo in coda
            if (serviceObj) {
               orderedData.push(serviceObj);
            }
            
            setCategoriesData(orderedData);
            setTotalAmount(tempTotal);
            setLoadingReceipt(false);
          }
        })();

      },[sdCategories, ...refreshVariables]);

      // gestione del range date per gli scontrini chiusi 
      const categoriesRangeDate = (dates, dateStrings) => {

        if (dates) {

          var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
          var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
          setSdCategories(newStartDate);
          setEdCategories(newEndDate);
        } else {
          setResetCategoriesDate(resetCategoriesDate + 1);
        }
      };

   
  return (
    <div className="di-card flex column h-100 y-scroll">
        <Row>
        {/* range di date per prodotti venduti e switcher del grafico*/}
            <Col span={24}>
               <div className='flex column align-center justify-center gap-10'>
                  <div className='flex gap-10 color-grey fs-15'>
                    <div className='flex align-center gap-5'><GroupOutlined className='color-primary fs-20' /> Prodotti venduti per categoria</div>
                  <DiTooltip text="Riepilogo del venduto (prezzo e quantità) di ogni prodotto, raggruppato per categorie"/>
                  </div>
                  <div>
                     Totale Complessivo:<span className='bold'> {formatEuro(totalAmount)+currency}</span>
                  </div>
                  <div className='mb-20' ref={refRange}>
                    <DiRangePicker onChange={categoriesRangeDate} />
                  </div>
               </div>
            </Col>
         </Row>
         {/* range di date per prodotti venduti e switcher del grafico*/}
         { 
            !loadingReceipt && categoriesData.length !== 0 ? <TableSelledCategories data={categoriesData}/> 
         :
            !loadingReceipt && categoriesData.length  ===  0 ? <NoData/>
         :
            loadingReceipt ? <TableSkeleton/> 
         : 
            ""
         }
   </div>
  )
}
