import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import dayjs from 'dayjs';

import { EditOutlined } from '@ant-design/icons';
import { Col, Row, Tag, Typography} from 'antd'

import { ENDPOINT_CORRECTION_LIST} from '../../helpers/Globals';
import TableCorrectionList from '../tables/TableCorrectionList';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import TableSkeleton from '../TableSkeleton';
import { ApiCall } from '../../Backoffice';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';


export default function DisplayCorrectionList() {
  
  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const [resetDate, setResetDate] = useState(0);
  const [data, setData] = useState([]);
  
  const location = useLocation();

  // ---- hook per indirizzare ad un componente dall'hash nell'url -----
  const removeHash = str => str.replace(/^#/, "");

  useEffect(() => {
    const idToNavigate = removeHash(location.hash);

    if (idToNavigate) {
      const element = document.getElementById(idToNavigate);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);
  // ---- hook per indirizzare ad un componente dall'hash nell'url -----

   
  // ---- variabili dal context -----
   const {
      commonQueryParams,
      refreshVariables,
      accessToken,
      refRange,
   } = useContext(Context);
  // ---- variabili dal context -----

   
  // --------------------- chiamata per ricevere i dati --------------------- 

    // settare la data giornaliera degli scontrini chiusi
    useEffect(()=>{

      var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
      var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

      setStartDate(startDate)
      setEndDate(endDate)

    },[resetDate])
    

    // chiamata per ricevere i dati degli scontrini chiusi
    useEffect(()=>{
      setLoadingReceipt(true);

      (async () => {

        const json = await ApiCall(`${ENDPOINT_CORRECTION_LIST}?date=${startDate}&end_date=${endDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
          if (json) {
            setData(json.CorrectionsList)
            setLoadingReceipt(false);
          }
      })();

    }, [startDate, ...refreshVariables]);
  
  // --------------------- chiamata per ricevere i dati --------------------- 

        
  // gestione del range date per gli scontrini chiusi 
  const handleRangeDate = (dates, dateStrings) => {

    if (dates) {

      var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
      var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      setResetDate(resetDate + 1);
    }
  };

  return (
    <div className="di-card flex column h-100 y-scroll" id={"correctionListTable"}>
        <Row>
        {/* range di date */}
            <Col span={24}>
               <div className='flex column align-center justify-center gap-10'>
                  <div className='flex gap-10 color-grey fs-15'>
                    <div className='flex align-center gap-5'><EditOutlined className='color-primary fs-20' /> Storico delle correzioni </div>
                    <DiTooltip text="Riepilogo di tutte le correzioni effettuate dai camerieri"/>
                  </div>
                  <div ref={refRange}>
                    <DiRangePicker onChange={handleRangeDate} />
                  </div>
               </div>
            </Col>
         </Row>
         {/* range di date */}
         { 
            !loadingReceipt && data.length !== 0 ? <TableCorrectionList data={data}/> 
         :
            !loadingReceipt && data.length  ===  0 ? <NoData/>
         :
            loadingReceipt ? <div className="mt-10"><TableSkeleton/> </div>
         : 
            ""
         }
   </div>
  )
}
