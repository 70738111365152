import React, { useContext } from 'react'

import { DeleteOutlined, NotificationOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd'

import createOperation from '../marketing/operations/MarketingOperationCreate';
import { dummyPeople } from '../../helpers/DummyData';
import confirmModal from '../DiConfirmModal';


export default function TableAudience({contextValue}) {
   
   const userProfileColumns = [
      {
         title: 'Nome',          // Column title
         dataIndex: 'name',      // Data index for this column (key in the data source)
         key: 'name',            // Unique key for React
         sorter: true,           // Enables sorting on this column
         fixed: 'left',
      },
      {
         title: 'Email',
         dataIndex: 'email',
         key: 'email',
         sorter: true,
         render: (text) => <a href={`mailto:${text}`}>{text}</a>, // Renders email as a mailto link
      },
      {
         title: 'Data di Nascita',
         dataIndex: 'dob',
         key: 'dob',
         sorter: true,
         render: (date) => new Date(date).toLocaleDateString(), // Formats date as string
      },
      {
         title: 'Telefono',
         dataIndex: 'phone',
         key: 'phone',
      },
      {
         title: 'Indirizzo',
         dataIndex: 'address',
         key: 'address',
         ellipsis: true, // Ellipsis to truncate if text is too long
      },
      {
         title: 'Azioni',
         key: 'actions',
         render: (_, record) => (
           <div className='flex gap-10'>
               <Button
                  type="link"
                  className='color-primary'
                  icon={<NotificationOutlined/>}
                  onClick={() => handleEdit(record)}
               />
               <Button
                  type="link"
                  danger
                  icon={<DeleteOutlined/>}
                  onClick={() => handleDelete(record)}
               />
           </div>
         ),
      },
   ];


   const handleEdit = (record) => {
      console.log('Editing user:', record);
      // Add your edit functionality here
      createOperation(contextValue,null,record.id)
   };

   const handleDelete = async (record) => {
      console.log('Inizio la cancellazione');
      // Add your edit functionality here

      
      const result = await confirmModal(
         "Stai per rimuovere questo utente dal pubblico. Non riceverà più operazioni, Vuoi comunque procedere?",
         contextValue
      ); //attendo la risposta dal componente di conferma modale

      if (result) {
         console.log('Cancello utente con ID:', record.id); 
      } else {
         console.log("Non cancello");
      }
   };
   
   return (
      <Table
         columns={userProfileColumns}
         scroll={{ x:'max-content'}} 
         dataSource={dummyPeople}
         bordered
      />
  )
}
