import { ArrowLeftOutlined, MenuOutlined, PaperClipOutlined, PhoneOutlined, UserOutlined, VideoCameraOutlined, WhatsAppOutlined } from '@ant-design/icons'
import React, { useContext, useEffect, useState } from 'react'

import { Avatar, Button, Col, Input, Row, theme, Typography } from 'antd';
import { CameraOutlined,LoadingOutlined,} from '@ant-design/icons'
import * as Unicons from '@iconscout/react-unicons';

import { CreateOperationDataContext } from '../../../../../helpers/Context';
import MarketingDeviceLayout from '../../MarketingDeviceLayout';

export default function ChannelWhatsAppPreview() {
  

  const { whatsAppData, formChanged } = useContext(CreateOperationDataContext);
  const [imageUrl, setimageUrl] = useState(null)
  const [load, setLoad] = useState(true);
  
  useEffect(() => {
    if (!whatsAppData?.description && !whatsAppData?.link) {
      setLoad(true)
    } else {
      setLoad(false)
    }
    if (whatsAppData?.file?.length > 0) {
      const file = whatsAppData?.file[0].originFileObj;
      const imageUrl = URL.createObjectURL(file);
      setimageUrl(imageUrl)
    } else {
      setimageUrl(null)
    }
  }, [whatsAppData, formChanged])
  
  return (
     <div className="preview-container color-white">
      {/* Schermata dispositivo */}
        <MarketingDeviceLayout>
        <div  style={{ background: 'url("/background-image-wa.jpg")' }}  className='h-100 flex column space-between'>
          
          <Row className='p-10 bg-dark-03' justify={"space-between"}>
            <div className='align-center flex gap-5'>
              <Unicons.UilArrowLeft />
              <Avatar icon={<UserOutlined />} />
            </div>
            <div className='align-center flex gap-10'>
              <Unicons.UilVideo />
              <Unicons.UilPhoneAlt />
              <Unicons.UilEllipsisV />
            </div>
          </Row>

          <div>
           <div className='w-100 flex justify-end '>
              <div className="bubble text-wrap sent bg-dark-03">
                {imageUrl && <img alt="Anteprima" className="w-100 di-preview-wa-image" src={imageUrl} />}
                <div className='mb-5'>{!load ? whatsAppData?.description : <LoadingOutlined />} </div>
                <a>{!load &&  whatsAppData?.link}</a>
              </div>
           </div>
  
            <div className='p-10 bg-dark-03'>
              <div className='flex gap-10'>
                <Input disabled className='radius-4 px-15' prefix={<Unicons.UilSmile className="color-white"/>} suffix={<><Unicons.UilPaperclip className="color-white"/><Unicons.UilCamera className="color-white"/></>} variant="filled" />
                <Button shape='circle' type='primary' size='large'><Unicons.UilMicrophone/></Button>
             </div>
            </div>
          </div>
          
        </div>
      </MarketingDeviceLayout>
    </div>
  )
}
