import React, { useContext, useEffect, useState } from 'react'

import * as Unicons from '@iconscout/react-unicons';
import { Row, Typography } from 'antd';

import { CreateOperationDataContext } from '../../../../../helpers/Context';
import { dateHourFormatIta } from '../../../../../helpers/DateUtils';
import MarketingDeviceLayout from '../../MarketingDeviceLayout';

import dayjs from 'dayjs';

export default function ChannelCouponPreview() {
  

  const { couponData, formChanged } = useContext(CreateOperationDataContext);
  const [imageUrl, setimageUrl] = useState(null)
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (!couponData?.description && !couponData?.link) {
      setLoad(true)
    } else {
      setLoad(false)
    }

    if (couponData?.file?.length > 0) {
      const file = couponData?.file[0].originFileObj;
      const imageUrl = URL.createObjectURL(file);
      setimageUrl(imageUrl)
    } else {
      setimageUrl(null)
    }
    
  }, [couponData, formChanged])
  
  return (
     <div className="preview-container">
      {/* Schermata dispositivo */}
      <MarketingDeviceLayout >
        <div className='h-100 flex column space-between'>
          
          <Row className='p-10 bg-dark-03' justify={"space-between"}>
            <div className='align-center flex gap-5'>
              <Unicons.UilArrowLeft />
              <Unicons.UilPostcard />
              <Typography className='bold'>Coupons</Typography>
            </div>
            <div className='align-center flex gap-10'>
              <Unicons.UilEllipsisV />
            </div>
          </Row>

          {!couponData?.hide &&
            <div className="h-100 px-15 pt-10">
              <div className='di-preview-coupon-card bg-white radius-1 mt-10 overflow-hidden'>
                {imageUrl && <div className=" di-preview-coupon-cover overflow-hidden flex align-center justify-center"><img alt="Anteprima"  className="w-100" src={imageUrl} /></div>}
                <div className="p-10">
                  <Typography className='bold color-black'>{couponData?.title}</Typography>
                  <Typography className='color-black fs-12'>{couponData?.subtitle}</Typography>
                  <Typography className='color-black fs-12'>Valido fino al: {couponData?.expiration_date?.format(dateHourFormatIta) }</Typography>
                  <Typography className='color-black fs-12'>N° Utilizzi consentiti: {couponData?.max_usage_customer}</Typography>
                </div>
                <div className='di-preview-coupon-footer border-solid  flex space-between px-15 align-center'>
                  <Typography className='color-primary'>Disponibile</Typography>
                  <div className='text-center'>
                    <Typography className='color-primary fs-14'>{couponData?.activity?.label}</Typography>
                    <Typography className='color-primary fs-20 bold'>{couponData?.cost}</Typography>
                  </div>
                </div>
              </div>
          </div>}
          
        </div>
      </MarketingDeviceLayout>
    </div>
  )
}
