import React from 'react'

import { Form, Input, Button, Col, Row, Typography, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import MarketingNumberConditionSelect from '../../../MarketingNumberConditionSelect';
import MarketingRemoveFieldIcon from '../../../MarketingRemoveFieldIcon';
import MarketingButtonAddField from '../../../MarketingButtonAddField';


export default function PersonalDataFiltersFormAge({ form, labelValue }) {
  
  const label = labelValue || "Età";

 return (
      <Form.List
        name="age_list"
        initialValue={[]} // Aggiungi un elemento iniziale vuoto
      >
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[10,10]} className='mb-20'>
              {fields.map(({ key, name, ...restField }) => (
                <Col
                  xs={24}
                  md={12}
                  key={key}
                  className='flex relative'
                >
                  {/* Campo Condizione */}
                 <Row gutter={[10,10]} className='w-100 di-filters-group-wrapper'>
                    <Col xs={24} sm={12} >
                        <Typography className='mb-5 marketing-field-label'>Confronto</Typography>
                        <MarketingNumberConditionSelect
                          nameValue={[name, 'condition']}
                          keyValue={[key, 'condition']}
                          initialValue="equal"
                        />
                      </Col>
                     {/* Campo Valore */}
                    <Col xs={24} sm={12} >
                      <Typography className='mb-5 marketing-field-label'>{label}</Typography>
                        <Form.Item
                          {...restField}
                          className='w-100'
                          name={[name, 'value']}
                          key={[key, 'value']}
                          rules={[
                            {
                              required: true,
                              message: `Per favore, inserisci l'${label.toLowerCase()}!`,
                            },
                          ]}
                        >
                           <InputNumber
                              min={1}
                              max={100}
                              changeOnWheel
                              className='w-100'
                           />
                        </Form.Item>
                     </Col>
                 </Row>
  
                  {/* Pulsante per rimuovere il campo */}
                  <MarketingRemoveFieldIcon onClick={() => remove(name)}/>
  
                </Col>
              ))}
            </Row>
         
            <MarketingButtonAddField
              onClick={() => add()} 
              label={label}
            />
         
          </>
        )}
      </Form.List>
  );
}
