import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs';

import { UserSwitchOutlined } from '@ant-design/icons';
import { Col, Row} from 'antd'

import TableWaiterProductions from '../tables/TableWaiterProductions';
import { ENDPOINT_PERIOD_SALES } from '../../helpers/Globals';
import duplicateArray from '../../helpers/DuplicateArray';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import TableSkeleton from '../TableSkeleton';
import { ApiCall } from '../../Backoffice';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';


export default function DisplayWaiterProduction() {
   
   const [sdWaiterProduction, setSdWaiterProduction] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
   const [edWaiterProduction, setEdWaiterProduction] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
   const [loadingWaiterProductionData, setLoadingWaiterProductionData] = useState(false);
   const [resetWaiterProductionDate, setResetWaiterProductionDate] = useState(0);
   const [waiterProductionData, setWaiterProductionData] = useState([]);
  
   
  // ---- variabili dal context -----
  const {
      commonQueryParamsNoWaiter,
      refreshVariables,
      accessToken,
   } = useContext(Context);
  // ---- variabili dal context -----

   
// --------------------- chiamata per ricevere i dati degli scontrini chiusi (receipt) --------------------- 

      // settare la data giornaliera degli scontrini chiusi
      useEffect(()=>{

        var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
        var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

        setSdWaiterProduction(startDate)
        setEdWaiterProduction(endDate)

      },[resetWaiterProductionDate])
      

      // chiamata per ricevere i dati degli scontrini chiusi
      useEffect(()=>{
        setLoadingWaiterProductionData(true);

        (async () => {

         const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${sdWaiterProduction}&end_date=${edWaiterProduction}&group_by_waiter=1&${commonQueryParamsNoWaiter}`, "GET", `${accessToken}`, null)

         var data = duplicateArray(json);
          if (json) {
            setWaiterProductionData(data);
            setLoadingWaiterProductionData(false);
          }
        })();

      },[sdWaiterProduction, ...refreshVariables]);

      // gestione del range date per gli scontrini chiusi 
      const WaiterProductionRangeDate = (dates, dateStrings) => {

        if (dates) {

          var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
          var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
          setSdWaiterProduction(newStartDate);
          setEdWaiterProduction(newEndDate);
        } else {
          setResetWaiterProductionDate(1);
        }
      };

   
  return (
    <div className="di-card flex column h-100 y-scroll">
        <Row>
        {/* range di date per prodotti venduti e switcher del grafico*/}
            <Col span={24}>
               <div className='flex column align-center justify-center gap-10'>
                  <div className='flex gap-10 color-grey fs-15'>
                  <div className='flex align-center gap-5'><UserSwitchOutlined className='color-primary fs-20'/> Incassato e Venduto diviso per Camerieri</div>
                  <DiTooltip text="Dati sull'incassato e venduto diviso per camerieri, non soggetto al filtro generico per cameriere"/>
                  </div>
                  <div className='mb-20'>
                    <DiRangePicker onChange={WaiterProductionRangeDate} />
                  </div>
               </div>
            </Col>
         </Row>
         {/* range di date per prodotti venduti e switcher del grafico*/}
         { 
            !loadingWaiterProductionData && waiterProductionData.length !== 0 ? <TableWaiterProductions data={waiterProductionData}/> 
         :
            !loadingWaiterProductionData && waiterProductionData.length  ===  0 ? <NoData/>
         :
            loadingWaiterProductionData ? <TableSkeleton/> 
         : 
            ""
         }
   </div>
  )
}
