import React from 'react';

function Logo({ white }) {
  return (
    <img
      className="di-logo-extended"
      src={white ? '/logo-white.svg' : '/logo-extended-green.svg'}
      alt="Logo"
    />
  );
}

export default React.memo(Logo);
