import React, { useContext, useRef, useState } from 'react'

import { EditOutlined, GiftOutlined, PictureOutlined, PlusOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Card, Checkbox, DatePicker, Divider, Form, Input, InputNumber, message, Modal, Select, Typography, Upload } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';

import { CreateOperationDataContext } from '../../../../../helpers/Context';
import { dateHourFormat } from '../../../../../helpers/DateUtils';
import MarketingUploadFile from '../../../MarketingUploadFile';
import DiRangePicker from '../../../../DiRangePicker';
import MarketingOperationCopyButton from '../../MarketingOperationCopyButton';

export default function ChannelCouponForm() {
   
  const [haveCondition, sethaveCondition] = useState(false);
  const [haveCosts, sethaveCosts] = useState(true);
  const [text, setText] = useState('');

  const textAreaRef = useRef(null);

  const contextValue = useContext(CreateOperationDataContext);

  const formPrefix = "coupon_data";

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 }, 
      md: { span: 10 },
      lg: { span: 12 },
      xl: { span: 8 },
      xxl: { span:6 },
    },
  };
  const formItemOrientation = "horizontal";


  return (
   <Card className='bg-dark-02'>
      <Typography className="fs-16 bold mb-20">
         <GiftOutlined /> Dati del Coupon
      </Typography>
        
      <Form.Item
        {...formItemLayout} 
        layout='horizontal'
        name={[formPrefix, "title"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "title"]}
            />
            Nome
          </div>
        }
        rules={[
          {
            required: true,
            message: "Per favore, inserisci il nome del Coupon!",
          },
        ]}
      >
        <Input/>
      </Form.Item>
        
      <Form.Item
        {...formItemLayout}
        layout={formItemOrientation}
        name={[formPrefix, "code"]}
        label={"Codice Coupon"}
        rules={[
          {
            required: true,
            message: "Il campo riferimento è obbligatorio per il Coupon",
          },
        ]}
      >
        <Input/>
      </Form.Item>
        
      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "subtitle"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "subtitle"]}
            />
            Descrizione Breve
          </div>
        }
      >
        <Input allowClear/>
      </Form.Item>
        
      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "description"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "description"]}
            />
            Descrizione Completa
          </div>
        }
      >
         <TextArea
            ref={textAreaRef} // Riferimento alla TextArea
            maxLength={80}
            value={text}
            showCount
            allowClear
         />
      </Form.Item>
        
      <Form.Item
        {...formItemLayout} 
        name={[formPrefix, "file"]}
        label={"Copertina"}
        valuePropName={"fileList"}
        layout={formItemOrientation}
        getValueFromEvent={(e) => e?.fileList}
      >
        <MarketingUploadFile context={contextValue} fieldName={formPrefix} />
      </Form.Item>

      <Divider>Valore</Divider>
      
      <Form.Item
        {...formItemLayout} 
        name={[formPrefix, "type"]}
        key={[formPrefix, "type"]}
        label="Tipologia"
        layout={formItemOrientation}
        initialValue={"percentage"}
      >
        <Select
          placeholder="Seleziona la tipologia"
          allowClear
          options={[
            { value: "percentage", label: "Percentuale" },
            { value: "amount", label: "Importo" },
            { value: "offer", label: "Offerta" },
          ]}
        />
      </Form.Item>
      
      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        label={"Valore Coupon"}
        name={[formPrefix, "value"]}
        key={[formPrefix, "value"]}
        rules={[
          {
            required: true,
            message: "Il valore è obbligatorio per il Coupon",
          },
        ]}
      >
        <InputNumber/>
      </Form.Item>
      
      <Divider>Condizioni</Divider>

       <Form.Item
          name={[formPrefix, "is_obtained"]}
          valuePropName="checked"
          onChange={()=>sethaveCondition(!haveCondition)}
        >
          <Checkbox>Il Coupon ha delle condizioni</Checkbox>
      </Form.Item>

      {haveCondition &&
       <>
          <FormItem
            {...formItemLayout} 
            layout={formItemOrientation}
            name={[formPrefix, "validation_range_date"]}
            label="Range di validità del coupon"
          >
            <DiRangePicker enableAllDate outlined hidePreset/>
          </FormItem>
        
          <FormItem
            {...formItemLayout} 
            layout={formItemOrientation}
            name={[formPrefix, "expiration_date"]}
            label="Data di Scadenza del Coupon"
          >
            <DatePicker
              className='mobile-w-100'
              format={dateHourFormat}
              placeholder='Seleziona la scadenza'
            />
          </FormItem>
        
          <Form.Item
            {...formItemLayout} 
            layout={formItemOrientation}
            label={"Utilizzi per cliente"}
            name={[formPrefix, "max_usage_customer"]}
            key={[formPrefix, "max_usage_customer"]}
            initialValue={1}
            rules={[
              {
                required: true,
                message: "Utilizzi per cliente è obbligatorio",
              },
            ]}
          >
            <InputNumber min={1}/>
          </Form.Item>
        
          <Form.Item
            {...formItemLayout} 
            layout={formItemOrientation}
            label={"Utilizzi massimi totali"}
            name={[formPrefix, "total_usage"]}
            key={[formPrefix, "total_usage"]}
          >
            <InputNumber min={0} allowClear/>
          </Form.Item>
        
          <Form.Item
            {...formItemLayout} 
            layout={formItemOrientation}
            label={"Importo minimo di spesa"}
            name={[formPrefix, "min_shop_amount"]}
            key={[formPrefix, "min_shop_amount"]}
          >
            <InputNumber min={0} allowClear/>
          </Form.Item>
        
          
          <Form.Item
            {...formItemLayout} 
            layout={formItemOrientation}
            name={[formPrefix, "hide"]}
            valuePropName="checked"
            label="Nascondi coupon nella lista"
          >
            <Checkbox>Nascondi coupon</Checkbox>
          </Form.Item>
        
       </>
      }

      <Divider>Valore per ottenimento</Divider>
      
      <Form.Item
        name={[formPrefix, "is_free"]}
        valuePropName="checked"
        onChange={()=>sethaveCosts(!haveCosts)}
      >
            <Checkbox>Il coupon è gratuito</Checkbox>
      </Form.Item>

      {haveCosts &&
        <>
          <Form.Item
            {...formItemLayout} 
            name={[formPrefix, "campaign"]}
            key={[formPrefix, "campaign"]}
            label="Campagna"
            layout={formItemOrientation}
            initialValue={{"label":"Punti", "id":1}}
            rules={[
              {
                required: true,
                message: "Se il coupon non è gratuito, va scelta la campagna",
              },
            ]}
          >
            <Select
              placeholder="Seleziona la tipologia"
              allowClear
              options={[
                { value: 1, label: "Punti" },
                { value: 2, label: "Prepagato" },
              ]}
            />
          </Form.Item>
        
          <Form.Item
            {...formItemLayout} 
            name={[formPrefix, "activity"]}
            key={[formPrefix, "activity"]}
            label="Attività"
            layout={formItemOrientation}
            initialValue={{"label":"Punti", "id":1}}
            rules={[
              {
                required: true,
                message: "Se il coupon non è gratuito, scegli l'attività",
              },
            ]}
          >
            <Select
              placeholder="Seleziona la tipologia"
              allowClear
                options={[
                { value: 1, label: "Punti" },
                { value: 2, label: "Prepagato" },
              ]}
            />
          </Form.Item>
        
          <Form.Item
            {...formItemLayout} 
            layout={formItemOrientation}
            label={"Importo per ottenere il coupon"}
            name={[formPrefix, "cost"]}
            key={[formPrefix, "cost"]}
            rules={[
              {
                required: true,
                message: "Se il coupon non è gratuito, scegli la cifra per ottenerlo",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
        </>
      }
    </Card>

    
  );
}
