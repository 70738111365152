import React from 'react'

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Card, Checkbox, Typography } from 'antd'

import { COMPARE_GREEN, COMPARE_RED } from '../../../helpers/Globals';
import DiTooltip from '../../DiTooltip'

export default function MarketingAudienceCard({ title, description, amount, compareDate, id, onChange, selectedAudience }) {
  
  const checked = selectedAudience.some(item => item.id === id); //se l'id del pubblico è presente in quelli selezionati, lo do per selezionato
  
  return (
    <Card
        bordered={false}
        className='di-audience-card text-center'
      
        // titolo del pubblico e descrizione
        title={
          <div className='flex align-center justify-center gap-5 text-wrap'>
            <Checkbox checked={checked} value={id} onChange={onChange} className='flex align-center gap-5'>
              <Typography.Title className='m-0 mb-0' level={5}>{title}</Typography.Title>
            </Checkbox>
            <DiTooltip text={description} />
          </div>
        }  
        // titolo del pubblico e descrizione

        // valori e date di confronto
        actions={
          compareDate.map((e) => {
            return (
              <div className="flex w-100 justify-center fs-15" style={{color:e.positive ? COMPARE_GREEN : COMPARE_RED}}>
                {e.positive ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                {e.amount}
              </div>
            )
          })
        }
        // valori e date di confronto
      >
       <Typography className="mb-0 m-0 fs-20 color-primary" level={4}>{amount}</Typography>
      </Card>
  )
}
