import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs';

import { Col, Row, Spin} from 'antd';

import { ENDPOINT_SOLD_ITEMS, LOCAL_TOP_10_PRODUCT_COLOR } from '../../helpers/Globals';
import DownloadCsvButton from '../DownloadCsvButton';
import DiPaletteSelector from '../DiPaletteSelector';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import { ApiCall } from '../../Backoffice';
import PieChart from '../charts/PieChart';
import DiTooltip from '../DiTooltip';
import NoData from '../NoData';
import { StarFilled } from '@ant-design/icons';


export default function DisplayTop10Products() {
   
   const [top10ProductsColor, setTop10ProductsColor] = useState(localStorage.getItem(LOCAL_TOP_10_PRODUCT_COLOR) ? localStorage.getItem(LOCAL_TOP_10_PRODUCT_COLOR) : "paired");
   const [sdTop10Products, setSdTop10Products] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
   const [edTop10Products, setEdTop10Products] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
   const [resetTop10ProductsDate, setResetTop10ProductsDate] = useState(0);
   const [loadTop10Products, setLoadTop10Products] = useState(true);
   const [loadDownload, setLoadDownload] = useState(false);
   const [top10Products, setTop10Products] = useState(null);
   

   // ---- variabili dal context -----
   const {
      commonQueryParams,
      refreshVariables,
      isRestaurant,
      accessToken,
      setService,
      refColors,
      refRange,
      refCsv,
   } = useContext(Context);
   // ---- variabili dal context -----


   useEffect(()=>{

      var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
      var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

      setSdTop10Products(startDate)
      setEdTop10Products(endDate)

   },[resetTop10ProductsDate])

   //chiamata per ricevere i dati venduto prodotti per periodo
   useEffect(()=>{
      setLoadTop10Products(true);
      (async () => { 
      const json = await ApiCall(`${ENDPOINT_SOLD_ITEMS}?date=${sdTop10Products}&end_date=${edTop10Products}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
      var service = 0;

      if(json){
         //riordino i prodotti venduti per quantità decrescente
         json.sort((a,b) => (a.quantity < b.quantity) ? 1 : ((b.quantity < a.quantity) ? -1 : 0));
         //recupero i primi 10

         var tempTop10products = [];
         tempTop10products = [...json].slice(0, isRestaurant ? 11 : 10);

         for (let i = 0; i < tempTop10products.length; i++) {
            
            // se il prodotto è "SERVIZIO" lo uso per incrementare il costo servizio e poi salto al prossimo eliminando il prodotto servizio 
            // per non farlo comparire nel grafico a torta
            if (tempTop10products[i]["description"] === "SERVIZIO") {
               service = tempTop10products[i].amount;
               tempTop10products.splice(i, 0);
               continue
            }
            
            //modifica delle chiavi prodotto in etichette per i grafici
            tempTop10products[i]["id"] = tempTop10products[i]["description"];
            tempTop10products[i]["label"] = tempTop10products[i]["description"];

            //tempTop10products[i]["Incasso medio"] = tempTop10products[i]["average"];
            //delete tempTop10products[i]["average"];

            tempTop10products[i]["value"] = tempTop10products[i]["quantity"];

         };

         setTop10Products(tempTop10products);
         setService(service);
         setLoadTop10Products(false);
      }
      })(); 

   }, [sdTop10Products, edTop10Products, ...refreshVariables]);
   
   // gestione del range date per il totale venduto prodotti
   const top10ProductsRange = (dates, dateStrings) => {
      setLoadTop10Products(true);

      if (dates) {
         var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
         var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
         setSdTop10Products(newStartDate);
         setEdTop10Products(newEndDate);
         setLoadTop10Products(false);
      } else {
         setResetTop10ProductsDate(resetTop10ProductsDate + 1);
         setLoadTop10Products(false);
      }
   };

   useEffect(() => {
      if (top10Products === null || top10Products.length < 0) return;
      setLoadDownload(true);
   },[top10Products])

  return (
       <div className="di-card h-100">
         <Row>
            <Col span={24}>
               <div className='flex column align-center justify-center gap-10'>
               <div className='flex gap-10 color-grey fs-15'>
                     <div className='flex align-center gap-5'><StarFilled className='color-yellow'/> Top 10 Prodotti Venduti</div>
                     <DiTooltip text={"Grafico dei Top10 prodotti venduti per l'arco di tempo stabilito"}/>
               </div>
               <div className='flex align-center gap-10 space-between w-100 flex-wrap'>
                  <div ref={refRange}><DiRangePicker onChange={top10ProductsRange}/></div>
                  <div className='flex gap-10'>
                     <div ref={refColors}>
                           <DiPaletteSelector
                              defaultValue={top10ProductsColor}
                              onChange={
                                 (value)=>{
                                    setTop10ProductsColor(value);
                                    localStorage.setItem(LOCAL_TOP_10_PRODUCT_COLOR,value)
                                 }
                              }
                           />
                     </div>
                     {loadDownload && <div ref={refCsv} ><DownloadCsvButton dataToDownload={top10Products} filename={"top-10-prodotti-venduti"}/></div>}
                  </div>
               </div>
               </div>
            </Col>
         </Row>
         <Row className='h-100 flex align-center'>
            <Col span={24}>
               {
               top10Products !== null && top10Products.length === 0 ? <NoData/>
               :
               loadTop10Products ? <div className="w-100 flex align-center justify-center pie-chart"><Spin /></div>
               :  
               top10Products !== null &&       
               <div className="pie-chart">
                  <PieChart data={top10Products} colors={top10ProductsColor}/>
               </div>
               }
            </Col>
         </Row>
         </div>
  )
}
