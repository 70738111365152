import { Card, Checkbox, Col, Form, Row, Typography } from 'antd'
import React from 'react'

export default function SoftwareFilters({form}) {
  
  const options = [
    {
      label: 'Sign WOW',
      value: 'signa_wow',
    },
    {
      label: 'WOW Commerce',
      value: 'wow_commerce',
    },
    {
      label: 'AppMobile (Android ed iOS)',
      value: 'app_mobile',
    },
    {
      label: 'WebApp Self Order',
      value: 'web_app_self_order',
    },
    {
      label: 'WebApp Pagamento',
      value: 'web_app_payment',
    },
    {
      label: 'Bacco',
      value: 'bacco',
    },
  ];

  return (
    <>
      <div className='di-filters-group-wrapper mt-20 mb-30'>
       <div className='p-10'>
          <Form.Item name="softwares_list" label="Seleziona solo gli utenti che sono censiti nei seguenti software">
            <Checkbox.Group>
              <Row>
                {options.map((e) => (
                  <Col key={e.value}>
                    <Checkbox value={e.value}>{e.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
       </div>
      </div>
    </>
  )
}
