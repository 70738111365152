import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import React, { useState } from 'react'
import { validateFile } from '../../helpers/ValidateFiles';

export default function MarketingUploadFile({context, fieldName}) {
   
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
   
   const { form, handleFormChange } = context;

  // Gestisce l'anteprima del file
  const handlePreview = (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  // Gestisce il cambiamento della lista dei file
  const handleChange = (info) => {
    // Filtra solo i file validi
    const validFiles = info.fileList.filter((file) => {
      if (file.originFileObj) {
        return validateFile(file.originFileObj);
      }
      return true; // Mantieni i file già validati
    });

    // Aggiorna la lista dei file
    setFileList(validFiles);

    // Aggiorna il valore nel form manualmente
    form.setFieldsValue({
      [fieldName]: {
        ...form.getFieldValue([fieldName]),
        file: validFiles,
      },
    });

    handleFormChange();
  };

  // Gestisce la rimozione dei file
  const handleRemove = (file) => {
    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);

    // Aggiorna il valore nel form manualmente
    form.setFieldsValue({
      [fieldName]: {
        ...form.getFieldValue([fieldName]),
        file: updatedFileList,
      },
    });

    handleFormChange();
  };
   
  return (
    
     <>
         <Upload
           listType="picture-card"
           fileList={fileList}
           onPreview={handlePreview}
           onChange={handleChange}
           beforeUpload={(file) => {
             // Esegui la validazione prima di aggiungere il file
             if (validateFile(file)) {
               file.preview = URL.createObjectURL(file); // Genera preview manualmente
               return false; // Evita l'upload automatico
             }
             return Upload.LIST_IGNORE; // Blocca file non validi
           }}
           onRemove={handleRemove}
           accept="image/jpeg,image/png,image/gif"
         >
           {fileList.length >= 1 ? null : (
             <div>
               <PlusOutlined />
               <div>Carica</div>
             </div>
           )}
        </Upload>
        
         
         <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
           <img alt="Anteprima" style={{ width: '100%' }} src={previewImage} />
         </Modal>
        
     </>

  )
}
