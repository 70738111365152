import React, { useContext, useState } from 'react'

import { CaretRightOutlined, CarOutlined, CreditCardOutlined, EuroCircleOutlined,ProductOutlined } from '@ant-design/icons'
import { Badge, Col, Collapse, Row, Typography } from 'antd'

import EcommerceFiltersCategoriesProducts from './EcommerceFiltersCategoriesProducts'
import EcommerceFiltersShippingPayment from './EcommerceFiltersShippingPayment'
import { CreateAudienceDataContext } from '../../../../../helpers/Context'
import EcommerceFiltersOrders from './EcommerceFiltersOrders'
import MarketingFiltersToggleCollapse from '../MarketingFiltersToggleCollapse'


export default function EcommerceFilters({ form }) {
  
  const {   
      setactiveKeysEcommerce,
      formShippingPayments,
      activeKeysEcommerce,
      colorEcommerce,
      formProducts,
      formOrders,
      keys,
  } = useContext(CreateAudienceDataContext);

  // Funzione per espandere o contrarre tutti i pannelli
  const toggleAll = () => {
    if (activeKeysEcommerce.length === 0) {
      // Espandi tutti i pannelli, ottieni le chiavi dei pannelli
      setactiveKeysEcommerce(keys);
    } else {
      // Contrai tutti i pannelli (set activeKeys to empty array)
      setactiveKeysEcommerce([]);
    }
  };
  
   
  return (
    <>
      <Row justify={"end"} gutter={[10,10]} className='w-100'> 
        <Col>
          <MarketingFiltersToggleCollapse onClick={toggleAll} activeKeys={activeKeysEcommerce}/>
        </Col>
      </Row>
      <Collapse
        activeKey={activeKeysEcommerce}
        onChange={setactiveKeysEcommerce}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        expandIconPosition='end'
        size='large'
        bordered={false}
        items={[
          // -------- inizio array di elementi accordion --------

          // -------- gestione campagna ---------
          {
            key: '1',
            forceRender:true,
            label: (
              <div className='flex-wrap gap-10 align-center'>
                    <div className='flex align-center gap-5'>
                      <Typography className='fs-16 flex align-center gap-10'>
                        <div className='fs-16'><ProductOutlined /></div>
                        Categorie e Prodotti
                      </Typography>
                    <Badge color={formProducts?.length ? colorEcommerce : ""} count={formProducts?.length}/>
                    </div>
                  <Typography className='fs-12 opacity-05 flex align-center gap-5'>
                    Definisci gli utenti in base ai loro acquisti di prodotto
                  </Typography>
              </div>
            ),
            
            children:
               <div className='mt-10'>
                  <EcommerceFiltersCategoriesProducts form={form} labelValue={"Categorie/Prodotti"} />
              </div>,
          },
          // -------- gestione campagna ---------

          // -------- gestione transazioni ---------
          {
            key: '2',
            forceRender:true,
            label: (
              <div className='flex-wrap gap-10 align-center'>
                 <div className='flex gap-10 alù'>
                    <Typography className='fs-16 flex align-center gap-5'>
                      <div className='fs-16'><EuroCircleOutlined /></div>
                      Ordini
                    </Typography>
                    <Badge color={formOrders?.length ? colorEcommerce : ""} count={formOrders?.length}/>
                 </div>
                <Typography className='fs-12 opacity-05 flex align-center gap-5'>
                  Definisci gli utenti sceondo il numero di ordini effettuati
                </Typography>
              </div>
            ),
            children: (
                <div className='mt-10'>
                  <EcommerceFiltersOrders form={form} labelValue={"Ordini"} />
                </div>
            ),
          },
          // -------- gestione transazioni ---------

          // -------- gestione dettaglio coupon ---------
          {
            key: '3',
            forceRender:true,
            label: (
              <div className='flex-wrap gap-10 align-center'>
                   <div className='flex gap-10 align-center'>
                      <Typography className='fs-16 flex align-center gap-5'>
                        <div className='fs-16'><CreditCardOutlined /></div>
                        Metodi di Pagamento e Spedizione
                      </Typography>
                    <Badge color={formShippingPayments?.length ? colorEcommerce : ""} count={formShippingPayments?.length}/>
                   </div>
                  <Typography className='fs-12 opacity-05 flex align-center gap-5'>
                    Definisci gli utenti in base ai metodi di pagamento e spedizione più utilizzati
                  </Typography>
                  {/* {<Tag color='yellow' className='text-wrap fs-16 p-10'>Selezionando {naturalLanguageBirthdate(birthday)} </Tag>} */}
              </div>
            ),
            
            children:
              <div className='mt-10'>
                  <EcommerceFiltersShippingPayment form={form} labelValue={"Metodi di spedizione/pagamento"} />
              </div>,
          },
          // -------- gestione dettaglio coupon ---------
          
          // -------- fine array di elementi accordion --------
        ]}
      />
    </>
  )
}
