import { Col, Row, Typography } from 'antd'
import React from 'react'

import ChannelEmailPreview from './ChannelEmailPreview'
import ChannelEmailForm from './ChannelEmailForm'


export default function ChannelEmailContent() {
   
  return (
    <div className='operations-group-email'>
       <Row gutter={[20,20]} className='mt-20'>
           <Col xs={24} md={14}>
              <ChannelEmailForm/>
           </Col>
           <Col xs={24} md={10} className=''>
              <div className='sticky top-20'>
                  <Typography className='fs-18 bold mb-10 text-center'>
                     Preview
                  </Typography>
                  <ChannelEmailPreview/>
              </div>
           </Col>
        </Row>
    </div>
  )
}
