import React from 'react'

import { Col, Row } from 'antd'

import MarketingAudienceCard from '../audience/MarketingAudienceCard'
import { dummyAudiences } from '../../../helpers/DummyData'
import MarketingEmpty from '../MarketingEmpty'

export default function MarketingOperationCreateSelectAudience({ contextValue, onChange, selectedAudience }) {


   return (
     <div className='di-operation-wizard-wrapper'>
        <Row gutter={[20, 20]}>
           {false ?
               <div className='w-100'><MarketingEmpty contextValue={contextValue} /></div>
            :
               dummyAudiences.map((e) => {
                  return (
                     <Col span={8} xs={24} sm={12}  md={12} lg={8}>
                        <MarketingAudienceCard
                           selectedAudience={selectedAudience}
                           compareDate={e.compareDate}
                           description={e.description}
                           onChange={onChange}
                           amount={e.amount}
                           title={e.title}
                           id={e.id}
                        />
                     </Col>
                  )
               })
         }
      </Row>
     </div>
  )
}
