import React, { useContext } from 'react'

import { Button, Popover } from 'antd'
import { MoonFilled, SunFilled } from '@ant-design/icons';

import { Context } from '../helpers/Context';

export default function DiDarkModeButton() {
   
   const {
      changeDarkMode,
      refDarkMode,
      darkMode,
   } = useContext(Context);
   
  return (
    <Popover title={`Imposta Modalità ${darkMode ? "Chiara" : "Scura"}`}>
       <Button
         ref={refDarkMode}
         shape='circle'
         icon={darkMode ? <MoonFilled /> : <SunFilled/>}
         onClick={()=>changeDarkMode(!darkMode)}
      />
    </Popover>
  )
}
