import React, { useContext, useState } from 'react'

import { Checkbox, Col, Divider, Drawer, FloatButton, Row, Select, Typography} from 'antd';
import { FilterFilled} from '@ant-design/icons';

import { LOCAL_DATA_FILTER, LOCAL_TRANSACTION_TYPE, LOCAL_USE_CURRENT_TIME } from '../helpers/Globals';
import { Context } from '../helpers/Context';
import DiTooltip from './DiTooltip';

export default function FiltersDrawer() {

   const [open, setOpen] = useState(false);
   
   const {
      closeNotificationWaiter,
      openNotificationWaiter,
      setTransactionType,
      
      baccoDocumentTypeList,
      setBaccoDocumentType,
      baccoDocumentType,
      
      setHotelFilter,
      hotelFilter,
        
      serviceNotification,
      
      setUseCurrentTime,
      setUseDataFilter,
      setChangeFilter,
      transactionList,
      useCurrentTime,
      setWaiterCode,
      setWaiterName,
      useDataFilter,
      changeFilter,
      isRestaurant,
      waitersList,
      waiterCode,
      refFilter,
      userType,
   } = useContext(Context);
   

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

   
  //gestione del cambio statistiche rapide 
  const changeFastStats = (value) => {
    const useCurrentTime = value.target.checked;
    localStorage.setItem(LOCAL_USE_CURRENT_TIME, useCurrentTime)
    setUseCurrentTime(useCurrentTime)
    setOpen(false);
    setChangeFilter(changeFilter + 1);
  };
   
  // --------- gestione delle spunte per i tipi di transazione signa --------- 
  const changeTransaction = (checkedValues) => {
    localStorage.setItem(LOCAL_TRANSACTION_TYPE, JSON.stringify(checkedValues))
    var tempTransactionType = checkedValues.join('+');
    setTransactionType(tempTransactionType);
    setChangeFilter(changeFilter + 1);
  };
  // --------- gestione delle spunte per i tipi di transazione signa --------- 
   
  // --------- gestione delle spunte per i tipi di documento in bacco --------- 
  const handleBaccoDocumentTypeChange = (checkedValues) => {
    setBaccoDocumentType(checkedValues.join('+'));
    setChangeFilter(changeFilter + 1);
  };
  // --------- gestione delle spunte per i tipi di transazione signa --------- 

  // --------- gestione della spunta data filtro --------- 
  const changeDataFilter = (value) => {
    const dataFilter = value.target.checked ? 1 : 0;
    localStorage.setItem(LOCAL_DATA_FILTER, dataFilter)
    setUseDataFilter(dataFilter)
    setChangeFilter(changeFilter + 1);
    setOpen(false);
  };
  // --------- gestione della spunta data filtro --------- 

  // --------- gestione della spunta addebito hotel --------- 
  const changeHotelFilter = (value) => {
    const hotelFilter = value.target.checked ? 1 : 0;
    serviceNotification(hotelFilter === 1 ? "Stai vedendo i dati per gli Addebiti Hotel" : "Hai tolto il filtro Addebiti Hotel");
    setHotelFilter(hotelFilter)
    setChangeFilter(changeFilter + 1);
    setOpen(false);
  };
  // --------- gestione della spunta addebito hotel --------- 

  return (
     <>
        <FloatButton ref={refFilter} icon={<FilterFilled />} onClick={showDrawer} className="bottom-20" type="primary" />
        <Drawer
          title="Gestione dati"
          onClose={onClose}
          open={open}
          destroyOnClose
          styles={{
            paddingBottom: 80,
          }}
        >
            {/* ----------------------- SOLO RISTORATORI: campo per ottenere i dati secondo la data lavoro ----------------------- */}
            {isRestaurant &&
              <Typography span={24}>
                  <Checkbox key={"data_filter"} checked={useDataFilter} onChange={changeDataFilter}>Utilizza data lavoro</Checkbox>
                  <DiTooltip text="Tutti i dati verranno mostrati tenendo in considerazione che la tua giornata lavorativa finisca secondo quella impostata da te e non la data solare"/>
              </Typography>
            }
            {/* ----------------------- SOLO RISTORATORI: campo per ottenere i dati secondo la data lavoro ----------------------- */}

        
            {/* ----------------------- filtri per cambiare statistiche rapide sull'ora corrente o tutto il giorno precedente ----------------------- */}
            <Typography>
                <Checkbox key={"use_current_time"} checked={useCurrentTime} onChange={changeFastStats}>
                  Utilizza orario corrente
                </Checkbox>
                <DiTooltip text="Con questa impostazione andrai a confrontare i dati di oggi con i giorni prcedenti fino all'orario corrente o confrontando tutto il giorno precedente con i dati ottenuti oggi fin'ora"/>
            </Typography>
            {/* ----------------------- filtri per cambiare statistiche rapide sull'ora corrente o tutto il giorno precedente ----------------------- */}

        
            {/* ----------------------- lista camerieri per filtrare i dati ----------------------- */}
            {isRestaurant &&
              <>
                <Divider/>
                <Typography  className='mt-10 mb-10'>
                    <span> Seleziona cameriere </span>
                    <DiTooltip text="Tutti i dati verranno mostrati tenendo in considerazione solo il cameriere scelto (anche le statistiche rapide). Lascia vuoto per tutti i dati"/>
                </Typography>
                <Select
                  showSearch
                  placeholder="Seleziona Negozio"
                  optionFilterProp="children"
                  className='w-100 min-w-150'
                  allowClear
                  value={waiterCode}
                  onClear={()=>{
                    setWaiterCode("");
                    setWaiterName("");
                    setOpen(false);
                    closeNotificationWaiter();
                  }}
                  onChange={
                    (value, i) => {
                      closeNotificationWaiter();
                      setWaiterName(i.name);
                      setOpen(false);
                      setWaiterCode(value ?? "");
                      openNotificationWaiter(i.name);
                    }
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={waitersList}
                />
                <Divider/>
                <Typography  className='mt-10 mb-10'>
                    <span> Tipi di documenti </span>
                    <DiTooltip text="Qui potrai selezionare determinati tipi di documenti per cui visionare i dati. Il filtro non viene salvato."/>
                </Typography>
                
                {baccoDocumentTypeList && baccoDocumentTypeList.length > 0 &&     
                  <>
                    <Checkbox.Group
                      className='w-100'
                      onChange={handleBaccoDocumentTypeChange}
                      value={baccoDocumentType ? baccoDocumentType.split('+') : []}
                    >
                      {baccoDocumentTypeList.map((e, index) => {
                        return(
                            <Col key={index} span={24}>
                              <Checkbox value={e.key} name={e.key}>{e.value}</Checkbox>
                            </Col>
                          )
                        })
                      }
                    </Checkbox.Group>
                    <Divider className='mt-10 mb-10'/>
                    <Col span={24}>
                      <Checkbox key={"filter_hotel"} checked={hotelFilter} onChange={changeHotelFilter}>Addebito Hotel</Checkbox>
                    </Col>
                  </>
                }
             </>
            }
            {/* ----------------------- lista camerieri per filtrare i dati ----------------------- */}
          

            {transactionList && transactionList.length > 0 &&     
              <Checkbox.Group
                className='w-100'
                onChange={changeTransaction}
                defaultValue={JSON.parse(localStorage.getItem(LOCAL_TRANSACTION_TYPE))}
              >
                <Row>
                {/* ----------------------- filtra per tipi di movimenti ----------------------- */}
                  <Divider>
                    <span className='m-2'>Filtra i movimenti </span>
                    <DiTooltip text="Scegli quali movimenti vedere, in caso di multiselezione il reso verrà sottratto, selezionando solamente il reso, questo verrà addizionato"/>
                  </Divider>
                  {transactionList.map((e, index) => {
                    return(
                      <Col key={index} span={24}>
                        <Checkbox value={e.CodTipoMovimento} name={e.CodTipoMovimento}>{e.Descrizione}</Checkbox>
                      </Col>
                      )
                    })
                  }
                {/* ----------------------- filtra per tipi di movimenti ----------------------- */}
                </Row>
              </Checkbox.Group>
            }

        </Drawer>
     </>
  )
}
