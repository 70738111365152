import React from 'react';

import { Col, Form, Row } from 'antd';

import MarketingButtonAddField from '../../../MarketingButtonAddField';
import MarketingDateField from '../../../MarketingDateField';

export default function PersonalDataFiltersActivation({ form, labelValue }) {
  
  const label = labelValue || "Data di attivazione utente";
  const listName = "activation_dates_list";
  
  return (
   <Form.List name={listName}>
      {(fields, opt) => (
        <>
          <div className='dynamic-date-wrapper mb-20' >
            {fields.map((field, index) => (
              <div className='bg-dark-02 p-15 radius-2 dynamic-date-child' key={index}>
                <MarketingDateField listName={listName} form={form} field={field} index={index} opt={opt} />
              </div>
            ))}
          </div>
          <MarketingButtonAddField onClick={() => opt.add()} label={label}/>
        </>
      )}
    </Form.List>
  );
}
