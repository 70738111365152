import React, { useContext } from 'react'

import { Row  } from 'antd'

import TableMarketingOperations from '../../tables/TableMarketingOperations'
import MarketingEmpty from '../../marketing/MarketingEmpty'
import DashboardLayout from '../../DashboardLayout'
import { Context } from '../../../helpers/Context'

export default function PageMarketingOperations() {
   
   const contextValue = useContext(Context);

  return (
     <DashboardLayout hideShops={true}>
      <Row className='p-20'>
        <div className='di-card mb-20 w-100'>
              {true ? 
                 
               //------------ aggiungi un operazione quando non ne hai ancora fatte -----------
                 <MarketingEmpty isOperation={true} contextValue={contextValue} />
               //------------ aggiungi un operazione quando non ne hai ancora fatte -----------
                 
            :
               <div>
                  <TableMarketingOperations/>
               </div>
              }
         </div>
      </Row>
     </DashboardLayout>
  )
}
