import React from 'react'

import { ExpandAltOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import { Button } from 'antd'

export default function MarketingFiltersToggleCollapse({onClick, activeKeys}) {
  return (
   <Button className='mb-10' onClick={onClick} icon={activeKeys.length === 0 ?  <ExpandAltOutlined /> : <VerticalAlignMiddleOutlined />} />
  )
}
