import React, { useContext, useRef, useState } from 'react'

import { EditOutlined, MailOutlined, PictureOutlined, } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, Input, Row, Typography} from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { CreateOperationDataContext } from '../../../../../helpers/Context';
import MarketingOperationShortcode from '../../MarketingOperationShortcode';
import MarketingUploadFile from '../../../MarketingUploadFile';
import { REGEX_HTTPS } from '../../../../../helpers/Globals';
import MarketingOperationCopyButton from '../../MarketingOperationCopyButton';
import MarketingOperationColorPicker from '../../MarketingOperationColorPicker';

export default function ChannelEmailForm() {
  
  const [text, setText] = useState('');

  const emailDescriptionRef = useRef(null);
  const emailObjectRef = useRef(null);
  const emailTitleRef = useRef(null);

  const contextValue = useContext(CreateOperationDataContext);

  const {
    onInputFocus,
  } = contextValue;

  const formPrefix = "email_data";

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 }, 
      md: { span: 10 },
      lg: { span: 12 },
      xl: { span: 8 },
      xxl: { span:6 },
    },
  };
  const formItemOrientation = "horizontal";


  return (
    <Card className='bg-dark-02'>
      
      <MarketingOperationShortcode />
      
      <Typography className="fs-16 bold mb-20">
         <MailOutlined /> Dati E-Mail
      </Typography>
        
      <Form.Item
        {...formItemLayout} 
        layout='horizontal'
        name={[formPrefix, "object"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "object"]}
            />
            Oggetto E-mail
          </div>
        }
        rules={[
          {
            required: true,
            message: "L'oggetto della mail è obbligatorio",
          },
        ]}
      >
        <Input
          ref={emailObjectRef}
          allowClear
          onFocus={() => onInputFocus(emailObjectRef, [formPrefix, "object"])}
        />
      </Form.Item>
        
      <Form.Item
        {...formItemLayout} 
        layout='horizontal'
        name={[formPrefix, "title"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "title"]}
            />
            Titolo
          </div>
        }
        rules={[
          {
            required: true,
            message: "Il titolo della mail è obbligatorio.",
          },
        ]}
      >
        <Input
          ref={emailTitleRef}
          allowClear
          onFocus={() => onInputFocus(emailTitleRef, [formPrefix, "title"])} 
        />
      </Form.Item>
        
      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "description"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "description"]}
            />
            Descrizione Completa
          </div>
        }      >
        <TextArea
          onFocus={() => onInputFocus(emailDescriptionRef, [formPrefix, "description"])}
          ref={emailDescriptionRef}
          value={text}
          maxLength={400}
          showCount
          allowClear
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout} 
        name={[formPrefix, "file"]}
        label={"Copertina"}
        valuePropName={"fileList"}
        layout={formItemOrientation}
        getValueFromEvent={(e) => e?.fileList}
      >
        <MarketingUploadFile context={contextValue} fieldName={formPrefix} />
      </Form.Item>

      <Divider>Pulsante Superiore</Divider>
      <Form.Item
        {...formItemLayout}
        layout={formItemOrientation}
        name={[formPrefix, "header_button_label"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "header_button_label"]}
            />
            Etichetta
          </div>
        }
      >
        <Input maxLength={15} showCount allowClear/>
      </Form.Item>
        
      <Form.Item
        {...formItemLayout}
        layout={formItemOrientation}
        name={[formPrefix, "header_button_link"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "header_button_link"]}
            />
            Link
          </div>
        }
        rules={[
          {
            type: 'url',
            message: "Il link deve essere un url valido.",
          },
          {
            type: 'string',
          },
          {
            pattern: REGEX_HTTPS,
            message: 'L\'URL deve iniziare con "https://"',
          },
        ]}
      >
        <Input allowClear/>
      </Form.Item>

      <Form.Item 
        {...formItemLayout}
        layout={formItemOrientation}
        label={"Colori Pulsante"}
      >
        <div className='flex gap-10 flex-wrap bg-dark-02 p-15'>
          <Form.Item
            className='mb-0'
            layout={formItemOrientation}
            label={"Sfondo"}
            name={[formPrefix, "header_button_background_color"]}
          >
            <MarketingOperationColorPicker fieldName={[formPrefix, "header_button_background_color"]}/>
          </Form.Item>
          <Form.Item
            className='mb-0'
            layout={formItemOrientation}
            label={"Testo"}
            name={[formPrefix, "header_button_text_color"]}
          >
            <MarketingOperationColorPicker fieldName={[formPrefix, "header_button_text_color"]}/>
          </Form.Item>
        </div>
      </Form.Item>

      <Divider>Pulsante Inferiore</Divider>
      <Form.Item
        {...formItemLayout}
        layout={formItemOrientation}
        name={[formPrefix, "footer_button_label"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "footer_button_label"]}
            />
            Etichetta
          </div>
        }      >
        <Input maxLength={15} showCount allowClear/>
      </Form.Item>
        
      <Form.Item
        {...formItemLayout}
        layout={formItemOrientation}
        name={[formPrefix, "footer_button_link"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "footer_button_link"]}
            />
            Link
          </div>
        }
        rules={[
          {
            type: 'url',
            message: "Il link deve essere un url valido.",
          },
          {
            type: 'string',
          },
          {
            pattern: REGEX_HTTPS,
            message: 'L\'URL deve iniziare con "https://"',
          },
        ]}
      >
        <Input allowClear/>
      </Form.Item>

      
      <Form.Item 
        {...formItemLayout}
        layout={formItemOrientation}
        label={"Colori Pulsante"}
      >
        <div className='flex gap-10 flex-wrap bg-dark-02 p-15'>
          <Form.Item
            className='mb-0'
            layout={formItemOrientation}
            label={"Sfondo"}
            name={[formPrefix, "footer_button_background_color"]}
          >
            <MarketingOperationColorPicker fieldName={[formPrefix, "footer_button_background_color"]}/>
          </Form.Item>
          <Form.Item
            className='mb-0'
            layout={formItemOrientation}
            label={"Testo"}
            name={[formPrefix, "footer_button_text_color"]}
          >
            <MarketingOperationColorPicker fieldName={[formPrefix, "footer_button_text_color"]}/>
          </Form.Item>
        </div>
      </Form.Item>

    </Card>

    
  );
}
