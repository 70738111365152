import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import * as Unicons from '@iconscout/react-unicons';

import { useNavigate } from 'react-router';
import { Button, Card, Col, Row, Skeleton, Spin, Typography} from 'antd';
import dayjs from 'dayjs';


import { ENDPOINT_PERIOD_SALES, LOCAL_ACCESS_TOKEN } from '../../helpers/Globals';
import { Context } from '../../helpers/Context';
import DiRangePicker from '../DiRangePicker';
import { ApiCall } from '../../Backoffice';
import TableCard from '../TableCard';

const { Paragraph } = Typography;

function formatEuro(number) {
  return Number(number.toFixed(2)).toLocaleString("es-ES", {minimumFractionDigits: 2});
}

function OpenedTables(props) {

  const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DDT00:00:00'));
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DDT23:59:59'));
  const [resetDate, setResetDate] = useState(0);
  
  const [publicCodeFilter,] = useState(sessionStorage.getItem("__di_public_code_filter") ? sessionStorage.getItem("__di_public_code_filter") : "")
  const [openedTableCount, setOpenedTableCount] = useState(0);
  const [openedTableEarn, setOpenedTableEarn] = useState(0);
  const [openedTable, setOpenedTable] = useState([]);
  const [coperti, setCoperti] = useState(0);

  const [loading, setLoading] = useState(true);

  const accessToken = sessionStorage.getItem(LOCAL_ACCESS_TOKEN);

  const navigate = useNavigate();

  const { currency } = useContext(Context);

  // --------- qundo c'è il reset date porto le date di default ad oggi ---------
  useEffect(()=>{

    var startDate = dayjs().format('YYYY-MM-DDT00:00:00');
    var endDate = dayjs().format('YYYY-MM-DDT23:59:59');

    setStartDate(startDate)
    setEndDate(endDate)

  }, [resetDate])
  // --------- qundo c'è il reset date porto le date di default ad oggi ---------

  //chiamata per ricevere i dati dei tavoli aperti
  useEffect(()=>{

    setLoading(true);
      
    (async () => {

      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${startDate}&end_date=${endDate}&shop=${publicCodeFilter}&opened=1`, "GET", `${accessToken}`, null)
      if (json) {
        
        var openedTable = json.sales;
        var totalOpenedTableEarn = 0;
        var coperti = 0;

        for (const i in openedTable) {
         
          //sommo i totali delle singole battute 
          for (let n in openedTable[i]["lines"]) {
            
            var line = openedTable[i]["lines"][n];

            totalOpenedTableEarn = totalOpenedTableEarn + line["amount"];
            if (line["description"] === "COPERTO") {
              coperti += line["quantity"]
            }
          }
        }
        setOpenedTableEarn(totalOpenedTableEarn);
        setOpenedTableCount(openedTable.length);
        setOpenedTable(openedTable);
        setCoperti(coperti);
        setLoading(false);
      }
    })();

  },[startDate, endDate]);

  // gestione del range date per gli scontrini chiusi 
  const handleRangeDate = (dates, dateStrings) => {

    if (dates) {
      var newStartDate = dateStrings[0].replaceAll("/", "-").replaceAll(/\s/g,'T');
      var newEndDate = dateStrings[1].replaceAll("/", "-").replaceAll(/\s/g,'T');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    } else {
      setResetDate(resetDate + 1);
    }
  };

  return (
      <>
        <div className='flex align-center gap-5 mb-20 pt- p-10 mobile-show'>
          <Unicons.UilClipboardNotes className="color-primary"/>
          <span className='fs-18'>Tavoli Aperti</span>
        </div>
        <Row gutter={[15,15]}>
          <Col span={24}>
            <div className='di-card flex space-between flex-wrap mt-10'>
              <div className='flex gap-10 align-center flex-wrap'>
                <Button
                  type='primary'
                  icon={<ArrowLeftOutlined />}
                  onClick={
                    () => {
                      sessionStorage.removeItem("__di_public_code_filter");
                      navigate(-1);
                    }
                  }
                >
                  Dashboard
                </Button>

                {/* range di date */}
                <div className='flex column align-center justify-center gap-10'>
                    <div>
                      <DiRangePicker onChange={handleRangeDate} />
                    </div>
                </div>
                {/* range di date */}
            

                <span className='fs-18 flex-wrap'>
                  <Paragraph className='mb-0 flex flex-wrap gap-5 fs-18'>
                    {openedTableCount} tavoli aperti per un totale di
                    <span className='color-primary fw-5'>
                      {formatEuro(openedTableEarn)+currency}
                    </span>
                    e
                    <span className='color-primary fw-5'>
                      {coperti} 
                    </span>
                    Coperti
                  </Paragraph>
                </span>
              </div>    
              <div className='flex align-center gap-5 py-10 mobile-hide'>
                <Unicons.UilClipboardNotes className="color-primary"/>
                <Paragraph className='fs-18 mb-0'>Tavoli Aperti</Paragraph>
              </div>
            </div>
          </Col>
        </Row>
      
        {!loading ?
          <Row className='mt-20' gutter={[15, 15]}>
              {openedTable.map((e, index)=>{
                return(
                    <Col 
                      className='p-0 animate opacity-0 fadeInUp' 
                      style={{animationDelay:index + "50ms"}}
                      key={index}
                      xs={24}
                      sm={24}
                      md={12}
                      lg={8}
                      xl={6}
                      xxl={4}
                    >
                      <TableCard table_data={e}/>
                    </Col>
                  )
                })
              }
          </Row>
        :
           <Row className='p-20' gutter={[16, 16]}>
            {Array.from({ length: 15 }).map((_, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={4}>
                <Card>
                  <Skeleton active />
                </Card>
              </Col>
            ))}
          </Row>
        }
      
      </>
  );
}

export default OpenedTables;