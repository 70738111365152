import { Form, Select } from 'antd'
import React, { useState } from 'react'

export default function MarketingNumberConditionSelect({nameValue, keyValue, initialValue, allowClear, required, date}) {
   
  return (
    <Form.Item
      className='w-100'
      name={nameValue}
      key={keyValue}
      initialValue={initialValue}
      rules={[
         {
         required: required,
         message: 'Per favore, seleziona una condizione!',
         },
      ]}
   >
      <Select
         allowClear={allowClear}
         className='min-w-150 w-100'
         options={[
            { label: date ? "Esattamente il"   : "Uguale a",    value: 'equal' },
            { label: date ? "Dopo il"   : "Maggiore di" , value: 'over' },
            { label: date ? "Prima di"  : 'Minore di', value: 'under' },
         ]}
      />
   </Form.Item>
  )
}
