import React, { useContext, useRef, useState } from 'react'

import { BellOutlined, MessageOutlined, MobileOutlined } from '@ant-design/icons';
import { Card, Form, Input, Select, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { CreateOperationDataContext } from '../../../../../helpers/Context';
import MarketingOperationShortcode from '../../MarketingOperationShortcode';
import MarketingOperationCopyButton from '../../MarketingOperationCopyButton';

export default function ChannelPushNotificationForm() {
   
  const [text, setText] = useState(''); // Stato per il contenuto della TextArea
  const pushNotificationDescriptionRef = useRef(null); 
  const pushNotificationTitleRef = useRef(null); 

  const { onInputFocus } = useContext(CreateOperationDataContext);
   
  const formPrefix = "push_notification_data";

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 }, 
      md: { span: 10 },
      lg: { span: 12 },
      xl: { span: 8 },
      xxl: { span:6 },
    },
  };
  const formItemOrientation = "horizontal";

  return (
    <Card className='bg-dark-02'>
      
      <MarketingOperationShortcode/>

      <Typography className="fs-16 bold mb-10">
        <BellOutlined /> Dati per la Notifica Push
      </Typography>

      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "title"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "title"]}
            />
            Titolo
          </div>
        }
        rules={[
          {
            required: true,
            message: "Per favore, inserisci il titolo della notifica!",
          },
        ]}
      >
        <Input
          onFocus={()=>onInputFocus(pushNotificationTitleRef, [formPrefix, "title"])}
          ref={pushNotificationTitleRef}
          maxLength={30}
          showCount
          allowClear
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "description"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "description"]}
            />
            Descrizione
          </div>
        }
        rules={[
          {
            required: true,
            message: "Per favore, inserisci il testo della notifica!",
          },
        ]}
      >
        <TextArea
            onFocus={()=>onInputFocus(pushNotificationDescriptionRef, [formPrefix, "description"])}
            ref={pushNotificationDescriptionRef}
            maxLength={80}
            value={text}
            showCount
            allowClear
         />
      </Form.Item>

      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        label={"Pagina di Atterraggio"}
        name={[formPrefix, "page"]}
        key={[formPrefix, "page"]}
      >
        <Select
          placeholder="Seleziona la pagina di atterraggio"
          className="w-100 min-w-150"
          allowClear
          options={[
            { value: 1, label: "Chi Siamo" },
            { value: 2, label: "Coupons" },
            { value: 3, label: "Registrati" },
            { value: 4, label: "Promozione 2025" },
          ]}
        />
      </Form.Item>

    </Card>
  );
}
