
import { useContext, useEffect, useState  } from 'react';

import PageFastStats from './components/pages/PageFastStats';
import { Context } from './helpers/Context';

function App() {
  
  const [render, setRender] = useState(false);
  const { accessToken } = useContext(Context);


  useEffect(() => {
    if (!accessToken) return;
    setRender(true);
  },[accessToken])


  return(
    <> 
    {
      render ? 
        <>
            <PageFastStats />
        </>  
      : 
        ""
    }
    </>
  )
}

export default App;
