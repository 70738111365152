import React, { useContext } from 'react'

import { LayoutOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';

import { Context } from '../helpers/Context';

export default function DiEditLayoutButton() {
  const {
      refEditLayout,
      toggleLayout,
      editLayout,
   } = useContext(Context);
   
  return (
    <Popover title="Modifica il layout">
      <Button
        ref={refEditLayout}
        shape='circle'
        icon={<LayoutOutlined />}
        type={editLayout ? "primary" : ""}
        onClick={toggleLayout}
       />
    </Popover>
  )
}
