import React, { useContext, useEffect, useState } from 'react'

import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { CreateOperationDataContext } from '../../../helpers/Context'

const { Text } = Typography;

export default function MarketingOperationCopyButton({ fieldName }) {

   const { form, formChanged } = useContext(CreateOperationDataContext)
   
   const [value, setvalue] = useState(form.getFieldValue(fieldName))

   useEffect(() => {
      const valueToCopy = form.getFieldValue(fieldName);
      if (valueToCopy) {
         setvalue(valueToCopy)
         return
      };
      setvalue(null);
   }, [formChanged])

   return (
      <>
         {value &&
            <Text
               type="secondary"
               copyable={{
                  text: value,
                  icon: [<CopyOutlined className='color-grey' key="copy-icon" />, <CheckCircleOutlined key="copied-icon" />],
                  tooltips: ['Copia testo', 'Copiato!'],
               }}
            />
         }
      </>
  )
}
