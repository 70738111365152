import React, { useContext } from 'react'

import { CodeOutlined, CreditCardOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Collapse, Typography } from 'antd'

import { CreateOperationDataContext } from '../../../helpers/Context';

export default function MarketingOperationShortcode() {
   
  const { insertShortcode } = useContext(CreateOperationDataContext);
  
  const shortcodeList = [
    {
      label: "Nome",
      value: "{user_name}",
      icon: <UserOutlined/>
    },
    {
      label: "Cognome",
      value: "{user_surname}",
      icon: <UserOutlined/>
    },
    {
      label: "Età",
      value: "{user_age}",
      icon: <UserOutlined/>
    },
    {
      label: "Saldo Fidelity",
      value: "{user_fidelity_amount}",
      icon: <CreditCardOutlined/>
    },
  ]

  return (
    <div className='mb-20'>
      <Collapse
        bordered={false}
        expandIconPosition='end'
        size='large'
        items={[{
          key: '1',
          label: 
             <>
              <Typography className="">
                <CodeOutlined/> Shortcodes dati Utente
              </Typography>
             </>
          ,
          children:
             <>
              <Typography className="fs-12 mb-10">
                Utilizza i seguenti shortcode per inserire nei campi di testo i dati dell'utente, verranno visualizzati nel messaggio finale.
              </Typography>
              <div className='flex gap-5 flex-wrap'>
                {shortcodeList.map((item, index) => (
                  <Button key={index} size='small' icon={item.icon} onClick={() => insertShortcode(item.value)}>{item.label}</Button>
                ))}
              </div>
             </>
          ,
        }]}
      />
    </div>
  )
}
