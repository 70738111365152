import React, { useState } from 'react';

import { Form, Select, DatePicker, Checkbox, InputNumber, Row, Col, Radio, Card } from 'antd';
import { InteractionOutlined } from '@ant-design/icons';

import DiRangePicker from '../../DiRangePicker';

const { Option } = Select;

export default function MarketingOperationCreateSchedule({setMonthlyOption, monthlyOption, setRepeatFrequency, repeatFrequency, setSelectedDays, selectedDays}) {
   
   const daysOfWeek = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato','Domenica'];
   const weeksOfMonth = ['Prima', 'Seconda', 'Terza', 'Quarta', 'Ultima'];

   const handleFrequencyChange = (value) => {
      setRepeatFrequency(value);
      setSelectedDays([]);
   };

   return (
      <Form layout="vertical">
         
         <Card className='mb-20'>
            {/* -------------— Frequenza di ripetizione -------------— */}
            <Form.Item
               className='m-0'
               label={
                  <span className='flex gap-5'><InteractionOutlined />Scegli quando inviare </span>
               }
            >
               <Select className='min-w-150' value={repeatFrequency} onChange={handleFrequencyChange}>
                  <Option value="now">Invia ora</Option>
                  <Option value="daily">Ogni giorno</Option>
                  <Option value="weekly">Ogni settimana</Option>
                  <Option value="monthly">Ogni mese</Option>
                  <Option value="yearly">Ogni anno</Option>
               </Select>
            </Form.Item>
            {/* -------------— Frequenza di ripetizione -------------— */}
   
            {/* -------------— Selezione giorni specifici della settimana per "Ogni settimana" -------------— */}
            {repeatFrequency === 'weekly' && (
               <Form.Item className='mb-0 mt-20' label="Giorni della settimana">
                  <Checkbox.Group value={selectedDays} onChange={setSelectedDays}>
                     <Row gutter={[10,10]}>
                        {daysOfWeek.map((day, index) => (
                           <Col key={index}>
                              <Checkbox value={day}>{day}</Checkbox>
                           </Col>
                        ))}
                     </Row>
                  </Checkbox.Group>
               </Form.Item>
            )}
            {/* -------------— Selezione giorni specifici della settimana per "Ogni settimana" -------------— */}
   
            {/* ------------- Opzioni per la ripetizione mensile ------------- */}
            {repeatFrequency === 'monthly' && (
               <Form.Item className='mb-0 mt-20' label="Ripeti ogni mese">
                  <Radio.Group onChange={(e) => setMonthlyOption(e.target.value)} value={monthlyOption} required>
                     <Radio value="specificDay">Giorno specifico del mese</Radio>
                     <Radio value="nthWeekday">In un giorno specifico (es. secondo venerdì)</Radio>
                  </Radio.Group>
   
                  {/* Giorno specifico del mese */}
                  {monthlyOption === 'specificDay' && (
                     <Form.Item className='mb-0 mt-20' label="Giorno del mese" required>
                        <InputNumber min={1} max={31} placeholder="Es. 15" />
                     </Form.Item>
                  )}
                  {/* Giorno specifico del mese */}
   
                  {/* Selezione di settimana e giorno per il mese */}
                  {monthlyOption === 'nthWeekday' && (
                     <Row gutter={8}>
                        <Col span={12}>
                           <Form.Item 
                              label="Settimana" 
                              className='mb-0 mt-20'
                              name="weekOfMonth" 
                              rules={[{ required: true, message: 'Seleziona una settimana' }]}
                           >
                              <Select className='min-w-150' placeholder="Seleziona una settimana">
                                 {weeksOfMonth.map((week, index) => (
                                    <Option key={index} value={index + 1}>
                                       {week}
                                    </Option>
                                 ))}
                              </Select>
                           </Form.Item>
                        </Col>
                        <Col span={12}>
                           <Form.Item 
                              label="Giorno della settimana" 
                              className='mb-0 mt-20'
                              name="dayOfWeek" 
                              rules={[{ required: true, message: 'Seleziona un giorno della settimana' }]}
                           >
                              <Select className='min-w-150' placeholder="Seleziona un giorno">
                                 {daysOfWeek.map((day, index) => (
                                    <Option key={index} value={day}>
                                       {day}
                                    </Option>
                                 ))}
                              </Select>
                           </Form.Item>
                        </Col>
                     </Row>
                  )}
                  {/* Selezione di settimana e giorno per il mese */}
   
               </Form.Item>
            )}
            {/* ------------- Opzioni per la ripetizione mensile ------------- */}
   
            {/* ------------- Giorno specifico dell'anno per "Ogni anno" ------------- */}
            {repeatFrequency === 'yearly' && (
               <Form.Item className='mb-0 mt-20' label="Data specifica">
                  <DatePicker className='w-100 mw-sm' picker="date" placeholder="Seleziona giorno e mese" />
               </Form.Item>
            )}
            {/* ------------- Giorno specifico dell'anno per "Ogni anno" ------------- */}
         </Card>

         {/* ------------- Intervallo di date ------------- */}
         {repeatFrequency !== 'now' &&
            <Card>
               <Form.Item label="Range di attivazione ricorrenza">
                  <DiRangePicker
                     onChange={(e) => console.log(e)}
                     enableAllDate
                     hidePreset 
                     hideTime
                     outlined
                  />
               </Form.Item>
            </Card>
         }
         {/* ------------- Intervallo di date ------------- */}

      </Form>
   );
}