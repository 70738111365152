import React, { useContext, useMemo } from 'react';

import { LOCAL_LAYOUT_TABLE_RESTAURANT, LOCAL_LAYOUT_TABLE_RETAIL } from '../../helpers/Globals';
import DisplaySelledCategories from '../data_display/DisplaySelledCategories';
import DisplayWaiterProduction from '../data_display/DisplayWaiterProduction';
import DisplayCorrectionList from '../data_display/DisplayCorrectionList';
import DisplayOpenedTables from '../data_display/DisplayOpenedTables';
import DisplayReceiptData from '../data_display/DisplayReceiptData';
import DiLayoutEditorWrapper from '../DiLayoutEditorWrapper';
import DashboardLayout from '../DashboardLayout';
import { Context } from '../../helpers/Context';
import FiltersDrawer from '../FiltersDrawer';
import NoData from '../NoData';

export default function PageTabulations() {

   const {isRestaurant} = useContext(Context)
   
   const tableWaiterProductionKey = "tableWaiterProductionKey";
   const tableSelledCategoryKey = "tableSelledCategoriesKey";
   const tableCorrectionListKey = "tableCorrectionListKey";
   const tableOpenedTablesKey = "tableOpenedTablesKey";
   const tableReceiptDataKey = "tableReceiptDataKey";

   const minHeight = 13;

   const restaurantLayout =
      {
         lg: [
            { i: tableSelledCategoryKey,     x: 0, y: 0, w: 6, h: minHeight, minH: minHeight },
            { i: tableWaiterProductionKey,   x: 6, y: 0, w: 6, h: minHeight, minH: minHeight },
            { i: tableOpenedTablesKey,       x: 0, y: 10, w: 6, h: minHeight, minH: minHeight },
            { i: tableReceiptDataKey,        x: 6, y: 10, w: 6, h: minHeight, minH: minHeight },
            { i: tableCorrectionListKey,     x: 0, y: 20, w: 12, h: 15, minH: 15 },
         ],
         md: [
            { i: tableSelledCategoryKey,     x: 0, y: 0, w: 4, h: minHeight, minH: minHeight },
            { i: tableWaiterProductionKey,   x: 6, y: 0, w: 4, h: minHeight, minH: minHeight },
            { i: tableOpenedTablesKey,       x: 0, y: 10, w: 4, h: minHeight, minH: minHeight },
            { i: tableReceiptDataKey,        x: 6, y: 10, w: 4, h: minHeight, minH: minHeight },
            { i: tableCorrectionListKey,     x: 0, y: 20, w: 12, h: 15, minH: 15 },
         ],
         sm: [
            { i: tableSelledCategoryKey,     x: 0, y: 0, w: 12, h: minHeight, minH: minHeight },
            { i: tableWaiterProductionKey,   x: 0, y: 2, w: 12, h: minHeight, minH: minHeight },
            { i: tableOpenedTablesKey,       x: 0, y: 4, w: 12, h: minHeight, minH: minHeight },
            { i: tableReceiptDataKey,        x: 0, y: 0, w: 12, h: minHeight, minH: minHeight },
            { i: tableCorrectionListKey,     x: 0, y: 20, w: 12, h: 15, minH: 15 },

         ],
         xs: [
            { i: tableSelledCategoryKey,     x: 0, y: 0, w: 12, h: minHeight, minH: minHeight },
            { i: tableWaiterProductionKey,   x: 0, y: 2, w: 12, h: minHeight, minH: minHeight },
            { i: tableOpenedTablesKey,       x: 0, y: 4, w: 12, h: minHeight, minH: minHeight },
            { i: tableReceiptDataKey,        x: 0, y: 0, w: 12, h: minHeight, minH: minHeight },
            { i: tableCorrectionListKey,     x: 0, y: 20, w: 12, h: 15, minH: 15 },

         ],
         xxs: [
            { i: tableSelledCategoryKey,     x: 0, y: 0, w: 12, h: minHeight, minH: minHeight },
            { i: tableWaiterProductionKey,   x: 0, y: 2, w: 12, h: minHeight, minH: minHeight },
            { i: tableOpenedTablesKey,       x: 0, y: 4, w: 12, h: minHeight, minH: minHeight },
            { i: tableReceiptDataKey,        x: 0, y: 0, w: 12, h: minHeight, minH: minHeight },
            { i: tableCorrectionListKey,     x: 0, y: 20, w: 12, h: 15, minH: 15 },

         ],
      };

   const retailerLayout = {};

   const tabulationLayout = useMemo(() => isRestaurant ? restaurantLayout : retailerLayout, [isRestaurant]);
   
   return (
      <DashboardLayout>
         <div>
            {/* Rendering dei componenti associati */}
            {isRestaurant ?
                  <DiLayoutEditorWrapper
                     layoutName={isRestaurant ? LOCAL_LAYOUT_TABLE_RESTAURANT : LOCAL_LAYOUT_TABLE_RETAIL}
                     defaultLayout={tabulationLayout}
                  >
                     <div key={tableSelledCategoryKey}>
                        <DisplaySelledCategories />
                     </div>
                     <div key={tableWaiterProductionKey}>
                        <DisplayWaiterProduction />
                     </div>
                     <div key={tableOpenedTablesKey}>
                        <DisplayOpenedTables />
                     </div>
                     <div key={tableReceiptDataKey}>
                        <DisplayReceiptData />
                     </div>
                     <div key={tableCorrectionListKey}>
                        <DisplayCorrectionList />
                     </div>
                  </DiLayoutEditorWrapper>
               :
                  <NoData/>
            }
         </div>


         {/* ------- floating buttons della pagina -------  */}
            
            <FiltersDrawer />
            
         {/* ------- floating buttons della pagina -------  */}
         
      </DashboardLayout>
   );
}
