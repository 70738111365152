import React, { useContext, useState } from 'react'

import Icon, { BellOutlined, CommentOutlined, EditOutlined, GiftOutlined, MailOutlined, MessageOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Card, Checkbox, Col, ConfigProvider, Divider, Row, Tabs, Typography } from 'antd';

import ChannelPushNotificationContent from './channels/channel_push_notification/ChannelPushNotificationContent.js';
import ChannelWhatsAppContent from './channels/channel_whatsapp/ChannelWhatsAppContent.js';
import ChannelCouponContent from './channels/channel_coupon/ChannelCouponContent.js';
import ChannelEmailContent from './channels/channel_email/ChannelEmailContent.js';
import ChannelSmsContent from './channels/channel_sms/ChannelSmsContent.js';
import { CreateOperationDataContext } from '../../../helpers/Context.js';

export default function MarketingOperationCreateSelectChannel({ setSelectedValues, selectedValues }) {
       
   const {
      colorPushNotification,
      colorWhatsApp,
      colorCoupons,
      colorEmail,
      colorSms,
   } = useContext(CreateOperationDataContext)
   

   const options = [
      {
         label: 'E-mail',
         value: 'email',
         key: 'email',
         icon: <MailOutlined />,
         color:colorEmail,
         className:"operation-email",
         users:2,
         children: (
             <ConfigProvider
               theme={{
                  token: {
                     colorPrimary: colorEmail,
                  }
               }}
            >
               <div className='di-tab-channel-content'>
                  <ChannelEmailContent/>
               </div>
            </ConfigProvider>
         ),
      },
      {
         label: 'SMS',
         value: 'sms',
         key: 'sms',
         icon: <CommentOutlined />,
         color:colorSms,
         className:"operation-sms",
         users:3,
         children: (
           <ConfigProvider
               theme={{
                  token: {
                     colorPrimary: colorSms,
                  }
               }}
            >
               <div className='di-tab-channel-content'>
                  <ChannelSmsContent/>
               </div>
            </ConfigProvider>
         ),
      },
      {
         label: 'Notifica Push',
         value: 'push-notification',
         key: 'push-notification',
         className: "operation-push-notification",
         color:colorPushNotification,
         icon:<BellOutlined/>,
         users:10,
         children: (
             <ConfigProvider
               theme={{
                  token: {
                     colorPrimary: colorPushNotification,
                  }
               }}
            >
               <div className='di-tab-channel-content'>
                  <ChannelPushNotificationContent/>
               </div>
            </ConfigProvider>
         ),
      },
      {
         label: 'Coupon',
         value: 'coupon',
         key: 'coupon',
         className: "operation-coupons",
         color:colorCoupons,
         icon:<GiftOutlined/>,
         users:24,
         children: (
             <ConfigProvider
               theme={{
                  token: {
                     colorPrimary: colorCoupons,
                  }
               }}
            >
               <div className='di-tab-channel-content'>
                  <ChannelCouponContent/>
               </div>
            </ConfigProvider>
         ),
      },
      {
         label: 'WhatsApp',
         value: 'whatsapp',
         key: 'whatsapp',
         className: "operation-whatsapp",
         color:colorWhatsApp,
         icon:<WhatsAppOutlined/>,
         users:2,
         children: (
            <ConfigProvider
               theme={{
                  token: {
                     colorPrimary: colorWhatsApp,
                  }
               }}
            >
               <div className='di-tab-channel-content'>
                  <ChannelWhatsAppContent/>
               </div>
           </ConfigProvider>
         ),
      },
   ];

   // Funzione per gestire la selezione/deselezione delle checkbox
   const handleCheckboxChange = (e) => {
      const { value, checked } = e.target;

      // Trova l'oggetto corrispondente nel `options` usando `value`
      const option = options.find(opt => opt.value === value);

      if (checked && option) {
         setSelectedValues((prevSelected) => [...prevSelected, option]);
      } else {
         setSelectedValues((prevSelected) =>
         prevSelected.filter((item) => item.value !== value)
         );
      }
   };


  return (
     <div className='di-operation-wizard-wrapper'>
        
         {/* ------------- scelta dei canali di operazione -------------  */}
            <Row className='mt-50'>
                  <div className='flex gap-20 flex-wrap' >
                     {options.map(option => (
                        <ConfigProvider
                           theme={{
                              token: {
                                 colorPrimary: option.color,
                              }
                           }}
                        >
                           <Card key={option.value} bordered={false} className={`operation-chekbox-wrapper ${option.className}`}>
                              <Checkbox
                                 className='flex align-center gap-5'
                                 onChange={handleCheckboxChange}
                                 value={option.value}
                                 checked={selectedValues.some(selected => selected.value === option.value)}
                              >
                                 <div className='flex gap-10 fs-15'>
                                    {option.icon}
                                    {`${option.label} (${option.users})`}
                                 </div>
                              </Checkbox>
                           </Card>
                        </ConfigProvider>
                     ))}
                  </div>
            </Row>
         {/* ------------- scelta dei canali di operazione -------------  */}
        
        {selectedValues.length > 0 &&
            <div  className='mt-50'>
              <Divider>
               <div className='flex align-center'>
                  <EditOutlined className='color-primary' />
                  <Typography>Compila i campi per ogni operazione</Typography>
               </div>
              </Divider>
            </div>
        }
        
         {/* ------------- tabs con le impostazioni per ogni operazione -------------  */}
         {selectedValues.length > 0 &&
            <div className='di-operations-group-tabs'>
               <Tabs
                  items={selectedValues}
                  defaultActiveKey="1"
                  className='mt-20'
                  size="large"
                  type="card"
               />
            </div>
         }
        {/* ------------- tabs con le impostazioni per ogni operazione -------------  */}
        
     </div>
  )
}
