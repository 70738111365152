import React from 'react'

import { Form, Input, Button, Space, Select, Col, Row, Typography  } from 'antd';
import MarketingStringConditionSelect from '../../../MarketingStringConditionSelect';
import MarketingRemoveFieldIcon from '../../../MarketingRemoveFieldIcon';
import MarketingButtonAddField from '../../../MarketingButtonAddField';
import { REGEX_ONLY_LETTERS } from '../../../../../helpers/Globals';


export default function PersonalDataFiltersFormSurname({ form, labelValue }) {
  
  const label = labelValue || "Nome";

 return (
      <Form.List
        name="names_list"
        initialValue={[]} // Aggiungi un elemento iniziale vuoto
      >
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[10,10]}  className='mb-20'>
              {fields.map(({ key, name, ...restField }) => (
                <Col
                  xs={24}
                  md={12}
                  key={key}
                  className='flex relative'
                >
                  {/* Campo Condizione */}
                 <Row gutter={[10,10]} className='w-100 di-filters-group-wrapper'>
                    <Col xs={24} sm={12} >
                        <Typography className='mb-5 marketing-field-label'>Confronto</Typography>
                        <MarketingStringConditionSelect nameValue={[name, 'condition']} initialValue={"contains"}/>
                      </Col>
                     {/* Campo Valore */}
                    <Col xs={24} sm={12} >
                      <Typography className='mb-5 marketing-field-label'>{label}</Typography>
                        <Form.Item
                          {...restField}
                          className='w-100'
                          name={[name, 'value']}
                          key={[key, 'value']}
                          rules={[
                            {
                              required: true,
                              message: `Per favore, inserisci un ${label.toLowerCase()}!`,
                            },
                            {
                              pattern: REGEX_ONLY_LETTERS, // Espressione regolare per lettere (sia maiuscole che minuscole)
                              message: `Il ${label.toLowerCase()} può contenere solo lettere`,
                            },
                          ]}
                        >
                          <Input placeholder={label} />
                        </Form.Item>
                     </Col>
                 </Row>
  
                  {/* Pulsante per rimuovere il campo */}
                  <MarketingRemoveFieldIcon onClick={() => remove(name)}/>
  
                </Col>
              ))}
            </Row>
         
            <MarketingButtonAddField
              onClick={() => add()} 
              label={label}
            />
          </>
        )}
      </Form.List>
  );
}
