import React, { useContext, useMemo } from 'react'

import { LOCAL_LAYOUT_FAST_STATS_RESTAURANT, LOCAL_LAYOUT_FAST_STATS_RETAIL } from '../../helpers/Globals'
import DisplayFastDailyAverageReceipt from '../data_display/fast_stats/DisplayFastDailyAverageReceipt'
import DisplayFastDailyCorrections from '../data_display/fast_stats/DisplayFastDailyCorrections'
import DisplayFastDailyOpenedTable from '../data_display/fast_stats/DisplayFastDailyOpenedTable'
import DisplayFastDailyCustomer from '../data_display/fast_stats/DisplayFastDailyCustomer'
import DisplayFastDailyReceipt from '../data_display/fast_stats/DisplayFastDailyReceipt'
import DisplayFastDailyEarn from '../data_display/fast_stats/DisplayFastDailyEarn'
import DiLayoutEditorWrapper from '../DiLayoutEditorWrapper'
import DashboardLayout from '../DashboardLayout'
import { Context } from '../../helpers/Context'
import FiltersDrawer from '../FiltersDrawer';

export default function PageFastStats() {
  
  const { isRestaurant } = useContext(Context);
  
  const fastDailyAverageReceiptKey = "fastDailyAverageReceiptKey";
  const fastDailyOpenedTableKey = "fastDailyOpenedTableKey";
  const fastDailyCorrectionsKey = "fastDailyCorrectionsKey";
  const fastDailyCustomerKey = "fastDailyCustomerKey";
  const fastDailyReceiptKey = "fastDailyReceiptKey";
  const fastDailyEarnKey = "fastDailyEarnKey";

  const minHeight = 6;
  const minWidth = 4;

  const restaurantLayout =
    {
      lg: [
        { i: fastDailyEarnKey,            x: 0, y: 0,  w: 6,  h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyReceiptKey,         x: 0, y: 0,  w: 6,  h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyCustomerKey,        x: 6, y: 10, w: 6,  h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyOpenedTableKey,     x: 6, y: 10, w: 6,  h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 0, y: 20, w: 6,  h: 8,         minH: 8,         minW: minWidth },
        { i: fastDailyCorrectionsKey,     x: 6, y: 20, w: 6,  h: 8,         minH: 8,         minW: minWidth },
      ],
      md: [
        { i: fastDailyEarnKey,            x: 0, y: 0,  w: 4,  h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyCustomerKey,        x: 6, y: 0,  w: 4,  h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyReceiptKey,         x: 0, y: 10, w: 4,  h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyOpenedTableKey,     x: 6, y: 10, w: 4,  h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 0, y: 20, w: 4,  h: 8,         minH: 8,         minW: minWidth },
        { i: fastDailyCorrectionsKey,     x: 6, y: 20, w: 4,  h: 8,         minH: 8,         minW: minWidth },
      ],
      sm: [
        { i: fastDailyEarnKey,            x: 0, y: 0,  w: 12, h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyCustomerKey,        x: 0, y: 10, w: 12, h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyReceiptKey,         x: 0, y: 30, w: 12, h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyOpenedTableKey,     x: 0, y: 40, w: 12, h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 0, y: 50, w: 12, h: 8,         minH: 8,         minW: minWidth },
        { i: fastDailyCorrectionsKey,     x: 0, y: 60, w: 12, h: 8,         minH: 8,         minW: minWidth },
      ],
      xs: [
        { i: fastDailyEarnKey,            x: 0, y: 0,  w: 12, h: 7, minH: 7, minW: minWidth },
        { i: fastDailyCustomerKey,        x: 0, y: 10, w: 12, h: 7, minH: 7, minW: minWidth },
        { i: fastDailyReceiptKey,         x: 0, y: 30, w: 12, h: 7, minH: 7, minW: minWidth },
        { i: fastDailyOpenedTableKey,     x: 0, y: 40, w: 12, h: 7, minH: 7, minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 0, y: 50, w: 12, h: 7, minH: 7, minW: minWidth },
        { i: fastDailyCorrectionsKey,     x: 0, y: 60, w: 12, h: 10, minH: 10, minW: minWidth },
      ],
      xxs: [
        { i: fastDailyEarnKey,            x: 0, y: 0,  w: 12, h: 9, minH: 9, minW: minWidth },
        { i: fastDailyCustomerKey,        x: 0, y: 10, w: 12, h: 9, minH: 9, minW: minWidth },
        { i: fastDailyReceiptKey,         x: 0, y: 30, w: 12, h: 9, minH: 9, minW: minWidth },
        { i: fastDailyOpenedTableKey,     x: 0, y: 40, w: 12, h: 9, minH: 9, minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 0, y: 50, w: 12, h: 9, minH: 9, minW: minWidth },
        { i: fastDailyCorrectionsKey,     x: 0, y: 60, w: 12, h: 10, minH: 10, minW: minWidth },
      ],
    };

  const retailerLayout =
    {
      lg: [
        { i: fastDailyEarnKey,            x: 0,  y: 0,   w: 6,   h: minHeight, minH: minHeight,  minW: minWidth },
        { i: fastDailyReceiptKey,         x: 10, y: 0,   w: 6,   h: minHeight, minH: minHeight,  minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 6,  y: 10,  w: 24,  h: 7,         minH: 7,          minW: minWidth },
      ],
      md: [
        { i: fastDailyEarnKey,            x: 0,  y: 0,   w: 4,   h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyReceiptKey,         x: 10, y: 0,   w: 4,   h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 6,  y: 10,  w: 24,  h: 8,         minH: 8,         minW: minWidth },
      ],
      sm: [
        { i: fastDailyEarnKey,            x: 0, y: 0, w: 12, h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyReceiptKey,         x: 0, y: 0, w: 12, h: minHeight, minH: minHeight, minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 0, y: 0, w: 24, h: 7,         minH: 7,         minW: minWidth },
      ],
      xs: [
        { i: fastDailyEarnKey,            x: 0, y: 0, w: 12, h: 7,         minH: 7,         minW: minWidth },
        { i: fastDailyReceiptKey,         x: 0, y: 0, w: 12, h: 7,         minH: 7,         minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 0, y: 0, w: 24, h: 7,         minH: 7,         minW: minWidth },
      ],
      xxs: [
        { i: fastDailyEarnKey,            x: 0, y: 0, w: 12, h: 9,         minH: 9,         minW: minWidth },
        { i: fastDailyReceiptKey,         x: 0, y: 4, w: 12, h: 9,         minH: 9,         minW: minWidth },
        { i: fastDailyAverageReceiptKey,  x: 0, y: 0, w: 24, h: 9,         minH: 9,         minW: minWidth },
      ],
    };

  const fastStatsLayout = useMemo(() => isRestaurant ? restaurantLayout : retailerLayout, [isRestaurant]);
  

  return (
    <DashboardLayout>
        <div>
          <DiLayoutEditorWrapper
            layoutName={isRestaurant ? LOCAL_LAYOUT_FAST_STATS_RESTAURANT : LOCAL_LAYOUT_FAST_STATS_RETAIL }
            defaultLayout={fastStatsLayout}
          >
              <div key={fastDailyEarnKey}>
                <DisplayFastDailyEarn/>
              </div>
              <div key={fastDailyReceiptKey}>
                <DisplayFastDailyReceipt/>
              </div>
              <div key={fastDailyAverageReceiptKey}>
                <DisplayFastDailyAverageReceipt/>
              </div>

              {/* ---------- componenti solo per i clienti bacco ----------- */}
              {isRestaurant &&
                  <div key={fastDailyCustomerKey}>
                      <DisplayFastDailyCustomer/>
                  </div>
              }
              {isRestaurant &&
                  <div key={fastDailyOpenedTableKey}>
                      <DisplayFastDailyOpenedTable/>
                  </div>
              }
              {isRestaurant &&
                  <div key={fastDailyCorrectionsKey}>
                      <DisplayFastDailyCorrections/>
                  </div>
              }
              {/* ---------- componenti solo per i clienti bacco ----------- */}

          </DiLayoutEditorWrapper>
        </div>
      
        {/* ------- floating buttons della pagina -------  */}
          
          <FiltersDrawer />
          
        {/* ------- floating buttons della pagina -------  */}
      </DashboardLayout>
  )
}
