import React from 'react'

export default function IconDiCake() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="1em"
      height="1em"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
   >
      <rect x="12" y="30" width="40" height="20" rx="4" ry="4" />
      <path d="M12 40c10 4 20-4 30 0s20 4 10 0" />
      <rect x="18" y="18" width="4" height="12" rx="2" ry="2" />
      <rect x="30" y="18" width="4" height="12" rx="2" ry="2" />
      <rect x="42" y="18" width="4" height="12" rx="2" ry="2" />
      <path d="M20 14c0 2-2 4-2 4s-2-2-2-4 2-4 2-4 2 2 2 4z" />
      <path d="M32 14c0 2-2 4-2 4s-2-2-2-4 2-4 2-4 2 2 2 4z" />
      <path d="M44 14c0 2-2 4-2 4s-2-2-2-4 2-4 2-4 2 2 2 4z" />
   </svg>
  )
}
