import React from 'react'

import { Form, Button, Col, Row, Typography, InputNumber, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import MarketingRemoveFieldIcon from '../../../MarketingRemoveFieldIcon';
import MarketingNumberConditionSelect from '../../../MarketingNumberConditionSelect';
import MarketingButtonAddField from '../../../MarketingButtonAddField';
import MarketingDynamicDatesCollapse from '../../../MarketingDynamicDatesCollapse';
import MarketingDateField from '../../../MarketingDateField';


export default function FidelityFiltersCampaigns({ form, labelValue }) {
  
  const label = labelValue || "Campagna, attività o saldo";

 return (
      <Form.List name="campaigns_list" initialValue={[]}>
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[10, 10]} className='mb-20'>
              {fields.map(({ key, name, ...restField }) => (
                <Col
                  xs={24}
                  md={12}
                  lg={8}
                  key={key}
                  className="flex relative"
                >
                  <Row gutter={[10, 10]} className="w-100 di-filters-group-wrapper">
                    {/* Campo per selezionare la campagna */}
                    <Col span={24}>
                      <Typography className="mb-5 marketing-field-label">
                        Campagna
                      </Typography>
                      <Form.Item
                        {...restField}
                        className="w-100 min-w-150"
                        name={[name, "campaign"]}
                        key={[key, "campaign"]}
                      >
                        <Select
                          placeholder="Seleziona Campagna"
                          options={[
                            { label: "Estate 2020", value: "1" },
                            { label: "Inverno 2022", value: "2" },
                            { label: "Punti 2024", value: "3" },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    {/* Campo per selezionare l'attività */}
                    <Col span={24}>
                      <Typography className="mb-5 marketing-field-label">
                        Attività
                      </Typography>
                      <Form.Item
                        {...restField}
                        className="w-100 min-w-150"
                        name={[name, "activity"]}
                        key={[key, "activity"]}
                      >
                        <Select
                          placeholder="Seleziona Attività"
                          options={[
                            { label: "Punti", value: "1" },
                            { label: "Prepagato", value: "2" },
                          ]}
                        />
                      </Form.Item>
                    </Col>

                    {/* Campo per definire il saldo con operatore */}
                    <Col span={24}>
                      <Typography className="mb-5 marketing-field-label">
                        Saldo
                      </Typography>
                      <Row gutter={[10, 10]}>
                        <Col xs={24} sm={12} lg={24} xl={24} xxl={12}>
                          <MarketingNumberConditionSelect
                            nameValue={[name, 'condition']}
                            keyValue={[key, 'condition']}
                            allowClear
                          />
                        </Col>
                        <Col xs={24} sm={12} lg={24} xl={24} xxl={12}>
                          <Form.Item
                            {...restField}
                            className="w-100"
                            name={[name, "activity_amount"]}
                            key={[key, "activity_amount"]}
                            rules={[
                              {
                                required: true,
                                message: "Per favore, inserisci il saldo!",
                              },
                              {
                                type: "number",
                                message: "Il saldo deve essere un numero!",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="Inserisci Saldo"
                              className="w-100"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    {/* Campo per la selezione delle date */}
                    <MarketingDynamicDatesCollapse
                      label={"Saldo Fidelity"}
                      child={
                          <Form.Item>
                            <Form.List name={[name, 'fidelity_amount_dates']}>
                              {(subFields, subOpt) => (
                                <>
                                  <div className='dynamic-date-wrapper justify-center'>
                                      {subFields.map((subField, index) => (
                                        <div className="radius-2 p-10 bg-dark-02 dynamic-date-child mw-sm" key={index}>
                                          <MarketingDateField listName={[name, 'fidelity_amount_dates']} field={subField} index={index} opt={subOpt} />
                                        </div>
                                      ))}
                                  </div>
                                  <div className="mt-20"><MarketingButtonAddField onClick={() => subOpt.add()} label={"Data"}/></div>
                                </>
                              )}
                            </Form.List>
                        </Form.Item>
                      }
                    />
                      
                  </Row>


                  {/* Pulsante per rimuovere il campo */}
                  <MarketingRemoveFieldIcon onClick={() => remove(name)} />
                </Col>
              ))}
            </Row>
         
            <MarketingButtonAddField
              onClick={() => add()} 
              label={label}
            />
         
          </>
        )}
      </Form.List>
  );
}
