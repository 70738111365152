import { Col, Row, Typography } from 'antd'
import React from 'react'
import ChannelPushNotificationForm from './ChannelPushNotificationForm'
import ChannelPushNotificationPreview from './ChannelPushNotificationPreview'

export default function ChannelPushNotificationContent() {
  return (
    <div className='operations-group-push-notification'>
       <Row gutter={[20,20]} className='mt-20'>
           <Col xs={24} md={14}>
              <ChannelPushNotificationForm/>
           </Col>
           <Col xs={24} md={10} >
               <Typography className='fs-18 bold mb-10 text-center'>
                  Preview
               </Typography>
               <ChannelPushNotificationPreview/>
           </Col>
        </Row>
    </div>
  )
}
