import React, { useContext, useRef, useState } from 'react'

import { BellOutlined, EditOutlined, LinkOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Card, Form, Input, Modal, Typography,  } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { CreateOperationDataContext } from '../../../../../helpers/Context';
import MarketingOperationShortcode from '../../MarketingOperationShortcode';
import MarketingUploadFile from '../../../MarketingUploadFile';
import { REGEX_HTTPS } from '../../../../../helpers/Globals';
import MarketingOperationCopyButton from '../../MarketingOperationCopyButton';

export default function ChannelWhatsAppForm() {
   
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [text, setText] = useState('');

  const whatsappDescriptionRef = useRef(null);

  const contextValue = useContext(CreateOperationDataContext);
  const { onInputFocus } = contextValue; 
  
  const formPrefix = "whatsapp_data";

    const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 }, 
      md: { span: 10 },
      lg: { span: 12 },
      xl: { span: 8 },
      xxl: { span:6 },
    },
  };
  const formItemOrientation = "horizontal";

  

  return (
    <Card className='bg-dark-02'>
      
      <MarketingOperationShortcode/>

      <Typography className="fs-16 bold mb-20">
         <WhatsAppOutlined /> Dati per WhatsApp
      </Typography>
        
      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "description"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "description"]}
            />
            Testo
          </div>
        }
        rules={[
          {
            required: true,
            message: "Per favore, inserisci il testo del messaggio!",
          },
        ]}
      >
        <TextArea
            onFocus={() => onInputFocus(whatsappDescriptionRef, [formPrefix, "description"])}
            ref={whatsappDescriptionRef} // Riferimento alla TextArea
            maxLength={80}
            value={text}
            showCount
            allowClear
         />
      </Form.Item>

      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "link"]}
        label={
          <div className="flex gap-5">
            <MarketingOperationCopyButton
              fieldName={[formPrefix, "link"]}
            />
            Link
          </div>
        }
        rules={[
          {
            type: 'url',
            message: "Il link deve essere un url valido.",
          },
          {
            type: 'string',
          },
          {
            pattern: REGEX_HTTPS,
            message: 'L\'URL deve iniziare con "https://"',
          },
        ]}
      >
        <Input allowClear/>
      </Form.Item>
        
      <Form.Item
        {...formItemLayout} 
        layout={formItemOrientation}
        name={[formPrefix, "file"]}
        label="Carica Foto"
        valuePropName={"fileList"}
        getValueFromEvent={(e) => e?.fileList}
      >
        <MarketingUploadFile context={contextValue} fieldName={formPrefix} />
      </Form.Item>

      <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt="Anteprima" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Card>
  );
}
