import React, { useState } from 'react'

import { Col, Collapse, Divider, Form, InputNumber, Row, Select, TreeSelect, Typography } from 'antd';

import MarketingNumberConditionSelect from '../../../MarketingNumberConditionSelect';
import MarketingDynamicDatesCollapse from '../../../MarketingDynamicDatesCollapse';
import MarketingRemoveFieldIcon from '../../../MarketingRemoveFieldIcon';
import MarketingButtonAddField from '../../../MarketingButtonAddField';
import MarketingDateField from '../../../MarketingDateField';



export default function EcommerceFiltersShippingPayment({form, labelValue}) {
   
   const label = labelValue || "Pagamento/Spedizione";

  return (
     <Form.List name="shippings_payments_list" initialValue={[]}>
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[10, 10]} className="mb-20">
            {fields.map(({ key, name, ...restField }) => (
              
                <Col xs={24}  key={key} className="flex relative">
                  <Row gutter={[10, 10]} className="w-100 di-filters-group-wrapper">
                    
                     <Col xs={24} sm={24} md={8}>
                        <Typography className='mb-5'>Metodo di spedizione</Typography>
                        <Form.Item
                           className='w-100'
                           name={[name, 'shipping_method']}
                           rules={[
                              {
                              required: true,
                              message: 'Per favore, seleziona una condizione!',
                              },
                           ]}
                        >
                           <Select
                              className='min-w-150'
                              options={[
                                 { label:"Consegna a Domicilio" ,value: 'delivery' },
                                 { label:"Ritiro in Sede"      ,value: 'local' },
                                 { label:"Al Tavolo"           , value: 'position' },
                              ]}
                           />
                        </Form.Item>
                     </Col>
                    
                     <Col xs={24} sm={24} md={8}>
                        <Typography className='mb-5'>Metodo di pagamento</Typography>
                        <Form.Item
                           className='w-100'
                           name={[name, 'payment_method']}
                           rules={[
                              {
                              required: true,
                              message: 'Per favore, seleziona una condizione!',
                              },
                           ]}
                        >
                           <Select
                              className='min-w-150'
                              options={[
                                 { label:"Stripe" ,value: 'stripe' },
                                 { label:"PayPal" ,value: 'paypal' },
                                 { label:"SumUp"  , value: 'sumup' },
                                 { label:"Contanti"  , value: 'cash' },
                                 { label:"MyPOS" , value: 'mypos' },
                                 { label:"Satispay" , value: 'satispay' },
                              ]}
                           />
                        </Form.Item>
                     </Col>
                     
                    {/* Campo per la selezione delle quantità acquistate */}
                    <Col span={24} className='mt-20'>
                      <Typography className=" marketing-field-label">
                        Quantità di utilizzo {label.toLowerCase()}
                      </Typography>
                    
                      <Row gutter={[10,10]} className='mt-10'>
                        <Col xs={24} sm={18} md={6}>
                          <Typography className='mb-5'>Condizione spedizione/pagamento</Typography>
                          <MarketingNumberConditionSelect  name={[name, 'method_condition']} initialValue={"over"}/>
                        </Col>
                      
                        <Col xs={24} sm={6} md={4}>
                          <Typography className='mb-5'>Numero di ordini</Typography>
                          <Form.Item name={[name, 'method_amount']}>
                            <InputNumber className='w-100' min={1}/>
                          </Form.Item>
                        </Col>
  
                      </Row>
                    
                    </Col>
                    
                    {/* Campo per la selezione delle date */}
                    <MarketingDynamicDatesCollapse
                        label={" di utilizzo metodi"}
                        child={
                            <Form.Item>
                              <Form.List name={[name, 'method_dates']}>
                                {(subFields, subOpt) => (
                                  <>
                                    <div className='dynamic-date-wrapper justify-center'>
                                        {subFields.map((subField, index) => (
                                          <div className="radius-2 p-10 bg-dark-02 dynamic-date-child mw-sm" key={index}>
                                            <MarketingDateField listName={[name, 'method_dates']} field={subField} index={index} opt={subOpt} />
                                          </div>
                                        ))}
                                    </div>
                                    <div className="mt-20"><MarketingButtonAddField onClick={() => subOpt.add()} label={"Data"}/></div>
                                  </>
                                )}
                              </Form.List>
                          </Form.Item>
                        }
                      />
                  
                  </Row>
                  
                  <MarketingRemoveFieldIcon onClick={()=>remove(name)} />
                </Col>
            ))}
          </Row>

          <MarketingButtonAddField
            onClick={() => add()} 
            label={label}
          />
        </>
      )}
    </Form.List>
  )
}
