import React from 'react'

import { Checkbox, Col, Form, Typography } from 'antd';

export default function PersonalDataFiltersGdpr({ form, labelValue }) {
   
   const label = labelValue || "GDPR";

  return (
      <Col span={24}>
         <Form.Item
            name={"marketing_policy"}
           valuePropName="checked"
         >
            <Checkbox><Typography className='fs-16'>L'utente ha accettato la policy per marketing</Typography></Checkbox>
        </Form.Item>
        
         <Form.Item
            name={"gdpr_policy"}
           valuePropName="checked"
         >
            <Checkbox><Typography className='fs-16'>L'utente ha accettato il GDPR</Typography></Checkbox>
         </Form.Item>
        
      </Col>
  )
}
