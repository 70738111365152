import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

export default function MarketingButtonAddField({onClick, label}) {
  return (
     <Form.Item>
         <Button
            onClick={onClick}
            icon={<PlusOutlined />}
            className="w-100"
         >
            Aggiungi {label}
         </Button>
      </Form.Item>
  )
}
