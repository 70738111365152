import React, { useContext, useState } from 'react'

import { Button, Col, Row, Typography } from 'antd'
import { UsergroupAddOutlined } from '@ant-design/icons';

import MarketingAudienceCard from '../../marketing/audience/MarketingAudienceCard';
import createAudience from '../../marketing/audience/MarketingAudienceCreate';
import showAudience from '../../marketing/audience/MarketingAudienceShow';
import { dummyAudiences } from '../../../helpers/DummyData';
import MarketingEmpty from '../../marketing/MarketingEmpty';
import { Context } from '../../../helpers/Context';
import DashboardLayout from '../../DashboardLayout';


export default function PageAudiences() {
  
  const [selectedAudience, setSelectedAudience] = useState([]);
  const contextValue = useContext(Context);

  const onClickAudience = (e) => {
      
      const audienceId = e.target.value;
      const audienceData = dummyAudiences.find((item) => item.id === audienceId);

       setSelectedAudience((prevItems) =>
         prevItems.some((i) => i.id === audienceData.id)
            ? prevItems.filter((i) => i.id !== audienceData.id)  // Rimuovi l'oggetto con lo stesso id
            : [...prevItems, audienceData]                       // Aggiungi l'oggetto se non presente
      );
      
      console.log(selectedAudience);
  }
  
  
  return (
    <DashboardLayout hideShops={true}>
      
      {/* --------- sezione del pubblico ---------  */}
      <Row className='p-20'>
        <div className='di-card mb-20 w-100'>
          
          {
          false ? //condizione per mostrare o meno il pubblico
              
            //------------ aggiungi un pubblico quando non ne hai nessuno -----------
              <MarketingEmpty contextValue={contextValue}/>
            //------------ aggiungi un pubblico quando non ne hai nessuno -----------
          :
            // ------- ciclo per mostrare il pubblico ----------
              <>
                <Row className='mb-20' justify={"space-between"} gutter={[20,10]}>
                  <Col span={12} xs={24} sm={12}>
                    <div className='flex gap-5 color-primary'>
                      <UsergroupAddOutlined className='fs-20' />
                      <Typography className='fs-15'><span className='bold'>345</span> Destinatari Totali</Typography>
                    </div>
                  </Col>
                  <Col span={12} xs={24} sm={12} lg={10} xl={8}>
                    <Row gutter={[10,10]}>
                      <Col span={12} xs={24} sm={12}>
                        <Button 
                          className='w-100'
                          onClick={() => showAudience(contextValue,)}
                        >
                          Mostra Pubblico
                        </Button>
                      </Col>
                      <Col span={12} xs={24} sm={12}>
                        <Button
                          className='w-100'
                          type='primary'
                          onClick={() => createAudience(contextValue,)}
                        >
                          + Crea Pubblico
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[20, 20]}>
                  {dummyAudiences.map((e) => {
                      return (
                        <Col key={e.id} span={8} xs={24} sm={12}  md={12} lg={8}>
                          <MarketingAudienceCard
                            selectedAudience={selectedAudience}
                            compareDate={e.compareDate}
                            description={e.description}
                            onChange={onClickAudience}
                            amount={e.amount}
                            title={e.title}
                            id={e.id}
                          />
                        </Col>
                      )
                    })
                  }
              </Row>
             </>
            // ------- ciclo per mostrare il pubblico ----------
          }
        
        </div>
      </Row>
      {/* --------- sezione del pubblico ---------  */}

  </DashboardLayout>
  )
}
