import React, { useState } from 'react';
import ReactDOM from 'react-dom/client'; 

import { ConfigProvider, Drawer, theme } from 'antd';

import MarketingAudienceShowContent from './MarketingAudienceShowContent';
import { DARK_BG_CONTAINER } from '../../../helpers/Globals';

const showAudience = (context, audienceIdArray) => {
   
   // ------------- Crea un div temporaneo nel DOM per far comparire il drawer -------------
   const drawerContainer = document.createElement('div');
   document.body.appendChild(drawerContainer);
   // ------------- Crea un div temporaneo nel DOM per far comparire il drawer -------------

   // ------------- Componente Drawer -------------
   const DrawerComponent = () => {
      
      const [open, setOpen] = useState(true);
      const { darkMode } = context;

      const handleClose = async () => {
         console.log("inizio la chiusura");
         setOpen(!open)
      };

      
      return (
            <ConfigProvider
               theme={
               {
                  algorithm: darkMode ? theme.darkAlgorithm : theme.compactAlgorithm,
                  token:{
                     colorPrimary: "#008833",
                     colorBgContainer: darkMode ? DARK_BG_CONTAINER : "#fff"
                  }
               }
               }
            >
               <Drawer
                  className='di-create-audience-drawer'
                  onClose={handleClose}
                  title={"Gestisci Pubblico"}
                  placement="bottom"
                  height="100vh"
                  open={open}
                  destroyOnClose
               >
                  <MarketingAudienceShowContent contextValue={context} setOpen={setOpen} />
               </Drawer>
            </ConfigProvider>
      );
   };
   // ------------- Componente Drawer -------------

   // ------------- rendering effettivo del drawer -------------
   const root = ReactDOM.createRoot(drawerContainer);
   root.render(<DrawerComponent />);
   // ------------- rendering effettivo del drawer -------------

};

export default showAudience;
