import React, { useContext, useEffect, useState } from 'react'

import { Button, Col, Input, Row, theme, Typography } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, BellFilled, BellOutlined, CameraOutlined, DisconnectOutlined, LoadingOutlined, MutedOutlined, RotateRightOutlined, SafetyOutlined, SettingFilled, SettingOutlined, SoundFilled, SoundOutlined, SyncOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined, WifiOutlined } from '@ant-design/icons'

import { CreateOperationDataContext } from '../../../../../helpers/Context';
import MarketingDeviceLayout from '../../MarketingDeviceLayout';
import { nowDateTimeIta } from '../../../../../helpers/DateUtils';


export default function ChannelPushNotificationPreview() {
  
  const { pushNotificationData } = useContext(CreateOperationDataContext);
  const [height, setheight] = useState("800px")
  const [open, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen(!open);
    if (open) {
      setheight("0px");
    } else {
      setheight("800px")
    }
  }

  return (
    <div className="preview-container">
      {/* Schermata dispositivo */}
      <MarketingDeviceLayout hideTopBar>
        <div style={{ background: 'url("/wallpaper-device.jpg")' }} className='android-ui-background h-100 relative'>
          
          <div className='absolute top-1 w-100'>
            {(pushNotificationData?.title && !open) && 
              <div className='w-100 mt-20 align-center space-between gap-5 flex px-15'>
                <div className='radius-4 fs-10 bg-grey color-black py-10 px-25 w-100'>
                  <div className='notification-header flex gap-5 align-center fs-15 bold'>
                    <BellFilled className='fs-15 color-primary'/>
                    {pushNotificationData?.title}
                  </div>
                  <div className='fs-15 opacity-07 mt-10 w-100 text-wrap word-break'>{(pushNotificationData?.description?.length > 35) ? `${pushNotificationData?.description.slice(0,35)}...` : pushNotificationData?.description}</div>
                </div>
              </div>
            }
            
            <div className='w-100 mt-20 align-center space-between gap-5 flex px-15'>
              <Button className='opacity-07 radius-4 color-white' onClick={toggleOpen} block icon={<VerticalAlignBottomOutlined />} size='large' type='text'> Apri Notifiche </Button>
            </div>
          </div>
          
          <div style={{height:height}} className='android-ui-overlay transition-02 overflow-hidden absolute top-0 w-100'>
            
            <div className='w-100 align-center space-between flex px-15 fs-16 color-white'>
              <div className='mt-10'>{nowDateTimeIta}</div>
              <SettingFilled className='fs-16'/>
            </div>

            <div className='w-100 align-center space-between gap-5 flex px-15 mt-10'>
              <Button className='fs-20' size='large' shape='circle' type='primary'><WifiOutlined/></Button>
              <Button className='fs-20' size='large' shape='circle' ><DisconnectOutlined/></Button>
              <Button className='fs-20' size='large' shape='circle' type='primary'><SoundFilled/></Button>
              <Button className='fs-20' size='large' shape='circle' type='primary'><RotateRightOutlined/></Button>
              <Button className='fs-20' size='large' shape='circle' type='primary'><SafetyOutlined/></Button>
            </div>

            <div className={`w-100 mt-20 align-center space-between gap-5 flex px-15`}>
              <div className='radius-4 fs-10 bg-dark-03 py-10 px-25 color-white'>Controllo dispositivo</div>
              <div className='radius-4 fs-10 bg-dark-03 py-10 px-25 color-white'>Output multimediale</div>
            </div>

            {(pushNotificationData?.title) &&
              <div className='w-100 mt-20 align-center space-between gap-5 flex px-15'>
                <div className='radius-4 fs-10 bg-dark-03 py-10 px-25 w-100'>
                  <div className='notification-header flex gap-5 align-center color-white fs-15 word-break' >
                    <BellOutlined/>
                    <div>{pushNotificationData?.title.slice(0,30)}</div>
                  </div>
                  <div className='fs-12 opacity-07 mt-10 text-wrap word-break color-white'>{pushNotificationData?.description}</div>
                </div>
              </div>
            }
            
            <div className='w-100 mt-20 align-center space-between gap-5 flex px-15'>
              <Button className='opacity-07 radius-4 color-white' onClick={toggleOpen} block icon={<VerticalAlignTopOutlined />} size='large' type='text'> Chiudi </Button>
            </div>
          </div>
        </div>
      </MarketingDeviceLayout>
    </div>
  )
}
