import React, { useContext, useEffect, useState } from 'react'

import dayjs from 'dayjs';

import { ArrowDownOutlined, ArrowUpOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Calendar, Col, DatePicker, Divider, InputNumber, Popover, Row, Skeleton, Spin, Statistic, Switch, Tooltip } from 'antd'
import * as Unicons from '@iconscout/react-unicons';

import { COMPARE_GREEN, COMPARE_RED, ENDPOINT_PERIOD_SALES, LOCAL_FAST_EARN_COMPARE_1, LOCAL_FAST_EARN_COMPARE_2, LOCAL_FAST_EARN_COMPARE_3, PRIMARY_COLOR, TOOLTIP_DIFF_TEXT } from '../../../helpers/Globals';
import safeNanDivision from '../../../helpers/SafeNanDivision';
import getPercentage from '../../../helpers/GetPercentage';
import { getOldDate } from '../../../helpers/DateUtils';
import formatEuro from '../../../helpers/FormatEuro';
import { Context } from '../../../helpers/Context';
import { ApiCall } from '../../../Backoffice';

export default function DisplayFastDailyEarn() {
      

  const [dailyEarnOldDay1, setDailyEarnOldDay1] = useState(null);
  const [dailyEarnOldDay2, setDailyEarnOldDay2] = useState(null);
  const [dailyEarnOldDay3, setDailyEarnOldDay3] = useState(null);
  const [diffEarnOldDay1, setDiffEarnOldDay1] = useState(null);
  const [diffEarnOldDay2, setDiffEarnOldDay2] = useState(null); 
  const [diffEarnOldDay3, setDiffEarnOldDay3] = useState(null);

  const [loadingDiff1, setLoadingDiff1] = useState(true); 
  const [loadingDiff2, setLoadingDiff2] = useState(true); 
  const [loadingDiff3, setLoadingDiff3] = useState(true); 

  const [dailyEarn, setDailyEarn] = useState(null); 
  
  // --------- setto i giorni di deafult per le statistiche rapide e personalizzabili ---------
  const [oldDay1, setOldDay1] = useState(localStorage.getItem(LOCAL_FAST_EARN_COMPARE_1) ? localStorage.getItem(LOCAL_FAST_EARN_COMPARE_1) : 1); 
  const [oldDay2, setOldDay2] = useState(localStorage.getItem(LOCAL_FAST_EARN_COMPARE_2) ? localStorage.getItem(LOCAL_FAST_EARN_COMPARE_2) : 7); 
  const [oldDay3, setOldDay3] = useState(localStorage.getItem(LOCAL_FAST_EARN_COMPARE_3) ? localStorage.getItem(LOCAL_FAST_EARN_COMPARE_3) : 28); 
  // --------- setto i giorni di deafult per le statistiche rapide e personalizzabili ---------

  const [showEarnPercentage, setShowEarnPercentage] = useState(false);
      
  
  // ---- variabili dal context -----
  const {
    commonQueryParams,
    refreshVariables,
    useCurrentTime,
    dailyDiscount,
    isRestaurant,
    accessToken,
    userType,
    currency,
    service,

    refShowPercentage,
    refChangeDay,
    refFastStat,
   } = useContext(Context);
   // ---- variabili dal context -----
   

  // ---------------------  chiamata giornaliera per ricevere i dati delle ${ENDPOINT_PERIOD_SALES} da cui ottengo il totale venduto giornaliero, il totale clienti serviti, prodotti venduti e documenti emessi --------------------- 
  useEffect(()=>{

    (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${dayjs().format('YYYY-MM-DDT00:00:00')}&end_date=${dayjs().format('YYYY-MM-DDT23:59:59')}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
      if(json){
        var sales = json.sales
        var dailyEarn = 0; 

        for (const i in sales) {
          
          //sommo i totali delle singole battute 
          for (let n in sales[i]["lines"]) {
            dailyEarn = dailyEarn + sales[i]["lines"][n]["amount"];
          }
        }
        setDailyEarn(dailyEarn.toFixed(2));
          
      }
    })(); 

  },[...refreshVariables]);

  // --------------------- chiamata per ricevere le statistiche rapide con il primo valore --------------------- 
  useEffect(() => {
    if (dailyEarn  === null ) return;
    
    setLoadingDiff1(true);

    var fullOldDate = getOldDate(oldDay1);
    var oldDate = fullOldDate.split("T",1)[0];

    if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti i dati fino all'ora corrente di oggi

    (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate+"T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      if(json){
        var sales = json.sales
        var dailyEarnOldDay1Temp = 0; 

        for (const i in sales) {
          //sommo i totali delle singole battute 
          for (let n in sales[i]["lines"]) {
            dailyEarnOldDay1Temp +=  sales[i]["lines"][n]["amount"];
          }
        }
        if (dailyEarnOldDay1Temp == dailyEarnOldDay1) {
          setLoadingDiff1(false);
        }
        setDailyEarnOldDay1(dailyEarnOldDay1Temp.toFixed(2));
      }
    })(); 
    
  }, [dailyEarn, useCurrentTime, oldDay1, ...refreshVariables]);
  // --------------------- chiamata per ricevere le statistiche rapide con il primo valore --------------------- 
  
  // --------------------- chiamata per ricevere le statistiche rapide con il secondo valore ---------------------
  useEffect(() => {
    if (dailyEarn  === null ) return;
    
    setLoadingDiff2(true);

    var fullOldDate = getOldDate(oldDay2);
    var oldDate = fullOldDate.split("T",1)[0];

    if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti i dati fino all'ora corrente di oggi

    (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate + "T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
      if(json){
        var sales = json.sales
        var dailyEarnOldDay2Temp = 0; 

        for (const i in sales) {
          //sommo i totali delle singole battute 
          for (let n in sales[i]["lines"]) {
            dailyEarnOldDay2Temp += sales[i]["lines"][n]["amount"];
          }
        }
        if (dailyEarnOldDay2Temp == dailyEarnOldDay2) {
          setLoadingDiff2(false);
        }
        setDailyEarnOldDay2(dailyEarnOldDay2Temp.toFixed(2));
      }
    })(); 

  },[dailyEarn, useCurrentTime, oldDay2, ...refreshVariables]);
  // --------------------- chiamata per ricevere le statistiche rapide con il secondo valore ---------------------

  // --------------------- chiamata per ricevere le statistiche rapide con il terzo valore ---------------------
  useEffect(() => {
    if (dailyEarn  === null ) return;
    
    setLoadingDiff3(true);

    var fullOldDate = getOldDate(oldDay3);
    var oldDate = fullOldDate.split("T",1)[0];

    if(!useCurrentTime) fullOldDate = oldDate+"T23:59:59"; //se il filtro useCurrentTime è disattivo prendo i dati dei giorni precedenti di tutta la giornata, altrimenti i dati fino all'ora corrente di oggi

    (async () => {
      const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${oldDate+"T00:00:00"}&end_date=${fullOldDate}&${commonQueryParams}`, "GET", `${accessToken}`, null)
      
      if(json){
        var sales = json.sales
        var dailyEarnOldDay3Temp = 0; 

        for (const i in sales) {
          //sommo i totali delle singole battute 
          for (let n in sales[i]["lines"]) {
            dailyEarnOldDay3Temp +=  sales[i]["lines"][n]["amount"];
          }
        }
        if (dailyEarnOldDay3Temp == dailyEarnOldDay3) {
          setLoadingDiff3(false);
        }
        setDailyEarnOldDay3(dailyEarnOldDay3Temp.toFixed(2));
      }
    })();  
    
  }, [dailyEarn, useCurrentTime, oldDay3, ...refreshVariables]);
  // --------------------- chiamata per ricevere le statistiche rapide con il terzo valore ---------------------

  
// ---------------------  settaggio dei dati giorni precedenti --------------------- 
  useEffect(() => {
    if (dailyEarnOldDay1  === null)  return;
    setLoadingDiff1(true);
    setDiffEarnOldDay1(getPercentage(dailyEarn,dailyEarnOldDay1)); 
  }, [dailyEarn, dailyEarnOldDay1])

  useEffect(() => {
    if (dailyEarnOldDay2 === null) return;
    setLoadingDiff2(true);
    setDiffEarnOldDay2(getPercentage(dailyEarn,dailyEarnOldDay2));
  }, [dailyEarn, dailyEarnOldDay2])
  
  useEffect(() => {
    if (dailyEarnOldDay3  === null )  return;
    setLoadingDiff3(true);
    setDiffEarnOldDay3(getPercentage(dailyEarn, dailyEarnOldDay3));
  }, [dailyEarn, dailyEarnOldDay3])
   
  useEffect(() => {
    if (diffEarnOldDay1  === null)  return;
    setLoadingDiff1(false);
  }, [diffEarnOldDay1])
  useEffect(() => {
    if (diffEarnOldDay2 === null) return;
    setLoadingDiff2(false);
  }, [diffEarnOldDay2])
  useEffect(() => {
    if (diffEarnOldDay3  === null )  return;
    setLoadingDiff3(false);
  }, [diffEarnOldDay3])
  // ---------------------  settaggio dei dati giorni precedenti --------------------- 

  // ----------- contenuto del popover per cambiare le comparazioni -----------

  const [showCalendar, setShowCalendar] = useState(true);
  const [popoverOldDay1, setPopoverOldDay1] = useState(1); 
  const [popoverOldDay2, setPopoverOldDay2] = useState(1); 
  const [popoverOldDay3, setPopoverOldDay3] = useState(1); 
  
  const popoverContent = (param) => (
    <div>
      <p>{TOOLTIP_DIFF_TEXT + " (netto dello sconto)"}</p>
      <div className='flex gap-5 align-center'>
        {!showCalendar ?
          <InputNumber
            min={1}
            prefix="-"
            defaultValue={
              param === 1 ? oldDay1 :
              param === 2 ? oldDay2 :
              param === 3 ? oldDay3 :
              1
            }
            onChange={(value) => {
              switch (param) {
                case 1:
                  setPopoverOldDay1(value)
                  break;
                case 2:
                  setPopoverOldDay2(value)
                  break;
                case 3:
                  setPopoverOldDay3(value)
                  break;
              
                default:
                  break;
              }
            }
            }
            changeOnWheel
          />
        :
          <DatePicker
            size='medium'
            placeholder='Scegli giorno'
            onChange={(value) => {
                var day = dayjs(value).diff(dayjs(), "day", true).toString().split(".")[0].replace("-","");
                if (day === "un") day = 1;
                switch (param) {
                  case 1:
                    setPopoverOldDay1(day)
                    break;
                  case 2:
                    setPopoverOldDay2(day)
                    break;
                  case 3:
                    setPopoverOldDay3(day)
                    break;
                
                  default:
                    break;
                }
              }
            }
          />
        }
        <Button
          type='primary'
          size='medium'
          onClick={() => {
            
            localStorage.setItem(
              param === 1 ? LOCAL_FAST_EARN_COMPARE_1 :
              param === 2 ? LOCAL_FAST_EARN_COMPARE_2 :
              param === 3 ? LOCAL_FAST_EARN_COMPARE_3 :
              "",
              param === 1 ? popoverOldDay1 :
              param === 2 ? popoverOldDay2 :
              param === 3 ? popoverOldDay3 :
              ""
            )

              switch (param) {
              case 1:
                setOldDay1(popoverOldDay1)
                break;
              case 2:
                setOldDay2(popoverOldDay2)
                break;
              case 3:
                setOldDay3(popoverOldDay3)
                break;
            
              default:
                break;
              }
            
            }}
          >
            Salva
        </Button>
        <Switch
          checkedChildren="n"
          unCheckedChildren={<CalendarOutlined />}
          onChange={()=>setShowCalendar(!showCalendar)}
        />
      </div>
    </div>
  );
  // ----------- contenuto del popover per cambiare le comparazioni ----------- 

  
  return (
    <div className="di-card h-100 flex column" ref={refFastStat}>
      <Spin className='absolute bottom-20 right-30' spinning={loadingDiff1 || loadingDiff2 || loadingDiff3} />
      <Row gutter={[15, 15]} justify="center" align="middle">
        

         <Unicons.UilEuroCircle className="color-primary" />
        
         <Col align="center">
          <div className='fs-18 fw-5'>Totale incasso di oggi</div>
          <div className='fs-12 color-grey'>Netto dallo sconto</div>
         </Col>
   
         <Col align="center">
          <div className='flex align-center gap-5'>
              <Switch
                checkedChildren="%"
                unCheckedChildren={currency}
                onChange={() => setShowEarnPercentage(showEarnPercentage ? false : true)}
                ref={refShowPercentage}
              />
          </div>
         </Col>
   
      </Row>
      <Divider className='mt-10 mb-10'/>
   
      <Row gutter={[15, 15]} justify="space-around" align="middle">
         <Col align="center">
          <Tooltip
            className='cursor-pointer'
            title={
                `Incasso lordo di oggi ${formatEuro(dailyDiscount + parseFloat(dailyEarn))+currency}`
            }
          >
            <div className="flex flex-wrap justify-center gap-10">
              {dailyEarn === null ?
                <Skeleton.Button active/>
                :
                <span className='fs-30'>
                  {formatEuro(dailyEarn)+currency}
                </span>
              }
              {showEarnPercentage ?
                  <span className='fs-15'>
                    % Sconto <br />
                    {/* percentuale di sconto sul totale */}
                    {(safeNanDivision(dailyDiscount, dailyEarn) * 100).toFixed(2)} 
                  </span>
                :
                  <span className='fs-15'>
                      Sconto <br/>
                      {formatEuro(dailyDiscount)+currency}
                  </span>
              }
               {isRestaurant &&
                  <span className='fs-15'>
                     Servizio <br/>
                     {formatEuro(service)+currency}
                  </span>
               }
            </div>
         </Tooltip>
         </Col>
      </Row>
   
      <Divider className='mt-10 mb-10' />

      <div className='flex-grow flex column justify-center'>
        <Row justify="center"><div className='fs-12 color-grey'>{showEarnPercentage ? "Confronti" : "Totali" }</div></Row>
        <Row gutter={[15, 15]} justify="space-around" align="middle" ref={refChangeDay}>
          
          <Popover
            className='cursor-pointer'
            content={()=>popoverContent(1)}
            trigger={"click"}
          >
             <Col align="center" >
             {showEarnPercentage ?
                  <Statistic 
                    groupSeparator="."
                    decimalSeparator=','
                    title={`-${oldDay1}gg`}
                    loading={loadingDiff1}
                    value={diffEarnOldDay1}
                    valueStyle={{
                        color: 
                        diffEarnOldDay1 > 0 ? COMPARE_GREEN : COMPARE_RED,
                    }}
                    prefix={
                        diffEarnOldDay1 > 0 ? <ArrowUpOutlined />
                        :
                        diffEarnOldDay1 < 0 ? <ArrowDownOutlined />
                        :
                        ""
                    }
                    suffix="%"
                   />
                :
                  <Statistic
                    groupSeparator="."
                    decimalSeparator=','
                    title={`-${oldDay1}gg`}
                    loading={loadingDiff1}
                    value={dailyEarnOldDay1}
                    suffix={currency}
                    valueStyle={{color: PRIMARY_COLOR}}
                  />
             }
             </Col>
          </Popover>
           
           <Popover
            className='cursor-pointer'
            content={()=>popoverContent(2)}
            trigger={"click"}
          >
             <Col align="center">
             {showEarnPercentage ?
                <Statistic
                   groupSeparator="."
                   decimalSeparator=','
                   title={`-${oldDay2}gg`}
                   loading={loadingDiff2}
                   value={diffEarnOldDay2}
                   valueStyle={{
                   color: diffEarnOldDay2>0?  COMPARE_GREEN : COMPARE_RED,
                   }}
                   prefix={ 
                   diffEarnOldDay2 > 0 ? <ArrowUpOutlined />
                   :
                   diffEarnOldDay2 < 0 ? <ArrowDownOutlined />
                   :
                   ""
                   }
                   suffix="%"
                />
                :
                <Statistic
                  groupSeparator="."
                  decimalSeparator=','
                  title={`-${oldDay2}gg`}
                  loading={loadingDiff2}
                  value={dailyEarnOldDay2}
                  valueStyle={{color: PRIMARY_COLOR}}
                  suffix={currency}
                />
             }
             </Col>
          </Popover>
           
          <Popover
            className='cursor-pointer'
            content={()=>popoverContent(3)}
            trigger={"click"}
          >
             <Col align="center">
             {showEarnPercentage ?
                <Statistic
                   groupSeparator="."
                   decimalSeparator=','
                   title={`-${oldDay3}gg`}
                   loading={loadingDiff3}
                   value={diffEarnOldDay3}
                   valueStyle={{
                   color: diffEarnOldDay3>0? COMPARE_GREEN : COMPARE_RED,
                   }}
                   prefix={ 
                   diffEarnOldDay3 > 0 ? <ArrowUpOutlined />
                   :
                   diffEarnOldDay3 < 0 ? <ArrowDownOutlined />
                   :
                   ""
                   }
                   suffix="%"
                />
             :
                <Statistic
                  groupSeparator="."
                  decimalSeparator=','
                  title={`-${oldDay3}gg`}
                  loading={loadingDiff3}
                  value={dailyEarnOldDay3}
                  valueStyle={{color: PRIMARY_COLOR}}
                  suffix={currency}
                />
             }
             </Col>
          </Popover>
        </Row>
      </div>
   </div>
  )
}
