import React, { useContext, useEffect, useState } from 'react'

import { Badge, Button, Col, Collapse, Row, Typography } from 'antd'
import { AlertOutlined, CalendarOutlined, CaretRightOutlined,EditOutlined,FormOutlined, ManOutlined,  SafetyOutlined,  UserAddOutlined } from '@ant-design/icons'

import PersonalDataFiltersFormBirthdate from './PersonalDataFiltersFormBirthdate'
import MarketingFiltersToggleCollapse from '../MarketingFiltersToggleCollapse'
import PersonalDataFiltersFormSurname from './PersonalDataFiltersFormSurname'
import PersonalDataFiltersFormGender from './PersonalDataFiltersFormGender'
import PersonalDataFiltersActivation from './PersonalDataFiltersActivation'
import { CreateAudienceDataContext } from '../../../../../helpers/Context'
import PersonalDataFiltersFormName from './PersonalDataFiltersFormName'
import PersonalDataFiltersBirthday from './PersonalDatFiltersBirthday'
import PersonalDataFiltersFormAge from './PersonalDataFiltersFormAge'
import PersonalDataFiltersGdpr from './PersonalDataFiltersGdpr'
import IconDiCake from '../../../../icons/IconDiCake'

export default function PersonalDataFilters({ form }) {
  
  const {   
      setactiveKeysProfile,
      formActivationDates,
      formMarketingPolicy,
      activeKeysProfile,
      formBirthdates,
      formGdprPolicy,
      formBirthdays,
      colorProfile,
      formSurnames,
      formGenders,
      formNames,
      formAges,
      keys,
  } = useContext(CreateAudienceDataContext);
  
  // Funzione per espandere o contrarre tutti i pannelli
  const toggleAll = () => {
    if (activeKeysProfile.length === 0) {
      // Espandi tutti i pannelli, ottieni le chiavi dei pannelli
      setactiveKeysProfile(keys);
    } else {
      // Contrai tutti i pannelli (set activeKeys to empty array)
      setactiveKeysProfile([]);
    }
  };

  return (
    <>
      <Row justify={"end"} gutter={[10,10]} className='w-100'> 
        <Col>
          <MarketingFiltersToggleCollapse onClick={toggleAll} activeKeys={activeKeysProfile}/>
        </Col>
      </Row>
      <Collapse
        activeKey={activeKeysProfile}
        onChange={setactiveKeysProfile}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        expandIconPosition='end'
        size='large'
        bordered={false}
        items={[
          // -------- inizio array di elementi accordion --------

          // -------- gestione nome ---------
          {
            key: '1',
            forceRender: true,
            label: (
              <div className='flex flex-wrap gap-10 align-center'>
                    <Typography className='fs-16 flex align-center gap-5'>
                      <div className='fs-16'><FormOutlined /></div>
                      Nome
                    </Typography>
                    <Badge color={formNames?.length ? colorProfile : ""} count={formNames?.length}/>
                  {/* {<Tag color='yellow' className='text-wrap fs-16 p-10'>Selezionando {naturalLanguageBirthdate(birthday)} </Tag>} */}
              </div>
            ),
            
            children:
              <div className='mt-10'>
                <PersonalDataFiltersFormName form={form} labelValue={"Nome"} />
              </div>,
          },
          // -------- gestione nome ---------

          // -------- gestione cognome ---------
          {
            key: '2',
            forceRender:true,
            label: (
              <div className='flex flex-wrap gap-10 align-center'>
                    <Typography className='fs-16 flex align-center gap-5'>
                      <div className='fs-16'><FormOutlined /></div>
                      Cognome
                    </Typography>
                    <Badge color={formSurnames?.length ? colorProfile : ""} count={formSurnames?.length}/>
                  {/* {<Tag color='yellow' className='text-wrap fs-16 p-10'>Selezionando {naturalLanguageBirthdate(birthday)} </Tag>} */}
              </div>
            ),
            
            children:
              <div className='mt-10'>
                <PersonalDataFiltersFormSurname form={form} labelValue={"Cognome"} />
              </div>,
          },
          // -------- gestione cognome ---------

          // -------- gestione compleanno ---------
          {
            key: '3',
            forceRender:true,
            label: (
              <div className='flex flex-wrap gap-10 align-center'>
                    <Typography className='fs-16 flex align-center gap-5'>
                      <div className='fs-25 mt-5'><IconDiCake /></div>
                      Compleanno
                    </Typography>
                    <Badge color={formBirthdays?.length ? colorProfile : ""} count={formBirthdays?.length}/>
              </div>
            ),
            children: (
                <div className='mt-10'>
                  <PersonalDataFiltersBirthday form={form} labelValue={"Compleanno"}/>
                </div>
            ),
          },
          // -------- gestione compleanno ---------

          // -------- gestione età ---------
          {
            key: '4',
            forceRender:true,
            label: (
              <div className='flex flex-wrap gap-10 align-center'>
                    <Typography className='fs-16 flex align-center gap-5'>
                      <div className='fs-16'><FormOutlined /></div>
                      Età
                    </Typography>
                    <Badge color={formAges?.length ? colorProfile : ""} count={formAges?.length}/>
              </div>
            ),
            children: (
                <div className='mt-10'>
                  <PersonalDataFiltersFormAge form={form} labelValue={"Età"}/>
                </div>
            ),
          },
          // -------- gestione età ---------

          // -------- gestione genere ---------
          {
            key: '5',
            forceRender:true,
            label: (
              <div className='flex flex-wrap gap-10 align-center'>
                  <Typography className='fs-16 flex align-center gap-5'>
                    <div className='fs-16'><ManOutlined /></div>
                    Genere
                  </Typography>
                  <Badge color={formGenders?.length ? colorProfile : ""} count={formGenders?.length}/>
              </div>
            ),
            children: (
                <div className='mt-10'>
                  <PersonalDataFiltersFormGender form={form} labelValue={"Genere"}/>
                </div>
            ),
          },
          // -------- gestione genere ---------

          // -------- gestione data di nascita ---------
          {
            key: '6',
            forceRender:true,
            label: (
              <div className='flex flex-wrap gap-10 align-center'>
                  <Typography className='fs-16 flex align-center gap-5'>
                    <div className='fs-16'><CalendarOutlined/></div>
                    Data di nascita
                  </Typography>
                  <Badge color={formBirthdates?.length ? colorProfile : ""} count={formBirthdates?.length}/>
                  {/* {<Tag color='yellow' className='text-wrap fs-16 p-10'>Selezionando {naturalLanguageBirthdate(birthday)} </Tag>} */}
              </div>
            ),
            
            children: <div className='mt-10'>
              <PersonalDataFiltersFormBirthdate form={form} labelValue={"Data di nascita"}/>
            </div>,
          },
          // -------- gestione data di nascita ---------

          // -------- gestione attivazione utente ---------
          {
            key: '7',
            forceRender:true,
            label: (
              <div className='flex flex-wrap gap-10 align-center'>
                  <Typography className='fs-16 flex align-center gap-5'>
                    <div><UserAddOutlined /></div>
                    Data attivazione utente
                  </Typography>
                  <Badge color={formActivationDates?.length ? colorProfile : ""} count={formActivationDates?.length}/>
                  {/* {<Tag color='yellow' className='text-wrap fs-16 p-10'>Selezionando {naturalLanguageBirthdate(birthday)} </Tag>} */}
              </div>
            ),
            
            children: <div className='mt-10'>
              <PersonalDataFiltersActivation form={form} labelValue={"Data attivazione utente"}/>
            </div>,
          },
          // -------- gestione attivazione utente ---------

          // -------- gestione policy---------
          {
            key: '8',
            forceRender:true,
            label: (
              <div className='flex flex-wrap gap-10 align-center'>
                    <Typography className='fs-16 flex align-center gap-5'>
                      <div><SafetyOutlined /></div>
                      Privacy e GDPR
                    </Typography>
                    <Badge color={(formMarketingPolicy || formGdprPolicy) ? colorProfile : ""} dot={formMarketingPolicy || formGdprPolicy}/>
                  {/* {<Tag color='yellow' className='text-wrap fs-16 p-10'>Selezionando {naturalLanguageBirthdate(birthday)} </Tag>} */}
              </div>
            ),
            
            children: <PersonalDataFiltersGdpr form={form} labelValue={"Privacy e GDPR"}/>,
          },
          // -------- gestione policy---------
          
          // -------- fine array di elementi accordion --------
        ]}
      />
    </>
  )
}
