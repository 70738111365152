import React, { useState, useEffect } from "react";

import { Form, Row, Col, InputNumber, Checkbox, Typography, Select, Divider, Collapse } from "antd";
import { CalendarOutlined, CaretRightOutlined, FireOutlined, GiftOutlined, HourglassFilled, HourglassOutlined, TrophyOutlined } from "@ant-design/icons";

import MarketingNumberConditionSelect from "../../../MarketingNumberConditionSelect";
import MarketingRemoveFieldIcon from "../../../MarketingRemoveFieldIcon";
import MarketingButtonAddField from "../../../MarketingButtonAddField";
import MarketingDateField from "../../../MarketingDateField";

const FidelityFiltersCoupons = ({ form, labelValue }) => {
  
  const label = labelValue || "Coupon";

  const [fieldsVisibility, setFieldsVisibility] = useState({});

  // Funzione per aggiornare la visibilità dei campi in base ai checkbox
  const handleCheckboxChange = (name, field, value) => {
    setFieldsVisibility((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [field]: value,
      },
    }));
  };


  return (
    <Form.List name="coupons_list" initialValue={[]}>
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[10, 10]} className="mb-20">
            {fields.map(({ key, name, ...restField }) => (
              <Col xs={24}  key={key} className="flex relative">
                <Row gutter={[10, 10]} className="w-100 di-filters-group-wrapper">
                  
                  <Typography className="fs-16 mt-10"><GiftOutlined /> {label}</Typography>

                  {/* Campo per la selezione del coupon */}
                  <Col span={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "coupon"]}
                      key={[key, "coupon"]}
                    >
                      <Select
                        placeholder="Seleziona Coupon"
                        className="w-100 min-w-150"
                        allowClear
                        options={[
                          { value: 1, label: "Coupon Estivo" },
                          { value: 2, label: "Coupon Invernale" },
                          { value: 3, label: "Coupon Black Friday" },
                          { value: 4, label: "Coupon Natale" },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  
                  <Typography className="fs-16 mt-10"><TrophyOutlined /> Ottenimenti</Typography>

                  {/* Checkbox per "ottenuto" */}
                  <Row gutter={[10,10]} className="w-100">
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, "is_obtained"]}
                        valuePropName="checked"
                        onChange={(e) => handleCheckboxChange(name, "is_obtained", e.target.checked)}
                      >
                        <Checkbox disabled={fieldsVisibility[name]?.is_not_obtained}>Il Coupon è stato ottenuto</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, "is_not_obtained"]}
                        valuePropName="checked"
                        onChange={(e) => handleCheckboxChange(name, "is_not_obtained", e.target.checked)}
                      >
                        <Checkbox disabled={fieldsVisibility[name]?.is_obtained}>Il Coupon non è stato ottenuto</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  
                  {/* Condizione per Ottenimenti */}
                  <Col span={24}>
                    {fieldsVisibility[name]?.is_obtained && (
                      <div className="p-10 radius-1 bg-dark-02">
                        <Typography className="mb-5 marketing-field-label">
                           Ottenimenti
                        </Typography>

                        {/* Confronto valore totale */}
                        <Row gutter={[10, 10]}>
                          
                          <Col xs={24} sm={12}>
                            <MarketingNumberConditionSelect
                              nameValue={[name, "obtain_condition"]}
                              keyValue={[key, "obtain_condition"]}
                              allowClear
                            />
                          </Col>
                          
                          <Col xs={24} sm={12}>
                            <Form.Item
                              {...restField}
                              className="w-100"
                              name={[name, "obtain_amount"]}
                              key={[key, "obtain_amount"]}
                              rules={[
                                {
                                  type: "number",
                                  message: `Il totale ${label} deve essere un numero!`,
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Infinito"
                                className="w-100"
                              />
                            </Form.Item>
                          </Col>

                        </Row>
                        {/* Confronto valore totale */}


                         {/* accordion per la gestione date */}
                          <Collapse
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            expandIconPosition='end'
                            bordered={false}
                            className='w-100 bg-dark-02 mt-10'
                            items={[
                              {
                                key: 'collapse-obtains-dates',
                                label: (
                                  <Col span={24}>
                                    <Typography className="mb-5 marketing-field-label mt-10">
                                      <CalendarOutlined/> Date ottenimenti
                                    </Typography>
                                  </Col>
                                ),
                                
                                children:
                                  <div>
                                    {/* Campo per la selezione delle date */}
                                    <Col span={24}>
                                      <Form.Item>
                                        <Form.List name={[name, 'obtain_dates']}>
                                          {(subFields, subOpt) => (
                                            <>
                                              <div className='dynamic-date-wrapper'>
                                                  {subFields.map((subField, index) => (
                                                    <div className="radius-2 p-10 bg-dark-02 dynamic-date-child mw-sm" key={index}>
                                                      <MarketingDateField field={subField} index={index} opt={subOpt} />
                                                    </div>
                                                  ))}
                                              </div>
                                              <div className="mt-20"><MarketingButtonAddField onClick={() => subOpt.add()} label={"Data"}/></div>
                                            </>
                                          )}
                                        </Form.List>
                                      </Form.Item>
                                    </Col>
                                  </div>,
                              },
                            ]}
                          />

                      </div>
                    )}
                  </Col>

                  <Typography className="fs-16"><FireOutlined/> Bruciature</Typography>
                  
                  {/* Checkbox per "bruciato" */}
                  <Row gutter={[10, 10]} className="w-100">
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, "is_burned"]}
                        valuePropName="checked"
                        onChange={(e) => handleCheckboxChange(name, "is_burned", e.target.checked)}
                      >
                        <Checkbox disabled={fieldsVisibility[name]?.is_not_burned}>Il Coupon è stato bruciato</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, "is_not_burned"]}
                        valuePropName="checked"
                        onChange={(e) => handleCheckboxChange(name, "is_not_burned", e.target.checked)}
                      >
                        <Checkbox disabled={fieldsVisibility[name]?.is_burned}>Il Coupon non è stato bruciato</Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* Condizione per le bruciature */}
                  <Col span={24}>
                    {fieldsVisibility[name]?.is_burned && (
                      <div className="p-10 radius-1 bg-dark-02">
                        <Typography className="mb-5 marketing-field-label">
                           Bruciature
                        </Typography>

                        {/* Confronto valore totale */}
                        <Row gutter={[10, 10]}>
                          
                          <Col xs={24} sm={12}>
                            <MarketingNumberConditionSelect
                              nameValue={[name, "burned_condition"]}
                              keyValue={[key, "burned_condition"]}
                              allowClear
                            />
                          </Col>
                          
                          <Col xs={24} sm={12}>
                            <Form.Item
                              {...restField}
                              className="w-100"
                              name={[name, "burned_amount"]}
                              key={[key, "burned_amount"]}
                              rules={[
                                {
                                  type: "number",
                                  message: `Il totale ${label} deve essere un numero!`,
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="Infinito"
                                className="w-100"
                              />
                            </Form.Item>
                          </Col>

                        </Row>
                        {/* Confronto valore totale */}

                         {/* accordion per la gestione date */}
                        <Collapse
                          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                          expandIconPosition='end'
                          bordered={false}
                          className='w-100 bg-dark-02 mt-10'
                          items={[
                            {
                              key: 'collapse-burned-dates',
                              label: (
                                <Col span={24}>
                                  <Typography className="mb-5 marketing-field-label mt-10">
                                    <CalendarOutlined/> Date bruciature
                                  </Typography>
                                </Col>
                              ),
                              
                              children:
                                <div>
                                  {/* Campo per la selezione delle date */}
                                  <Col span={24}>
                                    <Form.Item>
                                      <Form.List name={[name, 'burned_dates']}>
                                        {(subFields, subOpt) => (
                                          <>
                                            <div className='dynamic-date-wrapper'>
                                                {subFields.map((subField, index) => (
                                                  <div className="radius-2 p-10 bg-dark-02 dynamic-date-child mw-sm" key={index}>
                                                    <MarketingDateField field={subField} index={index} opt={subOpt} />
                                                  </div>
                                                ))}
                                            </div>
                                            <div className="mt-20"><MarketingButtonAddField onClick={() => subOpt.add()} label={"Data"}/></div>
                                          </>
                                        )}
                                      </Form.List>
                                    </Form.Item>
                                  </Col>
                                </div>,
                            },
                          ]}
                        />

                      </div>
                    )}
                  </Col>

                  
                  
                  <Typography className="fs-16 mt-10"><HourglassOutlined /> Scadenza </Typography>

                  {/* Checkbox per "ottenuto" */}
                  <Row gutter={[10,10]} className="w-100">
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, "is_expired"]}
                        valuePropName="checked"
                        onChange={(e) => handleCheckboxChange(name, "is_expired", e.target.checked)}
                      >
                        <Checkbox disabled={fieldsVisibility[name]?.is_not_expired}>Il Coupon è scaduto</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        {...restField}
                        name={[name, "is_not_expired"]}
                        valuePropName="checked"
                        onChange={(e) => handleCheckboxChange(name, "is_not_expired", e.target.checked)}
                      >
                        <Checkbox disabled={fieldsVisibility[name]?.is_expired}>Il Coupon non è scaduto</Checkbox>
                      </Form.Item>
                    </Col>

                    {/* accordion per la gestione date */}
                        <Collapse
                          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                          expandIconPosition='end'
                          bordered={false}
                          className='w-100 bg-dark-02 mt-10'
                          items={[
                            {
                              key: 'collapse-expired-dates',
                              label: (
                                <Col span={24}>
                                  <Typography className="mb-5 marketing-field-label mt-10">
                                    <CalendarOutlined/> Date
                                  </Typography>
                                </Col>
                              ),
                              
                              children:
                                <div>
                                  {/* Campo per la selezione delle date */}
                                  <Col span={24}>
                                    <Form.Item>
                                      <Form.List name={[name, 'expired_dates']}>
                                        {(subFields, subOpt) => (
                                          <>
                                            <div className='dynamic-date-wrapper'>
                                                {subFields.map((subField, index) => (
                                                  <div className="radius-2 p-10 bg-dark-02 dynamic-date-child mw-sm" key={index}>
                                                    <MarketingDateField  listName={[name, 'expired_dates']} field={subField} index={index} opt={subOpt} />
                                                  </div>
                                                ))}
                                            </div>
                                            <div className="mt-20"><MarketingButtonAddField onClick={() => subOpt.add()} label={"Data"}/></div>
                                          </>
                                        )}
                                      </Form.List>
                                    </Form.Item>
                                  </Col>
                                </div>,
                            },
                          ]}
                        />

                  </Row>

                  {/* Rimuovere campo */}
                  <MarketingRemoveFieldIcon onClick={() => remove(name)} />
                </Row>
              </Col>
            ))}
          </Row>

          <MarketingButtonAddField
            onClick={() => add()} 
            label={label}
          />
        </>
      )}
    </Form.List>
  );
};

export default FidelityFiltersCoupons;
