import React from 'react'

import { Form, Col, Row, Typography, Select } from 'antd';

import MarketingRemoveFieldIcon from '../../../MarketingRemoveFieldIcon';
import MarketingButtonAddField from '../../../MarketingButtonAddField';


export default function PersonalDataFiltersBirthday({ form, labelValue }) {
  
  const label = labelValue || "Compleanno";

 return (
      <Form.List
        name="birthday_list"
        initialValue={[]} // Aggiungi un elemento iniziale vuoto
      >
        {(fields, { add, remove }) => (
          <>
          <Row gutter={[10,10]}  className='mb-20'>
              {fields.map(({ key, name, ...restField }) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={4}
                  key={key}
                  className='flex relative'
                >
                 <Row gutter={[10,10]} className='w-100 di-filters-group-wrapper'>
                    <Col span={24} >
                      <Typography className='mb-5 marketing-field-label'>{label}</Typography>
                       <Form.Item
                        {...restField}
                        className='w-100'
                        name={[name, 'value']}
                        key={[key, 'value']}
                        initialValue={"today"}
                        rules={[
                           {
                              required: true,
                              message: `Per favore, seleziona il ${label.toLowerCase()}!`,
                           },
                        ]}
                     >
                     <Select
                        placeholder={label} 
                        options={[
                           { label: 'Oggi', value: 'today' },
                           { label: 'Questa Settimana', value: 'week' },
                           { label: 'Questo Mese', value: 'month' },
                        ]}
                     />
                     </Form.Item>
                     </Col>
                 </Row>
  
                  {/* Pulsante per rimuovere il campo */}
                  <MarketingRemoveFieldIcon onClick={() => remove(name)}/>
  
                </Col>
              ))}
          </Row>
         
            <MarketingButtonAddField
              onClick={() => add()} 
              label={label}
            />
         
          </>
        )}
      </Form.List>
  );
}
