import { useContext, useState } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Layout, Drawer, Divider, Typography} from 'antd';

import DiActionsSider from './DiActionsSider.js';
import { Context } from '../helpers/Context';
import DashboardMenu from './DashboardMenu';
import Version from './Version.js';
import Logo from './Logo.js';

const { Content, Sider } = Layout;

function DashboardLayout({ children, hideShops }) {
  
  const [open, setOpen] = useState(false);

  const {
    darkMode,
    shopName
  } = useContext(Context);

  const showDrawer = () => {
    document.querySelector("body").classList.toggle("vw-100");
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  return (
    
    <Layout
      className='di-dashboard'
        style={{
          minHeight: '100vh',
        }}
      > 

        {/* ---------------- header mobile ------------------ */}
        <section className='di-header mobile-show flex space-between align-center'>
          <div className='di-hedaer-left'>
            <MenuOutlined onClick={showDrawer} className="di-show-drawer mobile-show"/>
          </div>        
          <div className='di-hedaer-center'>
          </div>        
          <div className='di-hedaer-right'>
            <div className='di-logo-wrapper flex align-end column w-100 p-10'>
              <Logo white={darkMode}/>
              {
                shopName && <marquee className='shop-name'><Typography>{shopName}</Typography></marquee>
              }
            </div>
          </div>      
        </section>
        {/* ---------------- header mobile ------------------ */}

        {/* ------------------drawer per mobile ------------------  */}
        <Drawer className="di-mobile-menu" placement="left" width={"260px"} onClose={onClose} open={open}>
          <DiActionsSider/>  
          <DashboardMenu hideShops={hideShops} /> 
          <Divider/>
          <Version/>
        </Drawer>
        {/* ------------------ drawer per mobile ------------------  */}

        {/* ------------------ sidebar desktop ------------------ */}
        <Sider theme='light' className='di-sidebar mobile-hide'>    
          <div className='flex column align-center gap-10'>
            <DiActionsSider/>
            <div className='di-logo-wrapper w-100 mt-10'>
              <Logo white={darkMode}/>
            </div>
          </div>
          <DashboardMenu hideShops={hideShops} />
          <Version />
        </Sider>
        {/* ------------------ sidebar desktop ------------------ */}

        {/* ------------------ conent del layout dinamico ------------------ */}
        <Content>
            {children}
        </Content>
        {/*------------------  conent del layout dinamico ------------------ */}

      
    </Layout>
  )
}

export default DashboardLayout;