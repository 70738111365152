const changeLog = [
   {
      "version": "1.3.0 - 17/03/25",
      "lines": [
         { "type": "add", "text": "Bacco - Aggiunti metodi di pagamento alla tabella degli scontrini emessi" },
         { "type": "add", "text": "Bacco - Aggiunto il filtro per i tipi di documenti Addebito Hotel" },
         { "type": "add", "text": "Bacco - Aggiunta la possibilità di interrogare un periodo più ampio per i tavoli aperti" },
         { "type": "add", "text": "Bacco - Aggiunto il filtro Tipi di Documento nel drawer dei filtri" },
         { "type": "mod", "text": "Tutti - Aggiunto effetto sfocatura all'apertura dei menù laterali e drawers" },
         { "type": "add", "text": "Bacco - Aggiunta statistica rapida dello storico correzioni" },
         { "type": "add", "text": "Bacco - Aggiunta tabella dello storico correzioni nella appostia pagina" },
         { "type": "add", "text": "Bacco - Aggiunti i dati sui tipi di documenti anche nei giorni di confronto precedenti cliccandoci sopra" },
         { "type": "mod", "text": "Tutti - Cambiato lo sfondo della dashboard" },
         { "type": "mod", "text": "Tutti - Modificato lo sfondo degli elementi principali" },
         { "type": "add", "text": "Bacco - Aggiunto tasto rapido per visualizzare i tavoli sulla tabella dei conti aperti" },
         { "type": "add", "text": "Tutti - Aggiunto editor dei layout per modificare ordine e dimensione dei dati per singole pagine" },
         { "type": "mod", "text": "Tutti - Divisi i grafici per pagine: Statistiche Rapide, Grafici e Tabelle" },
         { "type": "add", "text": "Tutti - Aggiunta la visualizzazione della percentuale di sconto totale sull'incasso rapido" },
         { "type": "add", "text": "Tutti - Aggiunta la visualizzazione valuta personalizzata" },
         { "type": "fix", "text": "Bacco - Corretta la visualizzazione del nome Operatore Cassa su alcuni conti" },
      ]
   },
   {
      "version": "1.2.9 - 24/09/24",
      "lines": [
         { "type": "add", "text": "Bacco - Aggiunti i dati sui documenti annullati nelle statistiche rapide" },
         { "type": "mod", "text": "Bacco - Ordinate di default le categorie nella tabella venduto per categorie, per codice categoria " }
      ]
   },
   {
      "version": "1.2.8 - 23/09/24",
      "lines": [
         { "type": "add", "text": "Bacco - Aggiunti i totali ed i codici categorie nella tabella sul venduto raggruppato per categorie" },
         { "type": "mod", "text": "Tutti - Ordinati i risultati dei confronti per data crescente" }
      ]
   },
   {
      "version": "1.2.7 - 16/09/24",
      "lines": [
         { "type": "add", "text": "Bacco - Aggiunta la possibilità di filtrare i camerieri direttamente sulla tabella in relazione alla produzione cameriere. Il filtro generale non funzionerà per quei dati" },
         { "type": "add", "text": "Bacco - Aggiunta la possibilità di customizzare il colore dei dati 'Totale Produzione' ed 'Incassato' dei camerieri in modo casuale o selezionato" }
      ]
   },
   {
      "version": "1.2.6 - 12/09/24",
      "lines": [
         { "type": "add", "text": "Tutti - Aggiunto menù guida per i grafici"                                                                                                },
         { "type": "mod", "text": "Tutti - Migliorie grafiche generali"                                                                                                      },
         { "type": "add", "text": "Tutti - Aggiunto 'Ieri' come data rapida nei calendari dei grafici"                                                                       },
         { "type": "add", "text": "Bacco - Aggiunto il totale coperti nel monitor dei tavoli aperti"                                                                         },
         { "type": "mod", "text": "Bacco - Rimosso il Servizio nei prodotti venduti"                                                                                         },
         { "type": "add", "text": "Tutti - Aggiunto il calendario per recuperare facilmente i giorni di confronto rapidi"                                                    },
         { "type": "add", "text": "Bacco - Aggiunta la tabella per verificare prodotti ed incassato camerieri"                                                               },
         { "type": "add", "text": "Bacco - Aggiunta la tabella per visualizzare il venduto di tutti i prodotti raggruppati per categorie"                                    },
      ]
   },
   {
      "version": "1.2.5 - 24/07/24",
      "lines": [
         { "type": "mod", "text": "Bacco - Aggiunto arrotonddamento nel totale delle tabelle scontrini"                                                                      },
         { "type": "mod", "text": "Tutti - Mostrato il totale guadagni al netto degli sconti e non al lordo"                                                                 },
         { "type": "mod", "text": "Tutti - Modificato la label del tooltip dei confronti"                                                                                    },
      ]
   },
   {
      "version": "1.2.4 - 15/07/202",
      "lines": [
         { "type": "add", "text": "Tutti - Aggiunto il tour panoramico delle statistiche"                                                                                    },
         { "type": "mod", "text": "Tutti - Modificato lo stile dei tooltip"                                                                                                  },
         { "type": "mod", "text": "Tutti - Migliorata la responsività delle statistiche rapide"                                                                              },
      ]
   },
   {
      "version": "1.2.3 - 10/07/2024",
      "lines": [
         { "type": "add", "text": "Tutti - Aggiunta la modalità scura (beta)"                                                                                                },
         { "type": "mod", "text": "Tutti - Modificato lo stile dell'input datario e colori"                                                                                  },
         { "type": "mod", "text": "Tutti - Modificato lo stile del pulsante di download csv"                                                                                 },
         { "type": "add", "text": "Tutti - Aggiunto il totale rapido nel grafico dei metodi di pagamento"                                                                    },
         { "type": "fix", "text": "Tutti - Migliorata la responsività da mobile dell'input datario"                                                                          },
      ]
   },
   {
      "version": "1.2.2 - 04/07/2024",
      "lines": [
         { "type": "add", "text": "Tutti - Aggiunta la possibilità di configurare per browser, le giornate di confronto delle statistiche rapide cliccandoci sopra"          },
         { "type": "mod", "text": "Bacco - Aggiunto il filtro per ottenere i dati sulla data lavoro (default verranno recuperati per data scontrino"                         },
         { "type": "mod", "text": "Tutti - Limitato il range di date interrogabile ad un anno"                                                                               },
         { "type": "mod", "text": "Bacco - Gestito il prodotto di tipo SERVIZIO nei tavoli aperti se è a 0"                                                                  },
         { "type": "add", "text": "Bacco - Aggiunta la media di incasso per coperto nelle statistiche rapide"                                                                },
         { "type": "add", "text": "Tutti - Possibilità di mostrare o meno i log delle versioni più vecchie nel changelog"                                                    },
      ]
   },
   {
      "version": "1.2.1 - 28/06/2024",
      "lines": [
         { "type": "mod", "text": "Tutti - Gestito il prodotto di tipo SERVIZIO nei prodotti venduti"                                                                        },
         { "type": "mod", "text": "Tutti - Modificato il testo di aiuto nel grafico dei metodi di pagamento"                                                                 },
         { "type": "add", "text": "Tutti - Aggiunta la sezione di Confronto dati"                                                                                            },
         { "type": "mod", "text": "Tutti - Aggiunti e migliorati i loaders per le statistiche rapide"                                                                        },
         { "type": "mod", "text": "Tutti - Cambiato il colore della statistica rapida quando a valore 0, e aggiunte etichette indicative per la comprensione"                },
         { "type": "fix", "text": "Tutti - Migliorato il responso di alcuni grafici che non si resettavano in tempo dopo aver reimpostato la data"                           },
         { "type": "add", "text": "Bacco - Aggiunta la media clienti per documento emesso nella statistica rapida Documento Medio"                                           },
         { "type": "mod", "text": "Tutti - Disabilitata la traduzione automatica dei browser"                                                                                },
         { "type": "mod", "text": "Tutti - Aggiunto un loader per la lista punti vendita"                                                                                    },
      ]
   },
   {
      "version": "1.2.0 - 17/06/2024",
      "lines": [
         { "type": "mod", "text": "Bacco - Modificata la visualizzazione dei ristoranti nella lista locali da codice pubblico a Nome Locale"                                 },
         { "type": "fix", "text": "Bacco - Migliorato il responso dell'aggiornamento lista camerieri cambiando codice locale"                                                },
      ]
   },
   {
      "version": "1.1.9 - 02/06/2024",
      "lines": [
         { "type": "add", "text": "Bacco - Possibilità di vedere il totale in euro dei documenti emessi suddiviso per tipologia"                                             },
         { "type": "mod", "text": "Bacco - Possibilità di vedere il numero di documenti emessi divisi per tipologia, cliccando sul tipo di documento"                        },
      ]
   },
   {
      "version": "1.1.8 - 22/05/2024",
      "lines": [
         { "type": "add", "text": "Bacco - Suddivisione dei tipi di documento nel totale documenti emessi del giorno"                                                        },
         { "type": "mod", "text": "Signa - Rimozinoe del limite minimo di 10 per la visualizzazione dei marchi"                                                              },
      ]
   },
   {
      "version": "1.1.7 - 15/05/2024",
      "lines": [
         { "type": "fix", "text": "Bacco - Risoluzione apertura tavoli aperti"                                                                                               },
         { "type": "mod", "text": "Tutti - Rimozione apertura automatica del changelog"                                                                                      },
      ]
   },
   {
      "version": "1.1.6 - 11/05/2024",
      "lines": [
         { "type": "fix", "text": "Tutti - Inizializzata la visualizzazione di tutti i grafici la prima volta che si aprono le statistiche (filtri)"                        },
         { "type": "mod", "text": "Tutti - Messa in evidenza delle novità di versione (ChangeLog)"                                                                          },
         { "type": "mod", "text": "Tutti - Apertura del changelog automatica alla prima apertura della nuova versione"                                                      },
         { "type": "mod", "text": "Tutti - Modificato il separatore dell'export csv dalla virgola al punto e virgola"                                                       },
         { "type": "fix", "text": "Bacco - Cancellazione della notifica cameriere al reset del filtro o al cambio del cameriere"                                            },
         { "type": "add", "text": "Tutti - Possibilità di filtrare le note del changelog"                                                                                   },
      ]
   },
   {
      "version": "1.1.5 - 26/04/2024",
      "lines": [
         { "type": "add", "text": "Tutti - Aggiunta la funzione di Export in CSV tramite apposito pulsante nel grafico, quando il dato è presente"                         },
         { "type": "add", "text": "Tutti - Salvataggio di una configurazione di categorie visualizzate nel grafico prodotti venduti. Di default il sistema mostrerà tutti" },
         { "type": "add", "text": "------- Se è presente una configurazione salvata, il tasto di attivazione viene mostrato"                                               },
         { "type": "add", "text": "------- Se è attivo un filtro per categoria verrà mostrato il tasto per salvare l'attuale configurazione di filtro categoria"           },
         { "type": "fix", "text": "Tutti - Sottratti gli scontrini annullati dal conteggio statistico"                                                                     },
         { "type": "add", "text": "Bacco - Aggiunto filtro operatori"                                                                                                      },
         { "type": "add", "text": "Bacco - Aggiunto filtro gateway per visionare locali diversi con un unico accesso"                                                      },
         { "type": "add", "text": "Bacco - Implementata notifica per visionare il cameriere di cui si osservano le statistiche"                                            },
         { "type": "add", "text": "SignaWOW - Implementata statistica per i marchi più venduti"                                                                            },
         { "type": "mod", "text": "Bacco - Visualizzabile nella barra principale il codice gateway interrogato (solo da mobile)"                                           },
      ]
   }
];

export default changeLog; 