import { Form, Select } from 'antd'
import React, { useState } from 'react'

export default function MarketingStringConditionSelect({initialValue, nameValue}) {
   

  return (
   <Form.Item
      className='w-100'
      name={nameValue}
      initialValue={initialValue}
      rules={[
         {
         required: true,
         message: 'Per favore, seleziona una condizione!',
         },
      ]}
   >
      <Select
         placeholder="Condizione" 
         options={[
            { label: 'Uguale a', value: 'equal' },
            { label: 'Contiene', value: 'contains' },
         ]}
      />
   </Form.Item>
  )
}
