import React, { useContext } from 'react'

import generateFilterDescription from '../../../helpers/FiltersToText';
import { CreateAudienceDataContext } from '../../../helpers/Context'

export default function MarketingCreateAudienceDescription() {
   
   const { formValues } = useContext(CreateAudienceDataContext);

  return (
    <div></div>
  )
}
