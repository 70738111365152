import React, { useContext, useEffect, useRef, useState } from 'react';

import { DashboardOutlined, DiffOutlined, OrderedListOutlined, PieChartOutlined } from '@ant-design/icons';
import { Typography, Modal, Badge, Drawer, Carousel, Button, Menu } from 'antd';

import { LOCAL_LAST_VERSION, LOCAL_LAYOUT_FAST_STATS_RESTAURANT, LOCAL_LAYOUT_FAST_STATS_RETAIL, LOCAL_LAYOUT_GRAPHICS_RESTAURANT, LOCAL_LAYOUT_GRAPHICS_RETAIL, LOCAL_LAYOUT_TABLE_RESTAURANT, LOCAL_LAYOUT_TABLE_RETAIL, LOCAL_SHOW_VERSION_DOT } from '../helpers/Globals';
import ChangeLogTitle from './ChangeLogTitle';
import { Context } from '../helpers/Context';
import ShowMore from './ShowMore';

const packageJson = require("../../package.json");
const currentVersion = packageJson.version;

//costruzione del singolo elemento di menù
function getItem(label, key, icon, className, children) {
  return {
    key,
    icon,
    children,
    label,
    className
  };
}

export default function Version() {
   
   const [lastVersion, setLastVersion] = useState(localStorage.getItem(LOCAL_LAST_VERSION) ? localStorage.getItem(LOCAL_LAST_VERSION) : "0.0.0");
   const [show, setShow] = useState(localStorage.getItem(LOCAL_SHOW_VERSION_DOT));
   const [openDrawer, setOpenDrawer] = useState(false);
   const [open, setOpen] = useState(false);
   const carouselRef = useRef(null);

   const primaryMenu =
    [
      getItem('Tabelle', 'tabulations', <OrderedListOutlined />),
      getItem("Rapide", 'fast-stats', <DashboardOutlined />),
      getItem('Grafici', 'graphics', <PieChartOutlined />),
      getItem("Confronta", 'compare', <DiffOutlined />),
   ];
   
   const { resetChangelogFilter, filteredChangelog, deleteSavedLayout } = useContext(Context);

   const onClick = () => {
      setOpen(true);
      localStorage.removeItem(LOCAL_SHOW_VERSION_DOT); 
   };


   useEffect(() => {

      if (isVersionSavedLessThanCurrent()) {
         localStorage.setItem(LOCAL_SHOW_VERSION_DOT, true); 
      }
      //---------- se la versione appena aperta è la 1.3.0 mostro il drawer e salvo la nuova versione in locale
      
      if (currentVersion === "1.3.0" && isVersionSavedLessThanCurrent()) {
         setOpenDrawer(true); // Mostra il drawer
      }
      
      setTimeout(() => {
         localStorage.setItem(LOCAL_LAST_VERSION, currentVersion); 
      },2000)
      //---------- se la versione appena aperta è la 1.3.0 mostro il drawer e salvo la nuova versione in locale
   
   }, [])
  
   
   // Funzione per trasformare la versione in un numero intero senza i punti
   function versionToNumber(version) {
      return parseInt(version.replace(/\./g, ''), 10);  // Rimuove i punti e converte in intero
   }

   // Funzione per verificare se la versione salvata è maggiore della versione corrente
   function isVersionSavedGreaterThanCurrent() {
      return versionToNumber(lastVersion) > versionToNumber(currentVersion);
   }

   // Funzione per verificare se la versione salvata è minore della versione corrente
   function isVersionSavedLessThanCurrent() {
      return versionToNumber(lastVersion) < versionToNumber(currentVersion);
   }

   // Funzione per verificare se la versione salvata è uguale alla versione corrente
   function isVersionSavedEqualToCurrent() {
      return versionToNumber(lastVersion) === versionToNumber(currentVersion);
   }

   
   const carouselSlides = [
      {
         cover:<img src='/new-version.svg'></img>,      
         title:"Benvenuto nella nuova versione delle Statistiche!",      
         description:"Ti faccio un breve riassunto delle novità di questa versione...",      
      },
      {
         cover: <div className='di-card'><Menu className="di-navigation primary mt-5" theme="light" mode="inline" items={primaryMenu} /></div>,    
         title:"I dati sono stati organizzati in diverse pagine!",      
         description:"Ora avrai Statistiche: Rapide, Grafici, Tabelle e Confronti",      
      },
      {
         cover:<img src='/edit-layout-screen.jpg'></img>,      
         title:"Un nuovo editor di layout!",      
         description:"Con il pulsante apposito si attiverà l'editor per modificare dimensioni e posizioni di ogni grafico. Potrai usare il tasto di reset per tornare al layout originale. Quando inseriremo dei nuovi grafici, il layout si resetterà automaticamente!",      
      },
      {
         cover:<img src='/user-list.svg'></img>,      
         title:"Storico correzioni!",      
         description:"Per i clienti del settore ristorazione, è stato aggiunto il dato sulle correzioni presente nella nuova pagina dedicata alle tabelle",      
      },
      {
         cover:<img src='/document-list.svg'></img>,      
         title:"Filtro per tipi di documenti!",      
         description:"Abbiamo aggiunto per la ristorazione anche il filtro per analizzare i dati, basandosi sulla tipologia di documento (Es. Scontrini, Fattura, Ricevuta...)",      
      },
      {
         cover:<img src='/marketing.svg'></img>,     
         title:"Preparati al MARKETING!",      
         description:"Eh si! C'è un'importante versione in arrivo, il Marketing Selettivo che per ora è in Coming Soon è prossimo al rilascio... Prepariamoci!",      
      },
      {
         cover:<img src='/tour-info.jpg'></img>, 
         title:"Dubbi o serve aiuto?",      
         description:"Se hai qualche dubbio clicca l'apposito tasto di Informazioni per far partire il Tour che ti spiegherà nella attuale pagina cosa puoi fare",      
      },
      {
         cover:<img src='/changelog-list.svg'></img>,      
         title:"Tieni d'occhio il Changelog",      
         description:"Per scoprire tutte le novità delle versioni nel dettaglio, ti ricordo di guardare il changelog nella barra laterale cliccando sulla versione",      
      },
   ]

  return (
    <>
      <div className='version-label text-center mt-10' >
         <Badge key={"versionLabel"} dot={show} className={show && "pulse"}>
              <a href="#" onClick={onClick}>Novità della {currentVersion}</a>
         </Badge>
      </div>
           
      {/* ------------- modal con il changelog ------------ */}
      <Modal
         centered
         open={open}
         footer={false}
         onOk={() => setOpen(false)}
         onCancel={() => {
            resetChangelogFilter();
            setOpen(false)
         }}
         width={1000}
         className='changelog-modal' 
         destroyOnClose
      >
         <ChangeLogTitle />
           
         <ShowMore>
            {filteredChangelog && filteredChangelog.map((e, index) => {
               return(
                     <div key={`v-${index}`}>
                        <Typography.Title level={5}>{e.version}</Typography.Title>
                        {e.lines.map((l, index) => {
                           return (
                           <div key={`l-${index}`}>
                                 <Badge
                                    key={`b-${index}`}
                                    text={l.text}
                                    status={
                                       !l.type ? "default" :
                                       l.type === "fix" ? "error" :
                                       l.type === "add" ? "success" :
                                       l.type === "mod" ? "warning" : "default"
                                    }
                                 />
                                 <br></br>
                           </div>
                           )
                        })   
                        }
                     </div>
                  )
               })
              }
         </ShowMore>
        </Modal>
        {/* ------------- modal con il changelog ------------ */}
        
        {/* ------------- drawer con lo slider novità versione importanti ------------ */}

        <Drawer
         className='di-version-news-drawer'
         onClose={()=>setOpenDrawer(false)}
         title={`Novità della versione 1.3.0`}
         placement="bottom"
         height="95vh"
         open={openDrawer}
         destroyOnClose
        >
             <div className='di-version-carousel-wrapper h-100'>
               <Carousel className='h-100 text-center mt-20 mb-20' dots={false} ref={carouselRef}>
                 {carouselSlides.map((e, index) =>
                    <div className='di-news-slide' key={index} id={index}>
                       <div className='di-news-covers'><div className='img-wrapper'>{e.cover}</div></div>
                       <Typography className='fs-25 bold'>
                        {e.title}
                       </Typography>
                       <Typography className='fs-15'>
                        {e.description}
                       </Typography>
                    </div>
                  )
                 }
              </Carousel>
              <Button onClick={()=>carouselRef.current.next()} className='fw-bold fs-20' type='primary' size='large' block>
               Avanti
              </Button>
             </div>
        </Drawer>
        
        {/* ------------- drawer con lo slider novità versione importanti ------------ */}

    </>
  )
}
