import React, { useContext, useMemo } from 'react'

import { LOCAL_LAYOUT_GRAPHICS_RESTAURANT, LOCAL_LAYOUT_GRAPHICS_RETAIL } from '../../helpers/Globals';
import DisplayTop10Categories from '../data_display/DisplayTop10Categories';
import DisplayTop10Products from '../data_display/DisplayTop10Products';
import DisplayPaymentMethod from '../data_display/DisplayPaymentMethod';
import DisplaySelledProduct from '../data_display/DisplaySelledProduct';
import DisplayDailyEarn from '../data_display/DisplayDailyEarn';
import DiLayoutEditorWrapper from '../DiLayoutEditorWrapper';
import DisplayBrands from '../data_display/DisplayBrands';
import DashboardLayout from '../DashboardLayout';
import { Context } from '../../helpers/Context';
import FiltersDrawer from '../FiltersDrawer';

export default function PageGraphics() {

  const { isRestaurant } = useContext(Context);
  
  const displayTop10CategoriesKey = "displayTop10CategoriesKey";
  const displayTop10ProductsKey = "displayTop10ProductsKey";
  const displayPaymentMethodKey = "displayPaymentMethodKey";
  const displaySelledProductKey = "displaySelledProductKey";
  const displayDailyEarnKey = "displayDailyEarnKey";
  const displayBrandsKey = "displayBrandsKey";

  const minHeight = 16;
  const minWidth = 6;

  const restaurantLayout =
      {
        lg: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 0,    w: minWidth,   h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayTop10ProductsKey,      x: 12,  y: 0,    w: minWidth,   h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
        md: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 5,    w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayTop10ProductsKey,      x: 0,   y: 0,    w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
        sm: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 5,    w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayTop10ProductsKey,      x: 0,   y: 0,    w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
        xs: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 5,    w: 24,         h: 16,        minH: 16,        minW: minWidth },
          { i: displayTop10ProductsKey,      x: 0,  y: 0,     w: 24,         h: 16,        minH: 16,        minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: 16,        minH: 16,        minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
        xxs: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 5,    w: 24,         h: 11,        minH: 11,        minW: minWidth },
          { i: displayTop10ProductsKey,      x: 0,   y: 0,    w: 24,         h: 11,        minH: 11,        minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: 11,        minH: 11,        minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
      }
    const retailerLayout =
      {
        lg: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 0,    w: minWidth,   h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayTop10ProductsKey,      x: 12,  y: 0,    w: minWidth,   h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: minWidth,   h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayBrandsKey,             x: 12,  y: 10,   w: minWidth,   h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
        md: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 5,    w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayTop10ProductsKey,      x: 0,   y: 0,    w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayBrandsKey,             x: 12,  y: 10,   w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
        sm: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 5,    w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayTop10ProductsKey,      x: 0,   y: 0,    w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayBrandsKey,             x: 12,  y: 10,   w: 24,         h: minHeight, minH: minHeight, minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
        xs: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 5,    w: 24,         h: 16,        minH: 16,        minW: minWidth },
          { i: displayTop10ProductsKey,      x: 0,   y: 0,     w: 24,        h: 16,        minH: 16,        minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: 16,        minH: 16,        minW: minWidth },
          { i: displayBrandsKey,             x: 12,  y: 10,   w: 24,         h: 16,        minH: 16,        minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
        xxs: [
          { i: displayTop10CategoriesKey,    x: 0,   y: 5,    w: 24,         h: 11,        minH: 11,        minW: minWidth },
          { i: displayTop10ProductsKey,      x: 0,   y: 0,    w: 24,         h: 11,        minH: 11,        minW: minWidth },
          { i: displayPaymentMethodKey,      x: 0,   y: 10,   w: 24,         h: 11,        minH: 11,        minW: minWidth },
          { i: displayBrandsKey,             x: 12,  y: 10,   w: 24,         h: 11,        minH: 11,        minW: minWidth },
          { i: displayDailyEarnKey,          x: 0,   y: 30,   w: 24,         h: 40,        minH: 40,        minW: minWidth },
          { i: displaySelledProductKey,      x: 0,   y: 40,   w: 24,         h: 26,        minH: 26,        minW: minWidth },
        ],
      };

    const graphicsLayout = useMemo(() => isRestaurant ? restaurantLayout : retailerLayout, [isRestaurant]);

  return (
      <DashboardLayout>
        <div>
          <DiLayoutEditorWrapper
            layoutName={isRestaurant ? LOCAL_LAYOUT_GRAPHICS_RESTAURANT : LOCAL_LAYOUT_GRAPHICS_RETAIL }
            defaultLayout={graphicsLayout}
          >
              <div key={displayTop10ProductsKey}>
                <DisplayTop10Products/>
              </div>
              <div key={displayTop10CategoriesKey}>
                <DisplayTop10Categories/>
              </div>
              <div key={displayPaymentMethodKey}>
                 <DisplayPaymentMethod/>
              </div>
              <div key={displayDailyEarnKey}>
                 <DisplayDailyEarn/>
              </div> 
               <div key={displaySelledProductKey}>
                 <DisplaySelledProduct/>
              </div>
              {/* ---------- componenti solo per i retailers ----------- */}
              {!isRestaurant &&
                  <div key={displayBrandsKey}>
                      <DisplayBrands/>
                  </div>
              }
              {/* ---------- componenti solo per i retailers ----------- */}

          </DiLayoutEditorWrapper>
        </div>
      
        {/* ------- floating buttons della pagina -------  */}
          
          <FiltersDrawer />
          
        {/* ------- floating buttons della pagina -------  */}
      </DashboardLayout>
  )
}
