import React, { useContext, useEffect, useState } from 'react'

import { CloseCircleFilled, LayoutOutlined } from '@ant-design/icons';
import { Responsive, WidthProvider } from "react-grid-layout";
import { Badge, FloatButton, Tooltip } from 'antd';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { Context } from '../helpers/Context';
import DiScreenBadge from './DiScreenBadge';
import confirmModal from './DiConfirmModal';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function DiLayoutEditorWrapper({ children, layoutName, defaultLayout }) {
   
   const [width, setWidth] = useState(window.innerWidth);
   
   const [layouts, setLayouts] = useState(() => {
      // Inizializza il layout dallo storage o usa il layout di default
      const savedLayout = localStorage.getItem(layoutName);
      return savedLayout ? JSON.parse(savedLayout) : defaultLayout;
   });

   const context = useContext(Context);
   const { editLayout, refResetLayout, toggleLayout } = useContext(Context);

   //-------- allargo la griglia quanto lo schermo per la responsività -------
   useEffect(() => {
      const handleResize = () => {
         setWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);

      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);
   //-------- allargo la griglia quanto lo schermo per la responsività -------

   //-------- aggiorno lo stato del layout e lo salvo nel local storage -------
   const handleLayoutChange = (currentLayout, allLayouts) => {
      setLayouts(allLayouts);
      localStorage.setItem(layoutName, JSON.stringify(allLayouts));
   };
   //-------- aggiorno lo stato del layout e lo salvo nel local storage -------
   
   //-------- resetta i layout -------

   const resetLayout = async () => {
      const result = await confirmModal(
         "Stai per tornare al layout di base, sicuro di voler continuare?",
         context
      ); //attendo la risposta dal componente di conferma modale
      if (result) {
         localStorage.removeItem(layoutName)
         setLayouts(defaultLayout)
      }
      toggleLayout();
      return
   }

   //-------- resetta i layout -------

   return (
     <>
      {layouts &&
         <>
            <DiScreenBadge message="Editor Attivo" className={editLayout && "active"}/>
            <ResponsiveGridLayout
               className={`draggable-layout ${editLayout && "cursor-move"}`}
               layouts={layouts}
               cols={{ lg: 12, md: 8, sm: 6, xs: 4, xxs: 4 }} // Numero di colonne per ogni dimensione
               rowHeight={30}
               width={width} // Usa la larghezza della finestra dinamicamente
               onLayoutChange={handleLayoutChange}
               isResizable={editLayout}
               isDraggable={editLayout}
               autoSize={true}
               margin={[20, 20]}
               resizeHandles={["ne","se","nw","sw"]} 
               handle={
                  <span
                     style={{
                        width: "20px",
                        height: "20px",
                        background: "blue",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        position: "absolute",
                        cursor: "se-resize",
                     }}
                  />
               }
            >
                  {children}
            </ResponsiveGridLayout>

            {editLayout &&
               <Tooltip title="Reset Layout">
                  <FloatButton
                     ref={refResetLayout}
                     badge={{count:<CloseCircleFilled/>}}
                     icon={<LayoutOutlined/>}
                     onClick={resetLayout}
                     className="di-fab-reset-layout"
                     type="primary"
                  />
               </Tooltip>
            }
        </>
      }
     </>
  )
}
