// breakpoints.js

// Dimensioni viewport secondo i breakpoint di Ant Design
const breakpoints = {
  xs: 576,    // schermi piccoli, telefoni
  sm: 768,    // schermi medi, tablet
  md: 992,    // schermi larghi, desktop piccoli
  lg: 1200,   // schermi extra-large, desktop grandi
  xl: 1600,   // schermi molto larghi, grandi monitor
  xxl: 2000   // schermi extra-extra-large, monitor ultra-wide
};

// Helper per verificare se la viewport corrisponde a un breakpoint
const isBreakpoint = (size) => window.innerWidth >= breakpoints[size];

// Esempio di utilizzo per i media query per l'applicazione
const getViewport = () => {
  const width = window.innerWidth;
  if (width < breakpoints.xs) return 'xs';
  if (width < breakpoints.sm) return 'sm';
  if (width < breakpoints.md) return 'md';
  if (width < breakpoints.lg) return 'lg';
  if (width < breakpoints.xl) return 'xl';
  if (width < breakpoints.xxl) return 'xxl';
  return 'xxl';
};

const ifViewportGreaterThan = (breakpoint) => {
  const width = window.innerWidth;

  if (width > breakpoints[breakpoint]) {
      return true
  } else {
      return false
  }
};

// Esportiamo i breakpoint e le funzioni di utilità
export { breakpoints, isBreakpoint, getViewport, ifViewportGreaterThan };
