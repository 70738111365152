import React, { useState } from 'react'

import { BellOutlined, DragOutlined, GiftOutlined, MailOutlined, MessageOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Card, Table, Typography } from 'antd';

export default function MarketingOperationCreateSelectPriority({selectedChannels}) {
    
  
  const filteredChannels = selectedChannels
    .filter(option => option.value !== 'coupon')    // Rimuove gli oggetti con `value` uguale a 'coupon'
    .map(({ children, ...rest }) => rest);          // Rimuove la proprietà `children` dagli oggetti rimanenti
  
  const couponChannel = selectedChannels.filter(option => option.value === "coupon")[0]; //recupero solo i dati del canale coupon
  
  const [dataSource, setDataSource] = useState(filteredChannels);

  
  const columns = [
    {
      title: 'Canale',
      dataIndex: 'value',
      render: (value) => {
        return (
          <div className='flex gap-10 fs-15'>
            <DragOutlined/>
            {
              value === "push-notification"    ? <BellOutlined />      :
              value === "email"                ? <MailOutlined />      :
              value === "sms"                  ? <MessageOutlined />   :
              value === "whatsapp"             ? <WhatsAppOutlined />  : ""
            }
            {
              value === "push-notification"    ? "Notifica Push"    :
              value === "email"                ? "E-Mail"           :
              value === "sms"                  ? "SMS"              :
              value === "whatsapp"             ? "WhatsApp"         : ""
            }
          </div>
        )
      },
    },
    {
      title: "Destinatari",
      dataIndex:"users"
    }
  ];

  const Row = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: 'move',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };
    

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <div className='di-operation-wizard-wrapper'>
        
      <Typography className='fs-18 vold'>Scegli l'ordine dei canali di comunicazione</Typography>
      <Typography>Per tutto ciò che contiene messaggistica (whatsapp, email, sms ece...) verrà stabilita la priorità. Se il cliente riceve il messaggio tramite il primo canale, non riceverà gli altri. Altrimenti il sistema continuerà a provare in ordine di priorità fino ad esaurirli</Typography>
      <div className='mt-20 channel-priority-table'>
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              pagination={false}
              rowKey="key"
              columns={columns}
              dataSource={dataSource}
            />
          </SortableContext>
        </DndContext>
      </div>

      {couponChannel &&
        <Card className='mt-20'>
          <Typography  className='fs-16'><GiftOutlined/> {couponChannel.users} Utenti riceveranno questo Coupon</Typography>
        </Card>
      }
    </div>
  )
}
