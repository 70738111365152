function countCorrectionsTotals(data) {
    let totalPriceIncrease = 0;
    let totalPriceDecrease = 0;
    let totalQtaIncrease = 0;
    let totalQtaDecrease = 0;
    let countIncrease = 0;
    let countDecrease = 0;

    let totalPriceAfterPreReceipt = 0;
    let totalPriceWhileService = 0;

    data.forEach(item => {
        let hasIncrease = false;
        let hasDecrease = false;
        let valueToAdd = 0;

        const { TipoModifica, PriceOLD, PriceNew, QtaOLD, QtaNew, PrecontoEmesso, DuranteServizio } = item;
        const qtaDiff = QtaNew - QtaOLD;
        const priceDiff = PriceNew - PriceOLD;

        // **Calcolo basato sul TipoModifica**
        switch (TipoModifica) {
            case "AQP":
            case "DQP":
                valueToAdd = PriceOLD * qtaDiff;
                break;
            case "AV":
                valueToAdd = PriceNew * qtaDiff;
                break;
            case "CV":
            case "CP":
                valueToAdd = -(QtaOLD * PriceOLD);
                break;
            case "APP":
            case "DPP":
            case "APV":
            case "DPV":
                valueToAdd = QtaOLD * priceDiff;
                break;
            case "CD":
                valueToAdd = -PriceNew;
                break;
            case "MM":
                valueToAdd = priceDiff;
                break;
            case "MS":
                valueToAdd = -priceDiff;
                break;
            default:
                valueToAdd = 0;
                break;
        }

        // **Gestione incremento/decremento**
        if (valueToAdd > 0) {
            totalPriceIncrease += valueToAdd;
            hasIncrease = true;
        } else if (valueToAdd < 0) {
            totalPriceDecrease += valueToAdd;
            hasDecrease = true;
        }

        // **Conteggio quantità aumentate o diminuite**
        if (qtaDiff > 0) {
            totalQtaIncrease += qtaDiff;
        } else if (qtaDiff < 0) {
            totalQtaDecrease += qtaDiff;
        }

        // **Calcolo separato per PrecontoEmesso e DuranteServizio**
        if (PrecontoEmesso) {
            totalPriceAfterPreReceipt += valueToAdd;
        }
        if (DuranteServizio) {
            totalPriceWhileService += valueToAdd;
        }

        // **Conteggio modifiche**
        if (hasIncrease) countIncrease++;
        if (hasDecrease) countDecrease++;
    });

    return {
        totalPriceIncrease,
        totalPriceDecrease,
        totalQtaIncrease,
        totalQtaDecrease,
        countIncrease,
        countDecrease,
        totalPriceAfterPreReceipt,
        totalPriceWhileService
    };
}

export { countCorrectionsTotals };


