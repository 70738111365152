import React, {useEffect, useMemo, useState } from 'react'

import { CloseOutlined, CreditCardOutlined, FilterOutlined, FormOutlined, GiftFilled, GiftOutlined, MobileOutlined, NotificationOutlined, SaveOutlined, StarFilled, UserOutlined } from '@ant-design/icons'
import { Badge, Button, Col, Collapse, ConfigProvider, Form, Input, message, Modal, Row, Tabs, Typography } from 'antd'
import { orange, cyan, yellow,  purple } from '@ant-design/colors';
import TextArea from 'antd/es/input/TextArea';

import MarketingCreateAudienceDescription from './MarketingCreateAudienceDescription';
import PersonalDataFilters from './filters_group/filters_profile/PersonalDataFilters';
import EcommerceFilters from './filters_group/filters_product/EcommerceFilters';
import FidelityFilters from './filters_group/filters_fidelity/FidelityFilters';
import SoftwareFilters from './filters_group/filters_sofwares/SoftwareFilters';
import MarketingAudienceTopCarousel from './MarketingAudienceTopCarousel';
import UsersFilters from './filters_group/filters_user/UsersFilters';
import { CreateAudienceDataContext } from '../../../helpers/Context';
import { nowDateTimeIta } from '../../../helpers/DateUtils';
import { PRIMARY_COLOR } from '../../../helpers/Globals';
import confirmModal from '../../DiConfirmModal';

// Funzione per verificare se un valore è "compilato"
const isValid = (value) => {
  if (typeof value === 'boolean') {
    return value === true; // Se è un booleano, è valido solo se è true
  }
  
  if (Array.isArray(value)) {
    // L'array è valido se contiene almeno un oggetto valido
    return value.some(item => {
      if (typeof item === 'object' && item !== null) {
        // L'oggetto è valido se ha almeno una chiave con valore valido
        return Object.keys(item).length > 0 && Object.values(item).some(val => val !== undefined && val !== null && val !== '');
      }
      return false; // Ignora valori non oggetto (es. undefined, null, ecc.)
    });
  }

  if (typeof value === 'object' && value !== null) {
    // Se è un oggetto, deve avere almeno una chiave con valore valido
    return Object.keys(value).length > 0 && Object.values(value).some(val => val !== undefined && val !== null && val !== '');
  }
  
  // Per qualsiasi altro tipo (stringhe, numeri, ecc.), consideriamo valido se non è vuoto
  return value !== undefined && value !== null && value !== '';
};

const isFormNotEmpty = (form) => {
  const formValues = form.getFieldsValue(true); // Ottiene tutti i valori del form

  // Controlla se almeno un campo ha un valore valido
  return Object.values(formValues).some((value) => {
    if (value === null || value === undefined || value === '') {
      return false; // Campi vuoti non contano
    }
    if (Array.isArray(value)) {
      return value.length > 0; // Gli array devono avere almeno un elemento
    }
    if (typeof value === 'object') {
      return Object.keys(value).length > 0; // Gli oggetti devono avere almeno una chiave valida
    }
    return true; // Qualsiasi altro valore (stringhe, numeri, booleani) è valido
  });
};


export default function MarketingAudienceCreateContent({contextValue}) {

  const [openResume, setOpenResume] = useState(false);
  const [formChanged, setFormChanged] = useState(0);
  const [formValues, setFormValues] = useState([])
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  // -------- gestione delle chiavi dei singoli collapse nei gruppi di filtri --------

  const keys = ['1', '2', '3', '4', '5', '6', '7','8','9'];
  const [activeKeysEcommerce, setactiveKeysEcommerce] = useState([])
  const [activeKeysFidelity, setactiveKeysFidelity] = useState([])
  const [activeKeysProfile, setactiveKeysProfile] = useState([])

  const explodeAllCollapse = () => {
    setactiveKeysEcommerce(keys);
    setactiveKeysFidelity(keys);
    setactiveKeysProfile(keys);
  }
  const closeAllCollapse = () => {
    setactiveKeysEcommerce([""]);
    setactiveKeysFidelity([""]);
    setactiveKeysProfile([""]);
  }
  // -------- gestione delle chiavi dei singoli collapse nei gruppi di filtri --------
  
  // -------- colori preset delle aree --------

  const colorProfile    = PRIMARY_COLOR;
  const colorFidelity   = orange[6];
  const colorEcommerce  = cyan[7];
  const colorUsers      = purple[5];
  const colorSoftwares  = yellow[6];
  
  // -------- colori preset delle aree --------
  
  // -------- costanti delle singole liste di dati ----------

    // dati personali
    const [formMarketingPolicy, setformMarketingPolicy] = useState(null)
    const [formActivationDates, setformActivationDates] = useState(null)
    const [formBirthdates, setformBirthdates] = useState(null)
    const [formGdprPolicy, setformGdprPolicy] = useState(null)
    const [formBirthdays, setformBirthdays] = useState(null)
    const [formSurnames, setformSurnames] = useState(null)
    const [formGenders, setformGender] = useState(null)
    const [formNames, setformNames] = useState(null)
    const [formAges, setformAges] = useState(null)

 
    const arrayProfileValues = useMemo(() => [
      formMarketingPolicy,
      formActivationDates,
      formBirthdates,
      formGdprPolicy,
      formBirthdays,
      formSurnames,
      formGenders,
      formNames,
      formAges
    ], [formMarketingPolicy, formActivationDates, formBirthdates, formGdprPolicy, formBirthdays, formSurnames, formGenders, formNames, formAges]);

    const [showProfileDot, setShowProfileDot] = useState(false);
    // dati personali

    // dati fidelity
    const [formTransactions, setformTransactions] = useState(null)
    const [formCampaigns, setformCampaigns] = useState(null)
    const [formCoupons, setformCoupons] = useState(null)

    const arrayFidelityValues = useMemo(() => [
      formTransactions,
      formCampaigns,
      formCoupons,
    ], [formTransactions, formCampaigns, formCoupons]);

    const [showFidelityDot, setShowFidelityDot] = useState(false);
    // dati fidelity
  

    // dati ecommerce
    const [formShippingPayments, setformShippingPayments] = useState(null)
    const [formProducts, setformProducts] = useState(null)
    const [formOrders, setformOrders] = useState(null)

    const arrayEcommerceValues = useMemo(() => [
      formShippingPayments,
      formProducts,
      formOrders,
    ], [formShippingPayments, formProducts, formOrders]);

    const [showEcommerceDot, setShowEcommerceDot] = useState(false);
    // dati ecommerce
  

    // dati software
    const [formSoftwares, setformSoftwares] = useState([])

    const [showSoftwaresDot, setShowSoftwaresDot] = useState(false);
    // dati software
  

    // Definisci un oggetto con i nomi delle variabili da settare
    const formFields = {
      activation_dates_list: setformActivationDates,
      marketing_policy: setformMarketingPolicy,
      birth_dates_list: setformBirthdates,
      gdpr_policy: setformGdprPolicy,
      surnames_list: setformSurnames,
      birthday_list: setformBirthdays,
      gender_list: setformGender,
      names_list: setformNames,
      age_list: setformAges,

      transactions_list: setformTransactions,
      campaigns_list:setformCampaigns,
      coupons_list: setformCoupons,

      shippings_payments_list: setformShippingPayments,
      products_list:  setformProducts,
      orders_list: setformOrders,

      softwares_list: setformSoftwares,
    };
  
  // -------- costanti delle singole liste di dati ----------
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  //---------- gestione del click sul submit e invio form finito ------------
  const handleFinish = (values) => {
    console.log(values)
    setFormValues(values);
    setOpen(true);
  }

  const onSubmitClick = async () => {
    try {
      const values = await form.validateFields();
    } catch (errorInfo) {
      Object.keys(errorInfo.errorFields).forEach((index) => {
        message.error(errorInfo.errorFields[index].errors)
      });
    }
  }
  //---------- gestione del click sul submit e invio form finito ------------

  
  //---------- controllo del cambio dati del form non inviato ------------
    useEffect(() => {
      
        const formValues = form.getFieldsValue();
        console.log(formValues);
        // Itera su ogni campo e aggiorna il relativo stato
        Object.keys(formFields).forEach((key) => {
          if (formValues[key] !== undefined) {
            formFields[key](formValues[key]); // Chiamata dinamica a setformXXX
          }
        });
        // Infine, salva tutti i valori nel contesto principale
        setFormValues(formValues);

    }, [formChanged])
    
    useEffect(() => {
        
      const ecommerceHasValidValues = arrayEcommerceValues.some(isValid);
      const fidelityHasValidValues = arrayFidelityValues.some(isValid);
      const profileHasValidValues = arrayProfileValues.some(isValid);
      const softwaresHasValidValues = formSoftwares.some(isValid); 
      
      setShowSoftwaresDot(softwaresHasValidValues);
      setShowEcommerceDot(ecommerceHasValidValues);
      setShowFidelityDot(fidelityHasValidValues);
      setShowProfileDot(profileHasValidValues);

    }, [arrayProfileValues, arrayFidelityValues, arrayEcommerceValues, formSoftwares]); // Trigger solo quando una delle variabili cambia
  //---------- controllo del cambio dati del form non inviato ------------

  //---------- reset dei campi filtri ------------
  

  const resetFormValues = () => {
    form.resetFields();
      // Itera su ogni campo e aggiorna il relativo stato
      Object.keys(formFields).forEach((key) => {
        if (formValues[key] !== undefined) {
          formFields[key]([]); // Chiamata dinamica a setformXXX
        }
      });
  };

  const deleteFields = async () => {
    const result = await confirmModal(
      "Cliccando su si, cancellerai tutti i filtri impostati fi ora. Sicuro di voler procedere?",
      contextValue
    ); //attendo la risposta dal componente di conferma modale
    if (result) {
      resetFormValues()
      setFormChanged(formChanged + 1);
      closeAllCollapse();
    }
  }
  //---------- reset dei campi filtri ------------

  
  return (
    <CreateAudienceDataContext.Provider value={{
      
        setactiveKeysFidelity,
        setactiveKeysProfile,
        setactiveKeysEcommerce,
        activeKeysEcommerce,
        activeKeysFidelity,
        activeKeysProfile,
        explodeAllCollapse,
        closeAllCollapse,
        keys,
          
        colorSoftwares, 
        colorEcommerce,
        colorFidelity,
        colorProfile,
        colorUsers,
          
        setFormChanged,
        formChanged,
        formValues,
        deleteFields,
        resetFormValues,
        
        formActivationDates,
        formMarketingPolicy,
        formGdprPolicy,
        formBirthdates,
        formBirthdays,
        formSurnames,
        formGenders,
        formNames,
        formAges,
        
        formTransactions,
        formCampaigns,
        formCoupons,

        formShippingPayments,
        formProducts,
        formOrders,
        
        formSoftwares,
        formFields,
      }}
    >
        {/* ------------ componente form che wrappa le singole tab che procurano i campi ------------ */}
        <Form
          form={form}
          name="audience_form"
          layout='vertical'
          onFinish={handleFinish}
          onValuesChange={()=>setFormChanged(formChanged + 1)}
        > 
            
          {/* ------------ Prima riga con il monitor utenti e azioni -----------— */}
          <div className='p-10'>
            <div className='di-card p-10 w-100'>
              <Row gutter={[10,10]} align={"space-between"}>
                  <Col className='flex'>
                    <div className='flex gap-20 align-center'>
                      <Badge count={12}>
                        <UserOutlined className='fs-25 color-primary' />
                      </Badge> 
                      <Typography className='bold'>Pubblico Potenziale</Typography>
                    </div>
                  </Col>
                  <Col xs={24} sm={14} md={12} lg={8} xl={6} xxl={8}>
                    <Row gutter={[10,10]} justify={"end"}>
                      {/* <Col xs={24} sm={12} span={12}>
                        <Button onClick={()=>setOpenResume(!openResume)} className='w-100'icon={<NotificationOutlined />}>
                          Spiega i filtri
                        </Button>
                      </Col> */}
                      <Col xs={24} sm={12} span={12}>
                        <Form.Item className='m-0'>
                          <Button onClick={onSubmitClick} htmlType="submit" className='w-100' type='primary' icon={<SaveOutlined />}>
                            Salva pubblico
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
              </Row>
            </div>
          </div>
          {/* ------------ Prima riga con il monitor utenti e azioni -----------— */}
    
          {/* ------------ accordion con carosello di pubblici consigliati -----------— */}
          <Collapse
            expandIconPosition='end'
            defaultActiveKey={["1"]}
            className='di-audience-collapse mt-20'
            bordered={false}
            items={[
              {
                key: '1',
                label: (
                  <div className='flex gap-5'>
                    <StarFilled className='color-yellow' />
                    <Typography.Text>I preset più usati dagli utenti</Typography.Text>
                  </div>
                ),
                children: <MarketingAudienceTopCarousel  form={form}/>,
              },
            ]}
          />
          {/* ------------ accordion con carosello di pubblici consigliati -----------— */}
    
          {/* ------------ tabs con sezioni di filtri dinamici -----------— */}
          <div className='flex align-center space-between gap-10 mt-20 px-15'>
            <div className='flex align-center gap-10'>
                <FilterOutlined className='color-primary'/>
                <Typography>
                  Scegli i filtri per il pubblico
                </Typography>
            </div>
            {(showEcommerceDot || showFidelityDot || showProfileDot || showSoftwaresDot) &&
              <Button icon={
                <Badge count={<CloseOutlined className='fs-8'/>}>
                  <FilterOutlined/>
                </Badge>
              }
                onClick={deleteFields}
              >
                Reset filtri
              </Button>
              }
          </div>
    
          
            <Tabs
              className='di-audience-filters-group-tabs mt-20'
              defaultActiveKey="1"
              type="card"
              size="large"
              items={[
                {
                  key: '1',
                  forceRender: true,
                  icon: <FormOutlined />,
                  label:
                    <Badge color={colorProfile} dot={showProfileDot} className={showProfileDot && "pulse"}>
                        Anagrafica
                    </Badge>,
                  children: (
                      <div className='di-tab-audience-filter filters-group-profile'>
                         <PersonalDataFilters form={form} />
                      </div>
                  ),
                },
                {
                  key: '2',
                  forceRender:true,
                  icon: <GiftOutlined />,
                  label:
                    <Badge color={colorFidelity}  dot={showFidelityDot} className={showFidelityDot && "pulse"}>
                        Fidelity / Coupons
                    </Badge>,
                  children: (
                    <ConfigProvider
                       theme={
                        {
                          token:{
                            colorPrimary: colorFidelity,
                            }
                          }
                        }
                    >
                      <div className='di-tab-audience-filter filters-group-fidelity'>
                        <FidelityFilters form={form} />
                      </div>
                    </ConfigProvider>
                  ),
                },
                {
                  key: '3',
                  forceRender:true,
                  icon: <CreditCardOutlined />,
                  label:
                    <Badge color={colorEcommerce} dot={showEcommerceDot} className={showEcommerceDot && "pulse"}>
                        Venduto
                    </Badge>,
                  children: (
                    <ConfigProvider
                        theme={
                          {
                              token:{
                                colorPrimary: colorEcommerce ,
                              }
                            }
                          }
                      >
                        <div className='di-tab-audience-filter filters-group-ecommerce'>
                          <EcommerceFilters form={form} />
                        </div>
                    </ConfigProvider>
                  ),
                },
                {
                  key: '4',
                  forceRender: true,
                  icon: <UserOutlined />,
                  label: 'Lista Utenti',
                  children: (
                      <ConfigProvider
                        theme={
                          {
                              token:{
                                colorPrimary:colorUsers,
                              }
                            }
                          }
                      >
                      <div className='di-tab-audience-filter filters-group-users'>
                        <UsersFilters form={form} />
                      </div>
                    </ConfigProvider>
                  ),
                },
                {
                  key: '5',
                  forceRender: true,
                  icon: <MobileOutlined />,
                  label:<Badge color={colorSoftwares} dot={showSoftwaresDot} className={showSoftwaresDot && "pulse"}>Software (Bacco, Signa, WebApp...)</Badge>,
                  children: (
                    <ConfigProvider
                      theme={
                        {
                            token:{
                              colorPrimary: colorSoftwares ,
                            }
                          }
                        }
                    >
                      <div className='di-tab-audience-filter filters-group-softwares'>
                        <SoftwareFilters form={form} />
                      </div>
                   </ConfigProvider>
                  ),
                },
              ]}
            />
          {/* ------------ tabs con sezioni di filtri dinamici -----------— */}
    
    
          <Modal
            title="Prima di salvare..."
            onCancel={handleCancel}
            cancelText="Annulla"
            onOk={handleOk}
            okText={<><SaveOutlined /> Salva</>}
            open={open}
          >
            <Typography className='mb-20'>Diamo a questo pubblico alcune info per permetterti di capire subito il target</Typography>
            <Input size="large" placeholder={`Pubblico del ${nowDateTimeIta}`} prefix={<UserOutlined />} />
            <TextArea
              showCount
              className='mt-10 mb-20'
              maxLength={100}
              placeholder="Descrizione del pubblico"
              style={{
                height: 120,
              }}
            />
          </Modal>
  
    
    
          <Modal
            title="Ecco un riassunto dei tuoi filtri..."
            open={openResume}
            footer={false}
            closable
            onCancel={()=>setOpenResume(false)}
            onClose={()=>setOpenResume(false)}
          >
            <MarketingCreateAudienceDescription/>
          </Modal>
  
        </Form>
        {/* ------------ componente form che wrappa le singole tab che procurano i campi ------------ */}
    </CreateAudienceDataContext.Provider>
  )
}
