import { CalendarOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Col, Collapse, Form, Typography } from 'antd'
import React from 'react'

export default function MarketingDynamicDatesCollapse({child, label}) {
  return (
       <Collapse
         expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
         expandIconPosition='end'
         bordered={false}
         className='w-100 bg-dark-02 mt-10'
         items={[
         {
            key: 'collapse-dates',
            label: (
               <Typography className="mb-5 marketing-field-label mt-10">
                  <CalendarOutlined/> Date {label}
               </Typography>
            ),
            
            children: (
               <div>
                  {child}
               </div>
            ),
         },
         ]}
      />
  )
}
