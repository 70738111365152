import { Col, Row, Typography } from 'antd'

import React from 'react'
import * as Unicons from '@iconscout/react-unicons';

import { nowTime } from '../../../helpers/DateUtils'
import { BackwardOutlined, BorderOutlined, LeftOutlined, MenuOutlined, WifiOutlined, } from '@ant-design/icons';

export default function MarketingDeviceLayout({children, appIcon, appName, hideTopBar}) {
  return (
    <div className="device-frame">
      <div className='device-screen flex column space-between h-100'>
           {!hideTopBar &&
            <div className='device-topbar'>
               <Row gutter={[10,10]} justify={"space-between"}> 
                  <Col>
                     {nowTime}
                  </Col>
                  <Col>
                  <div className='flex align-center gap-5'>
                     <WifiOutlined/>
                     <Unicons.UilBatteryBolt/>
                  </div>
                  </Col>
               </Row>
            </div>
           }

         {(appIcon || appName) && 
            <>
               <div className='device-content-appbar bg-dark-02'>
                  {appIcon}
                  <Typography className='fs-14 bold'>{appName}</Typography>
               </div>
            </>
         }
         <div className='device-content'>
            {children}
         </div>
         <div className='device-navbar'>
            <Row gutter={[10,10]} justify={"space-between"}> 
               <LeftOutlined />
               <BorderOutlined/>
               <MenuOutlined rotate={90} />
            </Row>
         </div>
      </div>
   </div>
  )
}
