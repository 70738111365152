import React from "react";

import { Form, Row, Col, Select, InputNumber, DatePicker, Button, Typography, Collapse } from "antd";
import { CalendarOutlined, CaretRightOutlined, PlusOutlined } from "@ant-design/icons";

import MarketingNumberConditionSelect from "../../../MarketingNumberConditionSelect";
import MarketingRemoveFieldIcon from "../../../MarketingRemoveFieldIcon";
import { dateHourFormatIta } from "../../../../../helpers/DateUtils";
import MarketingButtonAddField from "../../../MarketingButtonAddField";
import MarketingDateField from "../../../MarketingDateField";
import MarketingDynamicDatesCollapse from "../../../MarketingDynamicDatesCollapse";

const FidelityFiltersTransactions = ({ form, labelValue }) => {
  
  const label = labelValue || "Transazioni effettuate";

  const onChange = (date, fieldName, index) => {
    if (date) {
      const formattedDate = date.format("DD/MM/YYYY HH:mm:ss");

      // Ottieni lo stato attuale delle transazioni
      const transactions = form.getFieldValue("transactions") || [];

      // Aggiorna solo il campo specifico nell'array
      transactions[index] = {
        ...transactions[index], // Mantieni gli altri valori
        [fieldName]: formattedDate, // Aggiorna solo il campo specifico
      };

      // Aggiorna l'intero array nel form
      form.setFieldsValue({ transactions });
      return formattedDate;
    } else {
      const transactions = form.getFieldValue("transactions") || [];
      transactions[index] = {
        ...transactions[index],
        [fieldName]: null,
      };

      form.setFieldsValue({ transactions });
      return null;
    }
  };



  return (
    <Form.List name="transactions_list" initialValue={[]}>
      {(fields, { add, remove }) => (
        <>
          <Row gutter={[10, 10]} className="mb-20">
            {fields.map(({ key, name, ...restField }) => (
              <Col
                xs={24}
                md={12}
                lg={12}
                xl={8}
                key={key}
                className="flex align-start justify-start relative"
              >
                <Row gutter={[10, 10]} className="w-100 di-filters-group-wrapper">
                  {/* Campo per il numero di transazioni */}
                  <Col span={24}>
                    <Typography className="mb-5 marketing-field-label">
                      {label}
                    </Typography>
                    <Row gutter={[10, 10]}>
                      <Col xs={24} sm={12} lg={24} xl={24} xxl={12}>
                        <MarketingNumberConditionSelect
                          nameValue={[name, 'condition']}
                          keyValue={[key, 'condition']}
                          initialValue="equal"
                          required
                        />
                      </Col>
                      <Col xs={24} sm={12} lg={24} xl={24} xxl={12}>
                        <Form.Item
                          {...restField}
                          className="w-100"
                          name={[name, "amount"]}
                          key={[key, "amount"]}
                          rules={[
                            {
                              type: "number",
                              message: `Il totale ${label} deve essere un numero!`,
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="Infinito"
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  {/* Campo per la selezione delle date */}
                  <MarketingDynamicDatesCollapse
                    label={"Transazioni"}
                    child={
                        <Form.Item>
                          <Form.List name={[name, 'transactions_dates']}>
                            {(subFields, subOpt) => (
                              <>
                                <div className='dynamic-date-wrapper justify-center'>
                                    {subFields.map((subField, index) => (
                                      <div className="radius-2 p-10 bg-dark-02 dynamic-date-child mw-sm" key={index}>
                                        <MarketingDateField listName={[name, 'transactions_dates']} field={subField} index={index} opt={subOpt} />
                                      </div>
                                    ))}
                                </div>
                                <div className="mt-20"><MarketingButtonAddField onClick={() => subOpt.add()} label={"Data"}/></div>
                              </>
                            )}
                          </Form.List>
                      </Form.Item>
                    }
                  />

                </Row>

             
                {/* Pulsante per rimuovere il campo */}
                <MarketingRemoveFieldIcon onClick={() => remove(name)} />
              </Col>
            ))}
          </Row>

          <MarketingButtonAddField
            onClick={() => add()} 
            label={label}
          />
          
        </>
      )}
    </Form.List>
  );
};

export default FidelityFiltersTransactions;
