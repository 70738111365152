import React from 'react'

import TableAudience from '../../../../tables/TableAudience'

export default function UsersFilters() {
  return (
    <div className='mt-20 mb-20 p-10'>
      <TableAudience />
    </div>
  )
}
