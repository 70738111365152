import React from 'react'

import { Col, Row, Select, Typography } from 'antd'

import TableAudience from '../../tables/TableAudience'

export default function MarketingAudienceShowContent({contextValue}) {
  
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
    

  return (
    <div className='p-10'>
      
      {/* ---------- sezione filtri  ---------- */}
      <div className='di-card mb-20'>
        <Row>
          <Typography>Filtri</Typography>
        </Row>
        <Row className='mt-10' gutter={[20,20]}>
          <Col span={4}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Filtra pubblico"
              className='w-100 min-w-150'
              onChange={handleChange}
              options={options}
            />
          </Col>
          <Col span={4}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Data di Nascita"
              className='w-100 min-w-150'
              onChange={handleChange}
              options={options}
            />
          </Col>
          <Col span={4}>
            <Select
              onChange={handleChange}
              placeholder="Indirizzo"
              options={options}
              className='w-100 min-w-150'
              mode="multiple"
              allowClear
            />
          </Col>
        </Row>
      </div>
      {/* ---------- sezione filtri  ---------- */}

      {/* ---------- sezione tabella con dati  ---------- */}
      <div className='di-card'>
        <TableAudience contextValue={contextValue}/>
      </div>
      {/* ---------- sezione tabella con dati  ---------- */}

    </div>
  )
}
