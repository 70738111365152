import React, { useContext } from 'react'

import { Col, ColorPicker, Divider, Row, theme } from 'antd'
import { cyan, generate, green, presetPalettes, red } from '@ant-design/colors';
import { CreateOperationDataContext } from '../../../helpers/Context';

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));

export default function MarketingOperationColorPicker({fieldName}) {
   
   const {form, handleFormChange} = useContext(CreateOperationDataContext)

   const { token } = theme.useToken();
   const presets = genPresets({
      primary: generate(token.colorPrimary),
      red,
      green,
      cyan,
   });

  return (
     <ColorPicker
      popupStyle={{
         width: '100%',
      }}
      placement="right" 
      defaultValue={token.colorPrimary}
      onChangeComplete={(color) => {
         const hexColor = color.toHexString(); // Converte il colore in HEX
         form.setFieldValue(fieldName, hexColor);
         handleFormChange();
      }}
      presets={presets}
    />
  )
}
