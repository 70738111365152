import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import { PieChartOutlined, DiffOutlined, UsergroupAddOutlined, NotificationOutlined, OrderedListOutlined, DashboardOutlined } from '@ant-design/icons';
import {  Divider, Menu, Select, Spin, Typography } from 'antd';

import { Context } from '../helpers/Context';
import DiScrollspy from './DiScrollspy';
import BetaBadge from './BetaBadge';

//costruzione del singolo elemento di menù
function getItem(label, key, icon, className, children) {
  return {
    key,
    icon,
    children,
    label,
    className
  };
}


//elementi del menu e del menu secondario


function DashboardMenu({hideShops}) {

  const [path] = useState(window.location.pathname);
  const [activeKey, setActiveKey] = useState("");

  const navigate = useNavigate();

  const {
    shopsListLoading,
    isRestaurant,
    setShopName,
    shopsList,
    setShop,
    setOpen,
    shop,
  } = useContext(Context);


  // ------------- definisco i menu della navigazione ------------- 
  const primaryMenu =
    [
      getItem("Rapide", 'fast-stats', <DashboardOutlined />),
      getItem('Grafici', 'graphics', <PieChartOutlined />),
      getItem('Tabelle', 'tabulations', <OrderedListOutlined />),
      getItem("Confronta", 'compare', <DiffOutlined />),
    ];
  
  const marketingMenu = 
      [
      getItem('Pubblico', 'marketing/audiences', <UsergroupAddOutlined />),
      getItem('Operazioni', 'marketing/marketing-operations', <NotificationOutlined />),
    ];
  // ------------- definisco i menu della navigazione ------------- 
 
  function clickItem(e){ 
    navigate("/" + e.key);
  };

  useEffect(() => {
    const activePath = path.substring(1);
    setActiveKey(activePath === "" ? "fast-stats" : activePath)
  }, [path])
  
  return (
    <div className='di-navigation-menu-wrapper'>
      <div className='mt-10'>
        <Typography>Statistiche</Typography>
      </div>
      {/*-------------  menu principale -------------*/}
      <Menu className="di-navigation primary mt-5" theme="light" selectedKeys={activeKey} mode="inline" items={primaryMenu} onClick={clickItem}/>
      {/*-------------  menu principale -------------*/}

      {/*------------- marketing divider -------------*/}
      <div className='mt-10 relative'>
        <Typography>
          Marketing
          <BetaBadge text={"Coming Soon"}/>
        </Typography>
      </div>
      {/*------------- marketing divider ------------- */}

      {/*------------- menu secondario -------------*/}
      <Menu disabled className="di-navigation primary mt-5" theme="light" selectedKeys={activeKey} mode="inline" items={marketingMenu} onClick={clickItem}/>
      {/*------------- menu secondario -------------*/}

      <Divider />
      
      {!hideShops &&
        <div className='secondary-menu'>
          {/* filtri per cambiare statistiche rapide sull'ora corrente o tutto il giorno precedente */}
              <Divider >
                <span className='m-2'>{`Filtra per ${!isRestaurant ? "Negozio" : "Ristorante "}`}</span>
              </Divider>
              <Spin spinning={shopsListLoading}>
                <Select
                  showSearch
                  placeholder="Seleziona Negozio"
                  optionFilterProp="children"
                  className='w-100 min-w-150'
                  allowClear
                  value={shop}
                  onClear={()=>{
                    setShop("");
                    setShopName("");
                    setOpen(false);
                  }}
                  onChange={
                    (value, i) => {
                      if (!i) return;
                      setShopName(i.label);
                      setOpen(false);
                      setShop(value ?? "");
                      sessionStorage.setItem("__di_public_code_filter",value)
                    }
                  }
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={shopsList}
                />
                <Divider/>
              </Spin>
              {/* filtri per cambiare statistiche rapide sull'ora corrente o tutto il giorno precedente */}
        </div>
      }
    </div>
  );
}

export default DashboardMenu;