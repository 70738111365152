import React, { useState, useEffect } from 'react';

import { Checkbox, Divider, Form, InputNumber, Row, Select, Switch, Tag, Typography } from 'antd';
import { CalendarOutlined, ControlOutlined } from '@ant-design/icons';

import MarketingNumberConditionSelect from './MarketingNumberConditionSelect';
import MarketingRemoveFieldIcon from './MarketingRemoveFieldIcon';
import DiRangePicker from '../DiRangePicker';


export default function MarketingDateField({ form, field, opt, index, listName }) {

  const [advancedMode, setAdvancedMode] = useState(false);
  

  // Stato per tracciare i valori dei tipi di giorno, mese e anno
  const [dayType, setDayType] = useState('all');
  const [monthType, setMonthType] = useState('all');
  const [yearType, setYearType] = useState('all');
  
  // Stato per tracciare la visibilità dei campi
  const [visibility, setVisibility] = useState({
    dayOffset: false,
    dayCustom: false,
    monthOffset: false,
    monthCustom: false,
    yearOffset: false,
    yearCustom: false,
  });

  // Funzione per gestire il cambiamento del tipo di giorno
  const handleDayTypeChange = (value) => {
    setDayType(value);
  };

  // Funzione per gestire il cambiamento del tipo di mese
  const handleMonthTypeChange = (value) => {
    setMonthType(value);
  };

  // Funzione per gestire il cambiamento del tipo di anno
  const handleYearTypeChange = (value) => {
    setYearType(value);
  };

  // Funzione per aggiornare la visibilità dei campi in base ai tipi
  useEffect(() => {
    setVisibility({
      dayOffset: dayType === 'now', // Mostra offset giorno se 'now'
      dayCustom: dayType === 'custom', // Mostra custom giorno se 'custom',
      monthOffset: monthType === 'now', // Mostra offset mese se 'now'
      monthCustom: monthType === 'custom', // Mostra custom mese se 'custom'
      yearOffset: yearType === 'now', // Mostra offset anno se 'now'
      yearCustom: yearType === 'custom', // Mostra custom anno se 'custom'
    });
  }, [dayType, monthType, yearType]); // Quando uno dei tipi cambia, aggiorniamo la visibilità

  return (
    <div className='relative'>
      <Row className='mb-10'>
        <div className='flex gap-5'>
          <Switch
            onChange={() => setAdvancedMode(!advancedMode)}
            unCheckedChildren={<CalendarOutlined/>}
            checkedChildren={<ControlOutlined />}
          />
          <Typography>Modalità {advancedMode ? "Avanzata" : "Semplice"}</Typography>
        </div>
        <Divider className='mt-10 mb-10'/>
      </Row>
      {advancedMode ?
          <div className="marketing-advanced-mode-date p-10" key = { index } >
            {/* Sezione Confronto */}
            <div className="marketing-data-field-col">
              <Typography className="mb-5 marketing-field-label">Confronto</Typography>
              <MarketingNumberConditionSelect allowClear nameValue={[field.name, 'date_condition']} date />
            </div>
    
            {/* Sezione Giorno */}
            <div className="marketing-data-field-col mt-10">
              <Typography className="mb-5 marketing-field-label">Giorno</Typography>
              <div className='border-yellow flex-wrap w-100'>
                <Form.Item noStyle name={[field.name, 'day_type']}>
                  <Select
                    allowClear
                    className="min-w-150 w-100 mb-10"
                    options={[
                      { label: 'Qualsiasi', value: 'all' },
                      { label: 'Oggi', value: 'now' },
                      { label: 'Personalizzato', value: 'custom' },
                    ]}
                    onChange={handleDayTypeChange}
                  />
                </Form.Item>
                {(visibility.dayOffset || form?.getFieldValue([listName, field.name])?.day_offset) && (
                  <>
                    <Tag className='mb-10'>+ o -</Tag>
                    <Form.Item noStyle key={[field.name, 'day_offset']}  name={[field.name, 'day_offset']}>
                      <InputNumber placeholder="+2 giorni" />
                    </Form.Item>
                  </>
                )}
                {(visibility.dayCustom || form?.getFieldValue([listName, field.name])?.day_custom) && (
                  <Form.Item  noStyle key={[field.name, 'day_custom']} name={[field.name, 'day_custom']}>
                    <InputNumber placeholder="Giorno" min={1} max={31} />
                  </Form.Item>
                )}
              </div>
            </div>
    
            {/* Sezione Mese */}
            <div className="marketing-data-field-col mt-10">
              <Typography className="mb-5 marketing-field-label">Mese</Typography>
              <div className='border-blue flex-wrap w-100'>
                <Form.Item noStyle name={[field.name, 'month_type']} >
                  <Select
                    allowClear
                    className="min-w-150 w-100 mb-10 border-blue"
                    options={[
                      { label: 'Qualsiasi', value: 'all' },
                      { label: 'Questo mese', value: 'now' },
                      { label: 'Personalizzato', value: 'custom' },
                    ]}
                    onChange={handleMonthTypeChange}
                  />
                </Form.Item>
                {(visibility.monthOffset || form?.getFieldValue([listName, field.name])?.month_offset) && (
                  <>
                    <Tag className='mb-10'>+ o -</Tag>
                    <Form.Item noStyle name={[field.name, 'month_offset']}>
                      <InputNumber placeholder="+2 mesi" />
                    </Form.Item>
                  </>
                )}
                {(visibility.monthCustom || form?.getFieldValue([listName, field.name])?.month_custom) && (
                  <Form.Item noStyle name={[field.name, 'month_custom']}>
                    <InputNumber placeholder="Mese" min={1} max={12} />
                  </Form.Item>
                )}
              </div>
            </div>
    
            {/* Sezione Anno */}
            <div className="marketing-data-field-col mt-10">
              <Typography className="mb-5 marketing-field-label">Anno</Typography>
              <div className='border-green flex-wrap w-100'>
                <Form.Item noStyle name={[field.name, 'year_type']}>
                  <Select
                    allowClear
                    className="min-w-150 w-100 mb-10"
                    options={[
                      { label: 'Qualsiasi', value: 'all' },
                      { label: 'Quest’anno', value: 'now' },
                      { label: 'Personalizzato', value: 'custom' },
                    ]}
                    onChange={handleYearTypeChange}
                  />
                </Form.Item>
                {(visibility.yearOffset || form?.getFieldValue([listName, field.name])?.year_offset) && (
                  <>
                    <Tag className='mb-10'>+ o -</Tag>
                    <Form.Item noStyle name={[field.name, 'year_offset']}>
                      <InputNumber placeholder="+2 anni" />
                    </Form.Item>
                  </>
                )}
                {(visibility.yearCustom || form?.getFieldValue([listName, field.name])?.year_custom) && (
                  <Form.Item noStyle name={[field.name, 'year_custom']}>
                    <InputNumber placeholder="Anno" min={1000} max={9999} />
                  </Form.Item>
                )}
              </div>
            </div>
    
          </div>
        :

          // ------ modalità data semplice ------ 
          <div className='marketing-simple-mode-date align-start w-100 flex gap-10 flex-wrap'>
            
            <Form.Item  key={[field.name, 'full_date']} name={[field.name, 'full_date']} className='m-0'>
              <DiRangePicker
                value={form ? form.getFieldValue([field.name, 'full_date']) || undefined : undefined}
                enableAllDate
                outlined
              />
            </Form.Item>
          
            <Form.Item
              key={[field.name, 'update_automatically']}
              name={[field.name, 'update_automatically']}
              valuePropName="checked"
              className='m-0'
            >
              <Checkbox ><Typography className='fs-16'>Aggiorna automaticamente</Typography></Checkbox>
            </Form.Item>

          </div>
          // ------ modalità data semplice ------ 

      }

      {/* Icona di rimozione del campo */}
      <MarketingRemoveFieldIcon trashIcon onClick={() => opt.remove(field.name)} />
    </div>
  );
}

