import { Col, Row, Typography } from 'antd'
import React from 'react'
import ChannelCouponForm from './ChannelCouponForm'
import ChannelCouponPreview from './ChannelCouponPreview'


export default function ChannelCouponContent() {
   
  return (
    <div className='operations-group-coupons'>
       <Row gutter={[20,20]} className='mt-20'>
           <Col xs={24} md={14}>
              <ChannelCouponForm/>
           </Col>
           <Col xs={24} md={10} className=''>
              <div className='sticky top-20'>
                  <Typography className='fs-18 bold mb-10 text-center'>
                     Preview
                  </Typography>
                  <ChannelCouponPreview/>
              </div>
           </Col>
        </Row>
    </div>
  )
}
