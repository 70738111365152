import React, { useContext, useEffect, useState } from 'react'

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker, Row, Spin,} from 'antd'
import dayjs from 'dayjs';

import { ENDPOINT_PAYMENTS_DATA, ENDPOINT_PERIOD_SALES } from '../../helpers/Globals';
import DisplayComparedData from '../data_display/DisplayComparedData';
import DashboardLayout from '../DashboardLayout';
import { Context } from '../../helpers/Context';
import FiltersDrawer from '../FiltersDrawer';
import { ApiCall } from '../../Backoffice';
import NoData from '../NoData';


dayjs.extend(customParseFormat);

export default function Compare() {
   
   const [selectedDays, setSelectedDays] = useState([]);
   const [loading, setLoading] = useState(false);
   const [render, setRender] = useState(false);

   const [data, setData] = useState([]);
   
   const { shop, serviceNotification, accessToken } = useContext(Context);
   
   const onChange = (date, dateString) => {
      
      dateString = dateString.filter(v => v !== ''); // tolgo le stringhe vuote dall'array di date
      
      if (!dateString.length) {
         setSelectedDays([]);
         setData([]);
         return;
      };
      if (dateString.length > 5) {
         serviceNotification("Puoi confrontare massimo 5 giorni in contemporanea");
         return;
      } else {
         const tempSelected = [];

         dateString.forEach(dayString => {
            tempSelected.push(dayjs(dayString, 'DD-MM-YYYY'));
         });

         setSelectedDays(tempSelected);
      };
   }; 

   useEffect(() => {

      if (selectedDays.length === 0) return;
   
      setLoading(true);
      var temp = [];

      // -------- ciclo ogni giorno selezionato per chiamarne i dati ------------
      selectedDays.forEach(day => {
         
         const dayString = dayjs(day).format("DD-MM-YYYY");
         const dayQuery = dayjs(day).format("YYYY-MM-DD");
         
         // -------------- definisco l'oggetto che comporrà i giorni per il grafico ------------
         var obj = {
            "Giorno": dayString,
            "Incasso": 0,
            "Documenti": 0
         };
         // -------------- definisco l'oggetto che comporrà i giorni per il grafico ------------

         // -------------- creo le chiamate da chiamare in una promise all per aspettare i dati ------------
         const fetchEarn = async () => {
            try {
               const json = await ApiCall(`${ENDPOINT_PAYMENTS_DATA}?date=${dayQuery}T00:00:00&end_date=${dayQuery}T23:59:59&shop=${shop}`, "GET", `${accessToken}`, null)
               var totalEarn = 0;
               if (json) {
                  json.forEach(paymentMethod => {
                     totalEarn += paymentMethod.amount;
                  });
                  return totalEarn; 
               }
            } catch (err) {
               console.log(err);
            }
         };
         const fetchReceipt = async () => {
            try {
               const json = await ApiCall(`${ENDPOINT_PERIOD_SALES}?date=${dayQuery}T00:00:00&end_date=${dayQuery}T23:59:59&shop=${shop}`, "GET", `${accessToken}`, null)
               if (json) {
                  return json.sales.length;
               }
            } catch (err) {
               console.log(err);
            }
         };
         // -------------- creo le chiamate da chiamare in una promise all per aspettare i dati ------------


         // -------------- faccio le due chiamate e attendo tutti i dati con promiseall  ------------
         const fetchAllTheThings = async () => {
            try {
               const [totalEarn, totalReceipt] = await Promise.all([
                  fetchEarn(),
                  fetchReceipt(),
               ]);

               if (totalEarn !== null && totalReceipt !== null) {
                  obj["Incasso"] = parseFloat(parseFloat(totalEarn).toFixed(2));
                  obj["Documenti"] = totalReceipt;
                  temp.push(obj);
                
                  if (temp.length === selectedDays.length) {
                     // Funzione per convertire stringa data in oggetto Date
                     function convertiData(dataStr) {
                        const [giorno, mese, anno] = dataStr.split('-').map(Number);
                        return new Date(anno, mese - 1, giorno); // mese - 1 perché i mesi in Date() partono da 0
                     }

                     // Ordinamento dell'array in base alla data crescente
                     temp.sort((a, b) => convertiData(a.Giorno) - convertiData(b.Giorno));

                     setData(temp);
                     setLoading(false);
                  }
               }
            } catch (err) {
               console.log(err);
            }
         };
         fetchAllTheThings(); //---- lancio tutte le chiamate giorno per giorno
         setData(temp);

      })
      // -------- ciclo ogni giorno selezionato per chiamarne i dati ------------

      
   },[selectedDays, shop])
   
   useEffect(() => {
      if (accessToken) {
         setRender(true);
      } else {
         window.open("https://dylogapp.it/login", "_self");
      };
   }, [accessToken])

   
   
  return (
     <>
      {render &&
         <DashboardLayout>
            <div className='p-20'>
               <Row>
                  <div className='di-card mb-20 w-100'>
                  <div className='mb-10'>Giorni:</div>
                  <DatePicker  
                     readOnly
                     format={"DD-MM-YYYY"}
                     multiple
                     needConfirm
                     allowClear
                     onChange={onChange}
                     value={selectedDays}
                     maxTagCount="responsive"
                     size="large"
                     order
                     placeholder='Seleziona i giorni da confrontare (max. 5)'
                  />
                  </div>
               </Row>
               <Row>
                  <div className='di-card w-100'>
                     <Spin spinning={loading}>
                        {data.length > 0 ?
                              <DisplayComparedData
                                    key="comparedData"
                                    layout={window.innerWidth > 1000 ? "vertical" : "horizontal"} 
                                    margin={window.innerWidth < 1000 ?  { top: 40, right: 10, bottom: 20, left: 80 } : { top: 50, right: 50, bottom: 50, left: 60 }}
                                    data={data}
                                    style={{ minHeigth: "600px", height: data.length + 50 + "0px" }}
                              />
                           :
                              <NoData/>
                        }
                     </Spin>
                  </div>
                 </Row>
            </div>
            
            {/* ------- floating buttons della pagina -------  */}
              
              <FiltersDrawer />
              
            {/* ------- floating buttons della pagina -------  */}
         </DashboardLayout >
      }
     </>
  )
}

