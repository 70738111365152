import React, { useContext, useState } from 'react'

import { Badge, Col, Collapse, Row, Typography } from 'antd'
import { CaretRightOutlined, CreditCardOutlined, FormOutlined, GiftOutlined, ManOutlined, NodeIndexOutlined, RetweetOutlined } from '@ant-design/icons'

import MarketingFiltersToggleCollapse from '../MarketingFiltersToggleCollapse'
import { CreateAudienceDataContext } from '../../../../../helpers/Context'
import FidelityFiltersTransactions from './FidelityFiltersTransactions'
import FidelityFiltersCampaigns from './FidelityFiltersCampaigns'
import FidelityFiltersCoupons from './FidelityFiltersCoupons'

export default function FidelityFilters({ form }) {
  
  const {   
      setactiveKeysFidelity,
      activeKeysFidelity,
      formTransactions,
      formCampaigns,
      colorFidelity,
      formCoupons,
      keys,
  } = useContext(CreateAudienceDataContext);

  // Funzione per espandere o contrarre tutti i pannelli
  const toggleAll = () => {
    if (activeKeysFidelity.length === 0) {
      // Espandi tutti i pannelli, ottieni le chiavi dei pannelli
      setactiveKeysFidelity(keys);
    } else {
      // Contrai tutti i pannelli (set activeKeys to empty array)
      setactiveKeysFidelity([]);
    }
  };

  return (
    <>
      <Row justify={"end"} gutter={[10,10]} className='w-100'> 
        <Col>
          <MarketingFiltersToggleCollapse onClick={toggleAll} activeKeys={activeKeysFidelity}/>
        </Col>
      </Row>
      <Collapse
        activeKey={activeKeysFidelity}
        onChange={setactiveKeysFidelity}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        expandIconPosition='end'
        size='large'
        bordered={false}
        items={[
          // -------- inizio array di elementi accordion --------

          // -------- gestione campagna ---------
          {
            key: '1',
            forceRender:true,
            label: (
              <div className='flex-wrap gap-10 align-center'>
                    <div className='flex gap-10 align-center'>
                      <Typography className='fs-16 flex align-center gap-5'>
                        <div className='fs-16'><CreditCardOutlined /></div>
                        Campagna, attività o saldo
                      </Typography>
                      <Badge color={formCampaigns?.length ? colorFidelity : ""} count={formCampaigns?.length}/>
                    </div>
                  <Typography className='fs-12 opacity-05 flex align-center gap-5'>
                    Definisci gli utenti secondo la loro fidelity, il loro saldo e la loro campagna
                  </Typography>
                  {/* {<Tag color='yellow' className='text-wrap fs-16 p-10'>Selezionando {naturalLanguageBirthdate(birthday)} </Tag>} */}
              </div>
            ),
            
            children:
              <div className='mt-10'>
                <FidelityFiltersCampaigns form={form} labelValue={"Campagna, Attività o Saldo"} />
              </div>,
          },
          // -------- gestione campagna ---------

          // -------- gestione transazioni ---------
          {
            key: '2',
            forceRender:true,
            label: (
              <div className='flex-wrap gap-10 align-center'>
                  <div className='flex gap-10 align-center'>
                    <Typography className='fs-16 flex align-center gap-5'>
                      <div className='fs-16'><RetweetOutlined /></div>
                      Transazioni Fidelity Card
                    </Typography>
                    <Badge color={formTransactions?.length ? colorFidelity : ""} count={formTransactions?.length}/>
                  </div>
                <Typography className='fs-12 opacity-05 flex align-center gap-5'>
                  Definisci gli utenti sceondo il numero di transazioni effettuate (se hai selezionato una campagna e delle attività, verranno calcolate in relazione)
                </Typography>
              </div>
            ),
            children: (
                <div className='mt-10'>
                  <FidelityFiltersTransactions form={form} labelValue={"Transazioni Effettuate"}/>
                </div>
            ),
          },
          // -------- gestione transazioni ---------

          // -------- gestione dettaglio coupon ---------
          {
            key: '3',
            forceRender:true,
            label: (
              <div className='flex-wrap gap-10 align-center'>
                  <div className='flex gap-10 align-center'>
                    <Typography className='fs-16 flex align-center gap-5'>
                      <div className='fs-16'><GiftOutlined /></div>
                      Coupons
                    </Typography>
                    <Badge color={formCoupons?.length ? colorFidelity : ""} count={formCoupons?.length}/>
                  </div>
                  <Typography className='fs-12 opacity-05 flex align-center gap-5'>
                    Definisci gli utenti sceondo l'ottenimento o le bruciature dei coupons
                  </Typography>
                  {/* {<Tag color='yellow' className='text-wrap fs-16 p-10'>Selezionando {naturalLanguageBirthdate(birthday)} </Tag>} */}
              </div>
            ),
            
            children: <div className='mt-10'>
              <FidelityFiltersCoupons form={form} labelValue={"Coupon"}/>
            </div>,
          },
          // -------- gestione dettaglio coupon ---------
          
          // -------- fine array di elementi accordion --------
        ]}
      />
    </>
  )
}
