import React, { useContext, useEffect, useState } from 'react'

import { ArrowDownOutlined, ArrowUpOutlined, EditFilled, EditOutlined, EuroCircleFilled, EuroCircleOutlined, LinkOutlined, MinusCircleOutlined, PlusCircleOutlined, ProductOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Popover, Row, Spin, Typography } from 'antd'

import { Navigate, useNavigate } from 'react-router';
import dayjs from 'dayjs';

import { ENDPOINT_CORRECTION_LIST } from '../../../helpers/Globals'
import { Context } from '../../../helpers/Context'
import { ApiCall } from '../../../Backoffice';
import formatEuro from '../../../helpers/FormatEuro';
import { countCorrectionsTotals } from '../../../helpers/CountCorrectionsTotals';

export default function DisplayFastDailyCorrections() {
   
   const [loading, setloading] = useState(true)
   const [data, setdata] = useState([])

   const [valueAfterPreReceipt, setvalueAfterPreReceipt] = useState(null)
   const [valueWhileService, setvalueWhileService] = useState(null)
   const [correctionRemoved, setcorrectionRemoved] = useState(0);
   const [correctionAdded, setcorrectionAdded] = useState(0);
   const [quantityRemoved, setquantityRemoved] = useState(0);
   const [quantityAdded, setquantityAdded] = useState(0);
   const [valueRemoved, setvalueRemoved] = useState(0);
   const [valueAdded, setvalueAdded] = useState(0);
   
   const navigate = useNavigate();

   const {
      commonQueryParams,
      accessToken,
      currency,
   } = useContext(Context)

   useEffect(() => {
      if (!data) return;

      const totals = countCorrectionsTotals(data);
      
      // Impostazione dello stato
      setquantityAdded(totals.totalQtaIncrease);
      setquantityRemoved(totals.totalQtaDecrease);
      setvalueAdded(totals.totalPriceIncrease);
      setvalueRemoved(totals.totalPriceDecrease);
      setcorrectionAdded(totals.countIncrease);
      setcorrectionRemoved(totals.countDecrease);
      setvalueAfterPreReceipt(totals.totalPriceAfterPreReceipt);
      setvalueWhileService(totals.totalPriceWhileService);

   }, [data]);

   // chiamata per ricevere i dati degli scontrini chiusi
   useEffect(()=>{
      setloading(true);

      (async () => {
         const json = await ApiCall(`${ENDPOINT_CORRECTION_LIST}?date=${dayjs().format('YYYY-MM-DDT00:00:00')}&end_date=${dayjs().format('YYYY-MM-DDT23:59:59')}&${commonQueryParams}`, "GET", `${accessToken}`, null)

         if (json) {
            setdata(json.CorrectionsList)
            setloading(false);
         }
      })();

   }, []);

 return (
   <div className="di-card h-100 flex column">
         <Spin className='absolute bottom-20 right-30'  spinning={loading}  />
         <Row gutter={[15, 15]} justify="center" align="middle">
            <EditFilled className="color-primary fs-20" />
            <Col align="center">
               <div className='fs-18 fw-5'>Correzioni Effettuate</div>
            </Col>
         </Row>
       
         <Divider className='mt-10 mb-10'/>
         
         <Row gutter={[15,15]} justify="center" align="middle">
          
            <Col xs={24}>
               <Popover
                  className='cursor-pointer'
                  title={
                     <>
                        <div>Totale durante il servizio: {formatEuro(valueWhileService)} {currency}</div>
                        <div>Totale dopo il preconto: {formatEuro(valueAfterPreReceipt)} {currency}</div>
                        <div>Dopo invio a reparti: {formatEuro(valueAdded + valueRemoved - valueAfterPreReceipt)} {currency}</div>
                     </>
                  }
               >
                 <div className='bg-dark-01 radius-3 py-10'>
                     <div className='flex gap-5 align-center justify-center'>
                        <EuroCircleOutlined/>
                        <Typography>
                           Valore
                        </Typography>
                     </div>
                     <div className='flex gap-20 align-center justify-center mt-10 mb-10'>
                        <div className='flex gap-10 wrap align-center justify-center color-primary fs-25'>
                           <PlusCircleOutlined />
                           {formatEuro(valueAdded) + currency}
                        </div>
                        <div className='flex gap-10 wrap align-center justify-center color-red fs-25'>
                           <MinusCircleOutlined/>
                           {formatEuro(Math.abs(valueRemoved)) + currency}
                        </div>
                     </div>
                     <Divider className='m-0'/>
                   <div className='w-100 flex justify-center align-center fs-25 mt-10 gap-5'>
                      <span>Totale: </span>
                      <span
                         className={Math.abs(valueAdded) > Math.abs(valueRemoved) ? "bold color-primary" : "bold color-red" }
                      >{formatEuro(valueAdded + valueRemoved)}{currency}</span>
                   </div>
                 </div>
              </Popover>
            </Col>
          
           <Col xs={24} md={12}>
               <div className='bg-dark-01 radius-3 py-10'>
                  <div className='flex gap-5 align-center justify-center'>
                     <ProductOutlined/>
                     <Typography>
                        Quantità totali
                     </Typography>
                  </div>
                  <div className='flex gap-20 align-center justify-center mt-10'>
                     <div className='flex gap-10 wrap align-center justify-center color-primary fs-25'>
                        <PlusCircleOutlined />
                        {quantityAdded}
                     </div>
                     <div className='flex gap-10 wrap align-center justify-center color-red fs-25'>
                        <MinusCircleOutlined/>
                        {Math.abs(quantityRemoved)}
                     </div>
                  </div>
               </div>
            </Col>
          
            <Col xs={24} md={12}>
               <div className='bg-dark-01 radius-3 py-10'>
                  <div className='flex gap-5 align-center justify-center'>
                     <EditOutlined/>
                     <Typography>
                        Correzioni totali
                     </Typography>
                  </div>
                  <div className='flex gap-20 align-center justify-center mt-10'>
                     <div className='flex gap-10 wrap align-center justify-center color-primary fs-25'>
                        <PlusCircleOutlined />
                        {correctionAdded}
                     </div>
                     <div className='flex gap-10 wrap align-center justify-center color-red fs-25'>
                        <MinusCircleOutlined/>
                        {correctionRemoved}
                     </div>
                  </div>
               </div>
            </Col>
         </Row>
       
         <Button type='primary' icon={<LinkOutlined/>} onClick={()=>navigate("/tabulations#correctionListTable")} className='mt-20'>Lista correzioni</Button>
   </div>
 )
}
