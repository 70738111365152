import React, { useContext } from 'react'

import { Button, Card, Carousel, Col, Divider, message, Row, Typography } from 'antd'
import { StarOutlined } from '@ant-design/icons'

import { CreateAudienceDataContext } from '../../../helpers/Context';
import { endToday, rangeLastMonth, startToday } from '../../../helpers/DateUtils';


export default function MarketingAudienceTopCarousel({ form }) {
   
   const {setFormChanged, formChanged, resetFormValues} = useContext(CreateAudienceDataContext)


   const onClick = (e) => {
      console.log(e)
      try {
         message.loading();
         resetFormValues();
         setTimeout(() => {
            message.destroy()
            form.setFieldsValue(e.filters)
            message.success(`Filtro "${e.title}" attivato`)
            form.validateFields()
            setFormChanged(formChanged + 1)
         }, 500);
      } catch (error) {
         message.error(error)
      }
   };

   const dummyTop = [
      {
         "title": "Compleanni Oggi",
         "description": "Seleziona tutti gli utenti che oggi fanno il compleanno.",
         "filters": {
            birthday_list:[
               {
                  "value": "today",
               },
            ]
         }
      },
      {
         "title": "Compleanni nel Mese",
         "description": "Seleziona tutti gli utenti che compiono il compleanno questo mese",
         "filters": {
            birthday_list:[
               {
                  "value": "month",
               },
            ]
         }
      },
      {
         "title": "Oltre 200€ di spesa",
         "description": "Individua gli utenti che hanno speso più di 200 euro",
         "filters": {
            orders_list:[
               {
                  "orders_value_calculation": "total",
                  "orders_value_amount": 200
               }
            ]
         }
      },
      {
         "title": "Utenti con più di 10 ordini",
         "description": "Individua gli utenti che hanno effettuato più di 10 ordini",
         "filters": {
            orders_list:[
               {
                  "orders_condition": "over",
                  "orders_quantity": 10
               }
            ]
         }
      },
      {
         "title": "Saldo Fidelity > 100",
         "description": "Selezionare tutti gli utenti che hanno un saldo fidelity maggiore di 100. Indipendentemente dalla campagna e dall'attività (punti, prepagato ece..)",
         "filters": {
            campaigns_list:[
               {
                     "condition": "over",
                     "activity_amount": 100
               }
            ]
         }
      },
      {
         "title": "Utenti in AppMobile ultimo mese",
         "description": "Individua tutti gli utenti attivati SOLO in AppMobile nell'ultimo mese",
         "filters": {
            activation_dates_list: [
               {
                  "full_date": rangeLastMonth,
                  "day_type": "now",
                  "day_offset": "-30",
                  "date_condition": "over",
                  "update_automatically":true
               }
            ],
            softwares_list:[
               "app_mobile"
            ]
         }
      },
   ]

   // ---------- componente che renderizza i dati del pubblico ----------
   const topAudience = (e) => {
      return (
         <div className='p-10'>
            <Card
               bordered={false}
               className='m-15 di-filters-preset-card'
               actions={[
                  <Button onClick={()=>onClick(e)} type='primary' icon={<StarOutlined/>}> Attiva Filtri </Button>,
               ]}
            >
               <Typography className='fs-16 bold'>{e.title}</Typography>
               <Divider className='mt-10 mb-10'></Divider>
               <Typography className='fs-16 h-100'>{e.description}</Typography>
            </Card>
         </div>
      )
   }
   // ---------- componente che renderizza i dati del pubblico ----------

  return (
     <Carousel
        className='di-marketing-audience-top'
        // ----------- regole di responsive per le slide del carosello -----------
        responsive={[
           {
              breakpoint: 5000, 
              settings: {
                 slidesToShow: 6, 
                 slidesToScroll: 1,
                 dots: true,
              },
           },
           {
              breakpoint: 2500, 
              settings: {
                 slidesToShow: 5, 
                 slidesToScroll: 1,
                 dots: true,
              },
           },
           {
              breakpoint: 1500, 
              settings: {
                 slidesToShow: 4, 
                 slidesToScroll: 1,
                 dots: true,
              },
           },
           {
              breakpoint: 1024, 
              settings: {
                 slidesToShow: 3, 
                 slidesToScroll: 1,
                 dots: true,
              },
           },
           {
              breakpoint: 800, 
              settings: {
                 slidesToShow: 2, 
                 slidesToScroll: 1,
              },
           },
           {
              breakpoint: 550, 
              settings: {
                 slidesToShow: 1, 
                 slidesToScroll: 1,
              },
           },
        ]}
        // ----------- regole di responsive per le slide del carosello -----------
        draggable
     >
         {dummyTop.map((content, index) => { //ciclo che prende le slide e i singoli pubblici
            return (
               <div key={index}>{topAudience(content)}</div>
            )
         })}
   </Carousel>
  )
}
